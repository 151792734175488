import React, { useEffect, useState } from 'react'
import ProviderNavTabs from './components/ProviderNavTabs'
import styled from 'styled-components';
import { UIModal } from '../../components/UI'
import {
  UINucleusContainer,
  UINucleusContentContainer,
  useModal
} from '../../components/UI'
import { httpApi } from "@nucleus-care/nucleuscare-backend-client";
import { UINucleusPageTitle } from '../../components/UI/NucleusPageTitle/Default'
import NucleusTable from '../../components/NucleusTable';
import NucleusSearchInput from '../../components/NucleusSearchInput';
import { useForm } from 'react-hook-form';
import { SpinnerCircular } from 'spinners-react';
import ProviderDetailsWrapper from './components/ProviderDetailsWrapper';
import {
  useNucleusProviders
} from '../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { getProviderUsers } from './utils/providerApi';

import NucleusModalButton from '../../components/NucleusModal/NucleusModalButton';
import { ProviderUser } from './types';

import ProviderUserModal from './ProviderUsersModal';
import Message from '../../utils/Message';

import AccountUserResetPasswordModal from '../../components/AccountUserResetPasswordModal';

const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;
const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;
const Name = styled.p`
  color: #0a313f;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;
const ProviderUsers = () => {

  const fastFilter = true;
  const { state: { provider: { data, state: { error, loading, success } } } } = useNucleusProviders();
  const [providerUsersData, setProviderUsersData] = React.useState<ProviderUser[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const { register, setValue, watch, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteModalActive, setDeleteModalActive] = useState<boolean>(false);

  const [selectedProviderUser, setSelectedProviderUser] = useState<ProviderUser | null>(null);
  const [providerUserId, setProviderUserId] = useState<string>('');
  const [selectedUserID, setSelectedUserID] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");

  const {
    isOpen: isProviderUserModalOpen,
    openModal: openProviderUserModal,
    closeModal: closeProviderUserModal,
  } = useModal();

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      show: false
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Telephone",
      accessor: "telephone",
      disableSortBy: true,
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
    },
    {
      Header: "Is Admin",
      accessor: "isAdmin",
      centerAlign: true,
      disableSortBy: true,
      Cell: ({ cell: { value } }) => <span>{value ? "Y" : ""}</span>,
    },
    {
      accessor: 'LockIcon',
      disableSortBy: true,
      Header:()=>{
        return(
          <span className="" style={{display:'flex', marginBottom:5, color:'#0092FF'}}>
						<i className="Tiny material-icons" style={{paddingLeft:5}}>lock_open</i>
					</span>
        )
      },
      Cell: ({ row, cell }) => {
        return (
          <img
            style={{cursor:'pointer'}}
            src="./img/black_lock.svg"
            onClick={(event) => {
              event.stopPropagation();
              console.log('deleting policy: ', row.original);
              const name = (row.original.name != " " ?  row.original.name : row.original.email)
              setSelectedUserID(row.original.id);
              setSelectedUserName(name);
            }}
          />
        );
      },
    },
    {
      accessor: 'TrashIcon',
      disableSortBy: true,
      Cell: ({ row, cell }) => {
        return (
          <img
            style={{cursor:'pointer'}}
            src="./img/trash.svg"
            onClick={(event) => {
              event.stopPropagation();
              console.log('deleting provider user: ', row.original);
              const name = (row.original.name != " " ?  row.original.name : row.original.email);
              setSelectedUserName(name);
              setProviderUserId(row.original.id);
              setDeleteModalActive(true);
            }}
          />
        );
      },
    },
  ];

  
  useEffect(() => {
    console.log("pages/providers/ProviderUsers data?.ID useEffect", data?.ID);
    if (!data?.ID) return;
    fetchProviderUsers(data?.ID)
  }, [data?.ID])
 

  const fetchProviderUsers = async (providerId: string ) => {
    // console.log("fetchProviderUsers ======================>");
    try {
      setIsLoading(true);
      await getProviderUsers(providerId, getProvidersUsersCallBack);
    } catch (error) {
      console.error('Error fetchProviderUsers:', error);
    }
  };

  const getProvidersUsersCallBack = (responseData) => {
    if (!responseData.users) {
      Message.error("Error getProviderUsers: " + responseData.message);
      return;
    }
    setProviderUsersData(responseData.users || []);
    setIsLoading(false);
  }

  const handleSubmitSearch = (data) => {
    setSearchText(data.search);
  };

  const handleRowClick = ({ row }) => {
    setSelectedProviderUser(row.original);
    openProviderUserModal();
  };

  const OpenAddUserModal = () => {
    setSelectedProviderUser(null);
    openProviderUserModal();
  }

  const onCloseProviderUserModal = () => {
    fetchProviderUsers(String(data?.ID));
    closeProviderUserModal();
  };

  const onUserResetPasswordClear = ()=>{
    setSelectedUserID("");
    setSelectedUserName("");
  }
  
  const closeDeleteModal = () => {
    setDeleteModalActive(false);
    setProviderUserId('');
    setSelectedUserName("");
  }
  
  const deleteProviderUser = () => {
    httpApi
    .delete(`providers/${data?.ID}/users/${providerUserId}`)
    .then(response => {
      console.log("delete response: ", response);
      closeDeleteModal();
      if (data && data.ID) {
        fetchProviderUsers(data.ID);
      } 
    })
    .catch(error => {
      console.log("Error deleting provider user: ", error)
    })
  }

  return (
    <ProviderDetailsWrapper>
      <UINucleusContainer>
        <UINucleusPageTitle>
          Provider: {data?.Name}
        </UINucleusPageTitle>
        <ProviderNavTabs activeTab='provider-users' />
        <UINucleusContentContainer style={{ paddingTop: 30 }}>
          <form onSubmit={handleSubmit(handleSubmitSearch)}>
            <NucleusSearchInput
              name='search'
              value={watch('search')}
              register={register('search', {
                onChange: () => {
                  setValue('search', watch('search'))
                  if (fastFilter) {
                    setSearchText(watch('search'))
                  }
                }
              })}
              placeholder='Filter by Name/Email/Telephone'
            />
          </form>
          {isLoading ?
            <UINucleusContentContainer centerContent>
              <SpinnerCircular
                color="#2096F3"
                secondaryColor="rgba(0,0,0,0.16)"
                size="50"
                thickness={100}
              />
            </UINucleusContentContainer> :
            <NucleusTable
              columns={columns}
              data={providerUsersData.filter(data => (searchText == "") ||
                data.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
                data.email.toLowerCase().includes(searchText.toLowerCase()) ||
                data.telephone?.toLowerCase().includes(searchText.toLowerCase()))
              }
              onRowClick={handleRowClick}
            />
          }

          <NucleusModalButton handleOpenModal={OpenAddUserModal} />

          {isProviderUserModalOpen && <ProviderUserModal
            isOpen={isProviderUserModalOpen}
            closeModal={onCloseProviderUserModal}
            providerUser={selectedProviderUser}
          />}

            <AccountUserResetPasswordModal
              UserID = {selectedUserID}
              UserName = {selectedUserName}
              OnDismiss = {onUserResetPasswordClear}
            />

        </UINucleusContentContainer>
        <UIModal
          actions={[
            {
              label: 'Cancel',
              onClick: closeDeleteModal,
              buttonVariant: 'secondary',
              disabled: isLoading
            },
            {
              label: 'Proceed',
              onClick: deleteProviderUser,
              buttonVariant: 'primary',
              disabled: isLoading
            }

          ]}
          close={closeDeleteModal}
          isOpen={deleteModalActive}
          title={''}
          size='medium'
        >
          <Title>WARNING!</Title>
          <Note>You are about to delete</Note>
          <Name>Provider User: {selectedUserName}</Name>
          <Note>this action is permanent</Note>
          <Note>Would you like to proceed?</Note>
        </UIModal>
      </UINucleusContainer>
    </ProviderDetailsWrapper>
  )
}


export default ProviderUsers