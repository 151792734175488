import React from 'react';
import AuthStore from '../../stores/AuthStore';
import CareAccountStore from '../../stores/CareAccountStore';
import GeneralStore from '../../stores/GeneralStore';

import Message from '../../utils/Message';
import { Link } from 'react-router-dom';

import AccountDevices from '../../components/AccountDevices';
import AccountStats from '../../components/AccountStats';
import ScheduleView from '../../components/ScheduleView';
import ClassSession from '../../components/ClassSession';
import AccountPatientTimezones from '../../components/AccountPatientsTimezones';
import AccountPatients from '../../components/AccountPatients';
import AccountUserItemRow from '../../components/AccountUserItemRow';
import AccountPatientItemRow from '../../components/AccountPatientItemRow';
import AccountBioDeviceItemRow from '../../components/AccountBioDeviceItemRow';

import AccountExternalApps from '../AccountExternalApps/AccountExternalApps';

import { SpinnerCircular } from 'spinners-react';
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import AppsTab from '../../components/AppsTab';
import LinkTab from '../../components/LinkTab';
import ScheduleTab from '../../components/ScheduleTab';
import AccountThemeSettings from './components/AccountThemeSettings';
import AccountUserResetPasswordModal from '../../components/AccountUserResetPasswordModal';
import { NucleusConfigSelect } from './components/NucleusConfigSelect';
import { AccountTabsHeader, AccountConfigTab, AccountConfigTabItem } from './components/AccountTabsHeader';
import { AccountFeatures } from './components/AccountFeatures';
import { AccountSettings } from './components/AccountSettings';
import { AccountStrings } from './components/AccountStrings';

import AccountConfigCheckBoxLine from './components/AccountConfigCheckBoxLine';
interface Data {
  ok: boolean;
  Data: number;
}
interface IntercomResponse {
  data: Data;
}
class AccountDetails extends React.Component<
  {
    match?: any;
    history?: any;
  },
  {
    accountConfigSelectedTab: AccountConfigTab | 'None';

    accountID?: string;
    loadingStrings?: boolean;
    currentMode: string;
    linkModeLabel: string;
    accountApiToken: string;
    accountApiUsageLimit?: number;
    accountName: string;
    accountLogo: string;
    accountGroupID: string;
    accountUpdateGroupID: string;
    accountZipCode: string;
    accountLocaleTag: string;
    accountCountryCode: string;

    accountAvailableLanguages: any[];
    accountActiveLanguages: any[];

    statsTab?: string;
    buttonsTab?: string;
    bioDevicesTab?: string;
    scheduleTab?: string;

    btnUpdateCallButtonText2Style: string;
    accountShowCallButton: boolean;
    accountShowCheckInButton: boolean;
    accountShowEmergencyButton: boolean;

    accountShowCallButton2: boolean;

    accountRingOnRequest: boolean;
    accountEnableBiometrics: boolean;
    accountEnableBluetoothButtons: boolean;
    accountShowCallControls: boolean;
    accountShowStats: boolean;
    accountShowFamilyCallBtn: boolean;
    accountAutoAnswer: boolean;
    accountFamilyAutoAnswer: boolean;
    accountFamilyAppAutoAnswer: boolean;
    accountForceLandscapeVideo: boolean;
    accountRingIncomingCall: boolean;
    accountVoiceMessage: boolean;

    accountAudioCallsOnly: boolean;
    intercomButtonEnabled: boolean;
    powerDisconnectedWarningEnabled: boolean;
    accountScheduleEnabled: boolean;
    accountEmergencySchedule: boolean;
    accountCallSchedule: boolean;

    deviceUxVersion: string;
    // Start of Languages

    accountClientRoleName: string;
    accountClientRoleNamePlural: string;
    accountFamilyRoleName: string;
    accountFamilyRoleNamePlural: string;
    accountGroupsRoleName: string;
    accountGroupsRoleNamePlural: string;
    accountUserRoleName: string;
    accountUserRoleNamePlural: string;
    accountOnDutyName: string;

    btnUpdateClientNameStyle: string;
    btnUpdateClientPluralNameStyle: string;
    btnUpdateFamilyNameStyle: string;
    btnUpdateGroupsNameStyle: string;
    btnUpdateFamilyPluralNameStyle: string;
    btnUpdateUserNameStyle: string;
    btnUpdateUserPluralNameStyle: string;

    accountCallButtonText: string;
    btnUpdateCallButtonTextStyle: string;

    accountCallButtonText2: string;

    accountEmergencyBtnText: string;
    btnUpdateEmergencyButtonTextStyle: string;

    accountMemoryBox: boolean;
    accountMemoryBoxLabel: string;
    accountMemoryBoxStyle: string;

    accountFamilyMemberLabel: string;
    accountFamilyMemberStyle: string;

    accountBiometricsLabel: string;
    accountBiometricsStyle: string;

    addFamilyMemberLabel: string;
    addFamilyMemberStyle: string;

    accountSelectedLanguage?: string;

    accountOffHoursMessage: string;
    btnOffHoursMessageStyle: string;

    accountCallTimeoutMessage: string;
    accountCallTimeoutMessageLength: string;

    // End of Languages

    accountUseBgImageTemplate: string;
    accountBackgroundImgUrl: string;

    sendMessageResponseByEmail: boolean;
    sendMessageResponseEmail: string;
    sendMessageResponseMode: string;

    escalationEmailAddress: string;

    updateGroupsArray: any[];
    updateAccountLocaleArray: any[];
    availableAccountLocales: any[];
    accountsCountriesArray: any[];
    availableCountries: any[];
    availableStates: any[];
    selectedState: string;
    selectedCountry: string;
    templatesArray: any[];

    accountAlertsArray: any[];

    accountDevicesArray: any[];
    accountUsersArray: any[];
    accountClientsArray: any[];
    accountButtonsArray: any[];
    accountBiometricDevicesArray: any[];

    accountDevices: number;
    accountUsers: number;
    accountPatients: number;
    accountClients: number;
    accountButtons: number;
    accountBioDevices: number;

    btnUpdateLogoStyle: string;

    btnPagerDutyStyle: string;

    accountImportClientsEnabled: boolean;
    accountSendToPagerDuty: boolean;
    accountPagerDutyEmail: string;

    accountSendWelcomeEmail: boolean;
    accountSendWelcomeSms: boolean;

    enableVideoCallShortcutValue: boolean;
    enableAudioCallShortcutValue: boolean;
    enableBargeInCallShortcutValue: boolean;
    enableSilentBargeInCallShortcutValue: boolean;
    enableAutomaticCallRequestBargeInValue: boolean;

    enableMoveFamily: boolean;

    accountTemplate: number;
    accountTemplateName: string;
    accountTemplateImageUrl: string;

    // Controls which templates are displayed
    templateNamesToDisplay: Set<String>;
    checkedTemplateId?: number;

    enableBackgroundImage: boolean;
    enableBackgroundLogo: boolean;

    accountBackgroundImageUrl: string;
    accountBackgroundLogoUrl: string;

    uploadingBgImageStatus: string;
    uploadingBgLogoStatus: string;

    accountBulletinButtons: number;
    accountBulletinButtonsArray: any[];

    alertOnDutyUsers: boolean;
    notifyDeviceOffline: boolean;
    alertExtremeRoomTemperature: boolean;
    panicEmergencyRingtone: boolean;
    messageToAllPatients: boolean;
    enableIncomingCall: boolean;
    enableAppIncomingCall: boolean;

    screenSaverDelay: string;
    escalationTimeOut: string;
    offlineTimeSpan: string;

    dimOffBrightness: string;
    dimOnBrightness: string;
    dimFrom: string;
    dimTo: string;

    inactiveDays: string;
    inactiveDaysAlert: string;
    hexnodeUserName: string;
    hexnodeGroupID: string;
    hexnodeAPI: string;
    hexnodeUrl: string;
    hexnodePolicyID: string;
    hexnodeIdRequired: false;

    callTimeout: string;
    accountSettingsPin: string;

    devicesContent: string;
    usersContent: string;
    clientsContent: string;
    statsContent: string;
    devicesTab: string;
    usersTab: string;
    clientsTab: string;

    selectedDeviceID: string;
    selectedDeviceMac: string;
    selectedDeviceName: string;

    selectedButtonMac: string;
    selectedButtonType: string;
    deleteButtonBtnStyle: string;

    deleteDeviceBtnStyle: string;
    deviceMacAddressError: string;
    deviceMacAddressText: string;

    deviceAddBtnStyle: string;
    devicesToAddInLegacy: string;
    deviceLegacyAddBtnStyle: string;

    downloadDeviceLogsBtnStyle: string;

    deviceSearchText: string;

    missingBiometricDevicesInfo: false;
    missingBiometricDevicesPatientInfo: false;

    deviceOfflineCheckBtnDisabled: string;
    callNotAnsweredCheckBtnDisabled: string;
    callNotEstablishedCheckBtnDisabled: string;
    messageAlertCheckBtnDisabled: string;
    biometricAlertCheckBtnDisabled: string;
    miscAlertCheckBtnDisabled: string;
    batteryAlertCheckBtnDisabled: string;
    homeAlertCheckBtnDisabled: string;
    bleDevicesAlertCheckBtnDisabled: string;
    isCheckedInScreenStaysOn: boolean;

    mobileCallResponse: string;
    enable2FactorAuthValue: boolean;
    remoteLogsValue?: boolean;
    salesforceEnabled?: boolean;
    size: number;

    deleteAccountError: string;
    btnDeleteAccountMode: string;

    isHipaaCompliant: boolean;
    autoLogoutTime: string;
    autoLogoutTimeMode: boolean;

    restartDeviceOfflineEnabled: boolean;
    restartDeviceOfflineTimeSpan: number;
    btnRestartDeviceOfflineTimeSpanStyle: string;
    showBatteryStatus: boolean;
    screenOffBatterySaver: boolean;

    salesforceCheckStatus: string;
    salesforceConfigurationStatus: string;
    salesforceConfigurationNeeded: boolean;
    closeSalesforceConfigurationBtnStyle: string;

    loading: boolean;

    accountsAbleToMoveDevices: any[];
    loadingAccountsAbleToMoveDevices: boolean;
    successAccountsAbleToMoveDevices: boolean;
    errorAccountsAbleToMoveDevices: boolean;
    selectedDevicesToMove: any;
    selectedDevicesToMoveArray: any[];
    selectedAccountToMoveID: string;
    movingDevicesStatus: string;

    selectedDeviceUnassign: boolean;
    selectedDeviceDisenrollHexnode: boolean;
    selectedDeviceRemoveFromHexnode: boolean;
    selectedDeviceRemoveFromSystem: boolean;
    selectedDeviceRemoveFromMDM: boolean;

    selectedUserId?: string;
    selectedUserName?: string;
    selectedUserResetPassword: boolean;
    selectedUserResetPasswordDone: boolean;
    salesforceName?: string;
    salesforceClientID?: string;
    salesforceClientSecret?: string;
    salesforceUserName?: string;
    salesforcePassword?: string;
    salesforceRedirectUri?: string;
    salesforceOrgUrl?: string;
    salesforceLoginUrl?: string;
    salesforceValidCredentials?: boolean;
    selectedDeviceAssigned?: boolean;
    salesforceSecurityToken?: string;
  }
> {
  private devicesInfoReloaded: number;
  private usersInfoReloaded: number;
  private clientsInfoReloaded: number;
  private buttonsInfoReloaded: number;
  private timePickerOptions: string[];
  private selectAccountLanguage = React.createRef<HTMLSelectElement>();
  private selectUpdateGroup = React.createRef<HTMLSelectElement>();
  private checkBulletinButton1 = React.createRef<HTMLInputElement>();
  private checkBulletinButton2 = React.createRef<HTMLInputElement>();
  private checkBulletinButton3 = React.createRef<HTMLInputElement>();
  private checkBulletinButton4 = React.createRef<HTMLInputElement>();
  private txtButton1Title = React.createRef<HTMLInputElement>();
  private txtButton2Title = React.createRef<HTMLInputElement>();
  private txtButton3Title = React.createRef<HTMLInputElement>();
  private txtButton4Title = React.createRef<HTMLInputElement>();
  private txtClientRoleName = React.createRef<HTMLInputElement>();
  private txtClientRoleNamePlural = React.createRef<HTMLInputElement>();
  private txtFamilyRoleName = React.createRef<HTMLInputElement>();
  private txtFamilyRoleNamePlural = React.createRef<HTMLInputElement>();
  private txtGroupsRoleName = React.createRef<HTMLInputElement>();
  private txtGroupsRoleNamePlural = React.createRef<HTMLInputElement>();
  private txtUserRoleName = React.createRef<HTMLInputElement>();
  private txtUserRoleNamePlural = React.createRef<HTMLInputElement>();
  private txtOnDutyName = React.createRef<HTMLInputElement>();
  private txtCallButtonText = React.createRef<HTMLInputElement>();
  private txtCallButtonText2 = React.createRef<HTMLInputElement>();
  private txtEmergencyBtnLabel = React.createRef<HTMLInputElement>();
  private txtMemoryBoxLabel = React.createRef<HTMLInputElement>();
  private txtFamilyMemberLabel = React.createRef<HTMLInputElement>();
  private txtBiometricsLabel = React.createRef<HTMLInputElement>();
  private txtAddFamilyButtonLabel = React.createRef<HTMLInputElement>();
  private txtTimeoutMessage = React.createRef<HTMLInputElement>();
  private txtOffHoursMessage = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      currentMode: 'Info',
      linkModeLabel: 'Edit',
      accountApiToken: '',
      accountID: match.params.accountId,
      accountName: '',
      accountLogo: '',
      accountGroupID: '',
      accountUpdateGroupID: '',
      accountLocaleTag: '',
      accountCountryCode: '',

      accountConfigSelectedTab: AccountConfigTab.AccountSettings,

      accountShowCallButton: false,
      accountShowCheckInButton: false,
      accountShowEmergencyButton: false,

      accountShowCallButton2: false,

      accountRingOnRequest: false,
      accountEnableBiometrics: false,
      accountEnableBluetoothButtons: false,
      accountShowCallControls: false,
      accountShowStats: false,
      accountShowFamilyCallBtn: false,
      accountAutoAnswer: false,
      accountFamilyAutoAnswer: false,
      accountFamilyAppAutoAnswer: false,
      accountForceLandscapeVideo: false,
      accountRingIncomingCall: false,
      accountVoiceMessage: false,

      accountAudioCallsOnly: false,
      intercomButtonEnabled: false,
      powerDisconnectedWarningEnabled: false,

      accountScheduleEnabled: false,
      accountEmergencySchedule: false,
      accountCallSchedule: false,

      deviceUxVersion: '',
      // Start of Languages

      accountClientRoleName: '',
      accountClientRoleNamePlural: '',
      accountFamilyRoleName: '',
      accountFamilyRoleNamePlural: '',
      accountGroupsRoleName: '',
      accountGroupsRoleNamePlural: '',
      accountUserRoleName: '',
      accountUserRoleNamePlural: '',
      accountOnDutyName: '',

      btnUpdateClientNameStyle: '',
      btnUpdateClientPluralNameStyle: '',
      btnUpdateFamilyNameStyle: '',
      btnUpdateGroupsNameStyle: '',
      btnUpdateFamilyPluralNameStyle: '',
      btnUpdateUserNameStyle: '',
      btnUpdateUserPluralNameStyle: '',

      accountCallButtonText: '',
      btnUpdateCallButtonTextStyle: '',

      accountCallButtonText2: '',
      btnUpdateCallButtonText2Style: '',

      accountEmergencyBtnText: ' ',
      btnUpdateEmergencyButtonTextStyle: '',

      accountMemoryBox: false,
      accountMemoryBoxLabel: '',
      accountMemoryBoxStyle: '',

      accountFamilyMemberLabel: '',
      accountFamilyMemberStyle: '',

      accountBiometricsLabel: '',
      accountBiometricsStyle: '',

      addFamilyMemberLabel: '',
      addFamilyMemberStyle: '',

      accountSelectedLanguage: null,
      accountActiveLanguages: [],
      accountAvailableLanguages: [],

      accountOffHoursMessage: '',
      btnOffHoursMessageStyle: '',

      accountCallTimeoutMessage: '',
      accountCallTimeoutMessageLength: '',

      loadingStrings: false,
      // End of Languages

      accountUseBgImageTemplate: '',
      accountBackgroundImgUrl: '',

      sendMessageResponseByEmail: false,
      sendMessageResponseEmail: '',
      sendMessageResponseMode: '',

      escalationEmailAddress: '',

      updateGroupsArray: [],
      updateAccountLocaleArray: [],
      availableAccountLocales: [],
      accountsCountriesArray: [],
      availableCountries: [],
      availableStates: [],
      selectedState: '',
      selectedCountry: '',
      templatesArray: [],

      accountAlertsArray: [],

      accountDevicesArray: [],
      accountUsersArray: [],
      accountClientsArray: [],
      accountButtonsArray: [],
      accountBiometricDevicesArray: [],

      accountDevices: 0,
      accountUsers: 0,
      accountPatients: 0,
      accountClients: 0,
      accountButtons: 0,
      accountBioDevices: 0,
      btnUpdateLogoStyle: '',

      btnPagerDutyStyle: '',

      accountImportClientsEnabled: false,
      accountSendToPagerDuty: false,
      accountPagerDutyEmail: '',

      accountSendWelcomeEmail: false,
      accountSendWelcomeSms: false,

      enableVideoCallShortcutValue: false,
      enableAudioCallShortcutValue: false,
      enableBargeInCallShortcutValue: false,
      enableSilentBargeInCallShortcutValue: false,

      enableMoveFamily: false,

      accountTemplate: 0,
      accountTemplateName: '',
      accountTemplateImageUrl: '',

      // Controls which templates are displayed
      templateNamesToDisplay: new Set([
        'Template 1',
        'Template 2',
        'Template 3',
        'Template 4',
        'Consumer Template',
        'Apps Template',
        'No Call Buttons',
        'Link Button Template',
        'IDD Template',
      ]),
      checkedTemplateId: null, // stores the id of the template that is currently checked

      enableBackgroundImage: false,
      enableBackgroundLogo: false,

      accountBackgroundImageUrl: '',
      accountBackgroundLogoUrl: '',

      uploadingBgImageStatus: '',
      uploadingBgLogoStatus: '',

      accountBulletinButtons: 0,
      accountBulletinButtonsArray: [],

      alertOnDutyUsers: false,
      notifyDeviceOffline: false,
      alertExtremeRoomTemperature: false,
      panicEmergencyRingtone: false,
      messageToAllPatients: false,
      enableIncomingCall: false,
      enableAppIncomingCall: false,

      screenSaverDelay: '',
      escalationTimeOut: '',
      offlineTimeSpan: '',

      dimOffBrightness: '',
      dimOnBrightness: '',
      dimFrom: '',
      dimTo: '',

      inactiveDays: '',
      inactiveDaysAlert: '',
      hexnodeUserName: '',
      hexnodeGroupID: '',
      hexnodeAPI: '',
      hexnodeUrl: '',
      hexnodePolicyID: '',
      hexnodeIdRequired: false,

      callTimeout: '',
      accountSettingsPin: '',

      devicesContent: 'hide',
      usersContent: 'hide',
      clientsContent: 'hide',
      statsContent: 'hide',
      devicesTab: '',
      usersTab: '',
      clientsTab: '',

      selectedDeviceID: '',
      selectedDeviceMac: '',
      selectedDeviceName: '',

      selectedButtonMac: '',
      selectedButtonType: '',
      deleteButtonBtnStyle: 'nucleus-submit-btn',

      deleteDeviceBtnStyle: 'nucleus-submit-btn',
      deviceMacAddressError: ' hide',
      deviceMacAddressText: ' Please enter the mac address',

      deviceAddBtnStyle: '',
      devicesToAddInLegacy: '',
      deviceLegacyAddBtnStyle: '',

      downloadDeviceLogsBtnStyle: 'nucleus-submit-btn',

      deviceSearchText: '',

      missingBiometricDevicesInfo: false,
      missingBiometricDevicesPatientInfo: false,

      deviceOfflineCheckBtnDisabled: '',
      callNotAnsweredCheckBtnDisabled: '',
      callNotEstablishedCheckBtnDisabled: '',
      messageAlertCheckBtnDisabled: '',
      biometricAlertCheckBtnDisabled: '',
      miscAlertCheckBtnDisabled: '',
      batteryAlertCheckBtnDisabled: '',
      homeAlertCheckBtnDisabled: '',
      bleDevicesAlertCheckBtnDisabled: '',
      isCheckedInScreenStaysOn: '',

      mobileCallResponse: '',
      size: 0,

      deleteAccountError: '',
      btnDeleteAccountMode: 'nucleus-submit-btn-small',

      isHipaaCompliant: false,
      autoLogoutTime: '',
      autoLogoutTimeMode: false,

      restartDeviceOfflineEnabled: false,
      restartDeviceOfflineTimeSpan: 0,
      btnRestartDeviceOfflineTimeSpanStyle: '',
      showBatteryStatus: false,
      screenOffBatterySaver: false,

      salesforceCheckStatus: '',
      salesforceConfigurationStatus: '',
      salesforceConfigurationNeeded: false,
      closeSalesforceConfigurationBtnStyle: 'nucleus-submit-btn',

      loading: false,

      accountsAbleToMoveDevices: [],
      loadingAccountsAbleToMoveDevices: false,
      successAccountsAbleToMoveDevices: false,
      errorAccountsAbleToMoveDevices: false,
      selectedDevicesToMove: {},
      selectedDevicesToMoveArray: [],
      selectedAccountToMoveID: '',
      movingDevicesStatus: '',

      selectedDeviceUnassign: false,
      selectedDeviceDisenrollHexnode: false,
      selectedDeviceRemoveFromHexnode: false,
      selectedDeviceRemoveFromSystem: false,
      selectedDeviceRemoveFromMDM: false,

      selectedUserId: null,
      selectedUserName: null,
      selectedUserResetPassword: false,
      selectedUserResetPasswordDone: false,
    };

    this.Init = this.Init.bind(this);

    this.handleHipaaComplianceSettingsTab = this.handleHipaaComplianceSettingsTab.bind(this);
    this.handleHexnodeSettingsTab = this.handleHexnodeSettingsTab.bind(this);
    this.handleSalesforceSettingsTab = this.handleSalesforceSettingsTab.bind(this);
    this.handleClassroomSettingsTab = this.handleClassroomSettingsTab.bind(this);
    this.handleAppsSettingsTab = this.handleAppsSettingsTab.bind(this);
    this.handleLinkSettingsTab = this.handleLinkSettingsTab.bind(this);

    this.handleChangeUpdateGroup = this.handleChangeUpdateGroup.bind(this);
    this.onUpdatesGroupUpdatedAction = this.onUpdatesGroupUpdatedAction.bind(this);

    this.handleShowCallButton = this.handleShowCallButton.bind(this);
    this.onShowCallButtonUpdatedAction = this.onShowCallButtonUpdatedAction.bind(this);

    this.handleShowCallButton2 = this.handleShowCallButton2.bind(this);
    this.onShowCallButton2UpdatedAction = this.onShowCallButton2UpdatedAction.bind(this);

    this.handleShowCheckInButton = this.handleShowCheckInButton.bind(this);
    this.onShowCheckInButtonUpdatedAction = this.onShowCheckInButtonUpdatedAction.bind(this);
    this.handleShowEmergencyButton = this.handleShowEmergencyButton.bind(this);
    this.onShowEmergencyButtonUpdatedAction = this.onShowEmergencyButtonUpdatedAction.bind(this);

    this.handleRingOnRequest = this.handleRingOnRequest.bind(this);
    this.onRingOnRequestUpdatedAction = this.onRingOnRequestUpdatedAction.bind(this);

    this.handleEnableBluetoothButtons = this.handleEnableBluetoothButtons.bind(this);
    this.onEnableBluetoothButtonsUpdatedAction = this.onEnableBluetoothButtonsUpdatedAction.bind(this);

    this.handleShowCallControls = this.handleShowCallControls.bind(this);
    this.onShowCallControlsUpdatedAction = this.onShowCallControlsUpdatedAction.bind(this);

    this.handleShowStats = this.handleShowStats.bind(this);
    this.onShowVideoStatsUpdatedAction = this.onShowVideoStatsUpdatedAction.bind(this);

    this.handleShowFamilyCallBtn = this.handleShowFamilyCallBtn.bind(this);
    this.onShowFamilyCallButtonUpdatedAction = this.onShowFamilyCallButtonUpdatedAction.bind(this);

    this.handleAutoAnswer = this.handleAutoAnswer.bind(this);
    this.onEnableAutoAnswerUpdatedAction = this.onEnableAutoAnswerUpdatedAction.bind(this);

    this.handleForceLandscapeVideo = this.handleForceLandscapeVideo.bind(this);
    this.onForceLandscapeVideoUpdatedAction = this.onForceLandscapeVideoUpdatedAction.bind(this);

    this.handleEmergencySchedule = this.handleEmergencySchedule.bind(this);
    this.onEnableEmergencyScheduleUpdatedAction = this.onEnableEmergencyScheduleUpdatedAction.bind(this);

    this.handleCallSchedule = this.handleCallSchedule.bind(this);
    this.onEnableCallScheduleUpdatedAction = this.onEnableCallScheduleUpdatedAction.bind(this);

    this.handleAudioCallsOnly = this.handleAudioCallsOnly.bind(this);
    this.onHandleAudioCallsOnlyAction = this.onHandleAudioCallsOnlyAction.bind(this);

    this.handleAccountThemeVersion = this.handleAccountThemeVersion.bind(this);

    this.handleStatsTab = this.handleStatsTab.bind(this);
    this.handleDevicesTab = this.handleDevicesTab.bind(this);
    this.handleUsersTab = this.handleUsersTab.bind(this);
    this.handleClientsTab = this.handleClientsTab.bind(this);
    this.handlePatientsTab = this.handlePatientsTab.bind(this);
    this.handlePatientsTimeZones = this.handlePatientsTimeZones.bind(this);
    this.handleButtonsTab = this.handleButtonsTab.bind(this);
    this.handleBioDevicesTab = this.handleBioDevicesTab.bind(this);
    this.handleScheduleTab = this.handleScheduleTab.bind(this);
    this.handleExternalAppsSubTab = this.handleExternalAppsSubTab.bind(this);

    this.onGetAccountDevicesAction = this.onGetAccountDevicesAction.bind(this);
    this.refreshBiometricDevicesData = this.refreshBiometricDevicesData.bind(this);

    this.refreshBiometricDevicesPatientData = this.refreshBiometricDevicesPatientData.bind(this);

    this.onDeleteDeviceModalAction = this.onDeleteDeviceModalAction.bind(this);
    this.handleCancelDeleteDevice = this.handleCancelDeleteDevice.bind(this);
    this.handleDeleteDevice = this.handleDeleteDevice.bind(this);
    this.onDeviceFromAccountRemovedAction = this.onDeviceFromAccountRemovedAction.bind(this);

    this.handleNewDevice = this.handleNewDevice.bind(this);
    this.handleCloseNewDeviceModal = this.handleCloseNewDeviceModal.bind(this);
    this.validateMacAddressText = this.validateMacAddressText.bind(this);
    this.handleSubmitNewDevice = this.handleSubmitNewDevice.bind(this);
    this.handleSubmitMoveDevices = this.handleSubmitMoveDevices.bind(this);

    this.handleSubmitLegacyDevice = this.handleSubmitLegacyDevice.bind(this);

    this.onDeviceAddedAction = this.onDeviceAddedAction.bind(this);

    this.onDeviceFromLegacyAddedAction = this.onDeviceFromLegacyAddedAction.bind(this);
    this.onRebootDeviceRequestedAction = this.onRebootDeviceRequestedAction.bind(this);

    this.onDownloadDeviceLogsModalAction = this.onDownloadDeviceLogsModalAction.bind(this);
    this.handleCancelDownloadDeviceLogs = this.handleCancelDownloadDeviceLogs.bind(this);
    this.handleDownloadDeviceLogs = this.handleDownloadDeviceLogs.bind(this);

    this.onUnblockApiButtonModalAction = this.onUnblockApiButtonModalAction.bind(this);
    this.handleCloseUnblockApi = this.handleCloseUnblockApi.bind(this);
    this.handleSubmitUnblockApi = this.handleSubmitUnblockApi.bind(this);
    this.onUnblockApiUpdatedAction = this.onUnblockApiUpdatedAction.bind(this);

    this.onGenerateApiTokenButtonModalAction = this.onGenerateApiTokenButtonModalAction.bind(this);
    this.handleCloseGenerateApiTokenModal = this.handleCloseGenerateApiTokenModal.bind(this);
    this.handleSubmitGenerateApiToken = this.handleSubmitGenerateApiToken.bind(this);
    this.onApiTokenUpdatedAction = this.onApiTokenUpdatedAction.bind(this);

    this.handleIsHipaaCompliant = this.handleIsHipaaCompliant.bind(this);
    this.onHipaaCompliantUpdatedAction = this.onHipaaCompliantUpdatedAction.bind(this);

    this.handleUpdateAutoLogout = this.handleUpdateAutoLogout.bind(this);
    this.onAutoLogoutUpdatedAction = this.onAutoLogoutUpdatedAction.bind(this);

    this.onGetAccountUsersAction = this.onGetAccountUsersAction.bind(this);
    this.onGetAccountPatientsStatsAction = this.onGetAccountPatientsStatsAction.bind(this);
    this.onGetAccountButtonsAction = this.onGetAccountButtonsAction.bind(this);

    this.onGetAccountBiometricDevicesAction = this.onGetAccountBiometricDevicesAction.bind(this);

    //MemoryBox
    this.handleEnableMemoryBox = this.handleEnableMemoryBox.bind(this);
    this.onEnableMemoryBoxUpdatedAction = this.onEnableMemoryBoxUpdatedAction.bind(this);

    //Screen Saver Delay
    this.handleUpdateScreenSaverDelay = this.handleUpdateScreenSaverDelay.bind(this);
    this.onScreenSaverDelayUpdatedAction = this.onScreenSaverDelayUpdatedAction.bind(this);

    //Dim Start (Time)
    this.handleChangeDimStartTime = this.handleChangeDimStartTime.bind(this);
    this.onDimStartUpdatedAction = this.onDimStartUpdatedAction.bind(this);

    //Dim End (Time)
    this.handleChangeDimEndTime = this.handleChangeDimEndTime.bind(this);
    this.onDimEndUpdatedAction = this.onDimEndUpdatedAction.bind(this);

    //Dim off (Brightness level)
    this.handleUpdateDimOffBrightness = this.handleUpdateDimOffBrightness.bind(this);
    this.onDimOffBrightnessUpdatedAction = this.onDimOffBrightnessUpdatedAction.bind(this);

    //Dim on (Brightness level)
    this.handleUpdateDimOnBrightness = this.handleUpdateDimOnBrightness.bind(this);
    this.onDimOnBrightnessUpdatedAction = this.onDimOnBrightnessUpdatedAction.bind(this);

    this.handleUpdateOfflineTimeSpan = this.handleUpdateOfflineTimeSpan.bind(this);
    this.onOfflineTimeSpanUpdatedAction = this.onOfflineTimeSpanUpdatedAction.bind(this);

    //Settings PIN Code
    this.handleUpdateSettingsPin = this.handleUpdateSettingsPin.bind(this);
    this.onSettingsAccessPINUpdatedAction = this.onSettingsAccessPINUpdatedAction.bind(this);

    // Inactive Days
    this.handleUpdateInactiveDays = this.handleUpdateInactiveDays.bind(this);
    this.onInactiveDaysUpdatedAction = this.onInactiveDaysUpdatedAction.bind(this);

    this.handleUpdateInactiveDaysAlert = this.handleUpdateInactiveDaysAlert.bind(this);
    this.onInactiveDaysAlertUpdatedAction = this.onInactiveDaysAlertUpdatedAction.bind(this);

    this.handleUpdateHexnodeUsername = this.handleUpdateHexnodeUsername.bind(this);
    this.onHexnodeUserNameUpdatedAction = this.onHexnodeUserNameUpdatedAction.bind(this);

    this.handleUpdateHexnodeAPI = this.handleUpdateHexnodeAPI.bind(this);
    this.onHexnodeAPIUpdatedAction = this.onHexnodeAPIUpdatedAction.bind(this);

    this.handleUpdateHexnodeGroupID = this.handleUpdateHexnodeGroupID.bind(this);
    this.onHexnodeGroupIDUpdated = this.onHexnodeGroupIDUpdated.bind(this);

    this.handleUpdateHexnodeUrl = this.handleUpdateHexnodeUrl.bind(this);
    this.onHexnodeUrlUpdatedAction = this.onHexnodeUrlUpdatedAction.bind(this);

    this.handleUpdateHexnodePolicyID = this.handleUpdateHexnodePolicyID.bind(this);
    this.onHexnodePolicyIDUpdated = this.onHexnodePolicyIDUpdated.bind(this);

    this.handleHexnodeIdRequired = this.handleHexnodeIdRequired.bind(this);
    this.onHexnodeIdRequiredUpdatedAction = this.onHexnodeIdRequiredUpdatedAction.bind(this);

    //Checked-In Screen Stays on
    this.handleUpdateCheckedInScreenStaysOn = this.handleUpdateCheckedInScreenStaysOn.bind(this);
    this.onCheckedInScreenStaysOnUpdatedAction = this.onCheckedInScreenStaysOnUpdatedAction.bind(this);

    this.handleRestartDeviceOffline = this.handleRestartDeviceOffline.bind(this);
    this.onRestartDeviceOfflineUpdatedAction = this.onRestartDeviceOfflineUpdatedAction.bind(this);

    this.handleUpdateRestartDeviceOfflineTimeSpan = this.handleUpdateRestartDeviceOfflineTimeSpan.bind(this);
    this.onRestartOfflineDeviceTimeSpanUpdatedAction = this.onRestartOfflineDeviceTimeSpanUpdatedAction.bind(this);

    this.handleShowBatteryStatus = this.handleShowBatteryStatus.bind(this);
    this.onShowBatteryStatusUpdatedAction = this.onShowBatteryStatusUpdatedAction.bind(this);

    this.handleEnableImportClients = this.handleEnableImportClients.bind(this);

    this.handlePagerDuty = this.handlePagerDuty.bind(this);
    this.onSendToPagerDutyUpdatedAction = this.onSendToPagerDutyUpdatedAction.bind(this);

    this.handleUpdatePagerDutyEmail = this.handleUpdatePagerDutyEmail.bind(this);
    this.onPagerDutyEmailUpdatedAction = this.onPagerDutyEmailUpdatedAction.bind(this);

    //------------------------------------------------
    this.onClearingSMSandEmailAccountAlertsUpdatedAction = this.onClearingSMSandEmailAccountAlertsUpdatedAction.bind(this);

    this.handleOnDutyUsersByEmail = this.handleOnDutyUsersByEmail.bind(this);
    this.onAlertOnDutyUsersByEmailUpdatedAction = this.onAlertOnDutyUsersByEmailUpdatedAction.bind(this);

    this.handleNotifyDeviceOffline = this.handleNotifyDeviceOffline.bind(this);
    this.onNotifyOnDeviceOfflineUpdatedAction = this.onNotifyOnDeviceOfflineUpdatedAction.bind(this);

    this.handleDeviceOfflineEnable = this.handleDeviceOfflineEnable.bind(this);
    this.onDeviceOfflineEnableUpdatedAction = this.onDeviceOfflineEnableUpdatedAction.bind(this);

    this.handleDeviceOfflineSMS = this.handleDeviceOfflineSMS.bind(this);
    this.onDeviceOfflineSMSUpdatedAction = this.onDeviceOfflineSMSUpdatedAction.bind(this);

    this.handleDeviceOfflineEmail = this.handleDeviceOfflineEmail.bind(this);
    this.onDeviceOfflineEmailUpdatedAction = this.onDeviceOfflineEmailUpdatedAction.bind(this);

    this.handleCallNotAnsweredEnable = this.handleCallNotAnsweredEnable.bind(this);
    this.onCallNotAnsweredEnableUpdatedAction = this.onCallNotAnsweredEnableUpdatedAction.bind(this);

    this.handleCallNotEstablishedEnabled = this.handleCallNotEstablishedEnabled.bind(this);
    this.onCallNotEstablishedEnabledUpdatedAction = this.onCallNotEstablishedEnabledUpdatedAction.bind(this);

    this.handleCallNotAnsweredSMS = this.handleCallNotAnsweredSMS.bind(this);
    this.onCallNotAnsweredSMSUpdatedAction = this.onCallNotAnsweredSMSUpdatedAction.bind(this);

    this.handleCallNotEstablishedSMS = this.handleCallNotEstablishedSMS.bind(this);
    this.onCallNotEstablishedSMSUpdatedAction = this.onCallNotEstablishedSMSUpdatedAction.bind(this);

    this.handleCallNotAnsweredEmail = this.handleCallNotAnsweredEmail.bind(this);
    this.onCallNotAnsweredEmailUpdatedAction = this.onCallNotAnsweredEmailUpdatedAction.bind(this);

    this.handleCallNotEstablishedEmail = this.handleCallNotEstablishedEmail.bind(this);
    this.onCallNotEstablishedEmailUpdatedAction = this.onCallNotEstablishedEmailUpdatedAction.bind(this);

    this.handleMessageAlertEnable = this.handleMessageAlertEnable.bind(this);
    this.onMessageAlertEnableUpdatedAction = this.onMessageAlertEnableUpdatedAction.bind(this);

    this.handleMessageAlertSMS = this.handleMessageAlertSMS.bind(this);
    this.onMessageAlertSMSUpdatedAction = this.onMessageAlertSMSUpdatedAction.bind(this);

    this.handleMessageAlertEmail = this.handleMessageAlertEmail.bind(this);
    this.onMessageAlertEmailUpdatedAction = this.onMessageAlertEmailUpdatedAction.bind(this);

    this.handleBiometricAlertEnable = this.handleBiometricAlertEnable.bind(this);
    this.onBiometricAlertEnableUpdatedAction = this.onBiometricAlertEnableUpdatedAction.bind(this);

    this.handleBiometricAlertSMS = this.handleBiometricAlertSMS.bind(this);
    this.onBiometricAlertSMSUpdatedAction = this.onBiometricAlertSMSUpdatedAction.bind(this);

    this.handleBiometricAlertEmail = this.handleBiometricAlertEmail.bind(this);
    this.onBiometricAlertEmailUpdatedAction = this.onBiometricAlertEmailUpdatedAction.bind(this);

    this.handleMiscAlertEnable = this.handleMiscAlertEnable.bind(this);
    this.handleBatteryAlertEnable = this.handleBatteryAlertEnable.bind(this);
    this.onMiscAlertEnableUpdatedAction = this.onMiscAlertEnableUpdatedAction.bind(this);
    this.onBatteryAlertEnableUpdatedAction = this.onBatteryAlertEnableUpdatedAction.bind(this);

    this.handleMiscAlertSMS = this.handleMiscAlertSMS.bind(this);
    this.onMiscAlertSMSUpdatedAction = this.onMiscAlertSMSUpdatedAction.bind(this);

    this.handleBatteryAlertSMS = this.handleBatteryAlertSMS.bind(this);
    this.onBatteryAlertSMSUpdatedAction = this.onBatteryAlertSMSUpdatedAction.bind(this);

    this.handleMiscAlertEmail = this.handleMiscAlertEmail.bind(this);
    this.onMiscAlertEmailUpdatedAction = this.onMiscAlertEmailUpdatedAction.bind(this);

    this.handleBatteryAlertEmail = this.handleBatteryAlertEmail.bind(this);
    this.onBatteryAlertEmailUpdatedAction = this.onBatteryAlertEmailUpdatedAction.bind(this);

    this.handleHomeAlertEnable = this.handleHomeAlertEnable.bind(this);
    this.onHomeAlertEnableUpdatedAction = this.onHomeAlertEnableUpdatedAction.bind(this);

    this.handleHomeAlertSMS = this.handleHomeAlertSMS.bind(this);
    this.onHomeAlertSMSUpdatedAction = this.onHomeAlertSMSUpdatedAction.bind(this);

    this.handleHomeAlertEmail = this.handleHomeAlertEmail.bind(this);
    this.onHomeAlertEmailUpdatedAction = this.onHomeAlertEmailUpdatedAction.bind(this);

    this.handleBleDevicesAlertEnable = this.handleBleDevicesAlertEnable.bind(this);
    this.onBleDevicesAlertEnableUpdatedAction = this.onBleDevicesAlertEnableUpdatedAction.bind(this);

    this.handleBleDevicesAlertSMS = this.handleBleDevicesAlertSMS.bind(this);
    this.onBleDevicesAlertSMSUpdatedAction = this.onBleDevicesAlertSMSUpdatedAction.bind(this);

    this.handleBleDevicesAlertEmail = this.handleBleDevicesAlertEmail.bind(this);
    this.onBleDevicesAlertEmailUpdatedAction = this.onBleDevicesAlertEmailUpdatedAction.bind(this);

    this.handleExtremeRoomTemperature = this.handleExtremeRoomTemperature.bind(this);
    this.onAlertOnExtremeRoomTemperatureUpdatedAction = this.onAlertOnExtremeRoomTemperatureUpdatedAction.bind(this);

    //------------------------------------------------

    // Background Image
    // Deprecated Sep 4th 2019
    this.handleUseBgImgTemplate = this.handleUseBgImgTemplate.bind(this);
    this.onUseDeviceTemplateUpdatedAction = this.onUseDeviceTemplateUpdatedAction.bind(this);
    this.handleUpdateBackgroundImgUrl = this.handleUpdateBackgroundImgUrl.bind(this);
    this.onBackgroundImgUrlUpdatedAction = this.onBackgroundImgUrlUpdatedAction.bind(this);

    this.onAccountTemplateUpdatedAction = this.onAccountTemplateUpdatedAction.bind(this);

    this.handleEnableMessageResponse = this.handleEnableMessageResponse.bind(this);
    this.onEnableMessageResponseUpdatedAction = this.onEnableMessageResponseUpdatedAction.bind(this);
    this.handleUpdateMessageResponseEmailAddress = this.handleUpdateMessageResponseEmailAddress.bind(this);
    this.onMessageResponseEmailUpdatedAction = this.onMessageResponseEmailUpdatedAction.bind(this);

    this.handleUpdateEscalationEmailAddress = this.handleUpdateEscalationEmailAddress.bind(this);
    this.onEscalationEmailUpdatedAction = this.onEscalationEmailUpdatedAction.bind(this);

    /* ------ Bulletin Buttons ------- */
    this.onGetAccountBulletinButtonsAction = this.onGetAccountBulletinButtonsAction.bind(this);
    this.handleChangeAccountTemplate = this.handleChangeAccountTemplate.bind(this);
    this.handleCancelSelectTemplate = this.handleCancelSelectTemplate.bind(this);
    this.handleSelectTemplate = this.handleSelectTemplate.bind(this);

    this.handleEnableBackgroundImage = this.handleEnableBackgroundImage.bind(this);
    this.handleEnableBackgroundLogo = this.handleEnableBackgroundLogo.bind(this);

    this.onEnableBackgroundImageUpdatedAction = this.onEnableBackgroundImageUpdatedAction.bind(this);
    this.onEnableBackgroundLogoUpdatedAction = this.onEnableBackgroundLogoUpdatedAction.bind(this);

    this.handleChangeBackgroundImage = this.handleChangeBackgroundImage.bind(this);
    this.selectedBackgroundImageFile = this.selectedBackgroundImageFile.bind(this);
    this.onUploadBackgroundImageCompleteAction = this.onUploadBackgroundImageCompleteAction.bind(this);

    this.handleChangeBackgroundLogo = this.handleChangeBackgroundLogo.bind(this);
    this.selectedBackgroundLogoImageFile = this.selectedBackgroundLogoImageFile.bind(this);
    this.onUploadBackgroundLogoCompleteAction = this.onUploadBackgroundLogoCompleteAction.bind(this);

    this.handleEnableBulletinButton = this.handleEnableBulletinButton.bind(this);
    this.onEnableBulletinButtonUpdatedAction = this.onEnableBulletinButtonUpdatedAction.bind(this);

    this.handleUpdateBulletinButtonName = this.handleUpdateBulletinButtonName.bind(this);
    this.onBulletinButtonNameUpdatedAction = this.onBulletinButtonNameUpdatedAction.bind(this);

    this.handleSalesforceEnabled = this.handleSalesforceEnabled.bind(this);
    this.onSalesforceEnabledUpdatedAction = this.onSalesforceEnabledUpdatedAction.bind(this);

    this.handleUpdateSalesforceClientID = this.handleUpdateSalesforceClientID.bind(this);
    this.onSalesforceClientIDUpdatedAction = this.onSalesforceClientIDUpdatedAction.bind(this);

    this.handleUpdateSalesforceClientSecret = this.handleUpdateSalesforceClientSecret.bind(this);
    this.onSalesforceClientSecretUpdatedAction = this.onSalesforceClientSecretUpdatedAction.bind(this);

    this.handleUpdateSalesforceUserName = this.handleUpdateSalesforceUserName.bind(this);
    this.onSalesforceUserNameUpdatedAction = this.onSalesforceUserNameUpdatedAction.bind(this);

    this.handleUpdateSalesforceUserName = this.handleUpdateSalesforceUserName.bind(this);
    this.onSalesforceUserNameUpdatedAction = this.onSalesforceUserNameUpdatedAction.bind(this);

    this.handleUpdateSalesforcePassword = this.handleUpdateSalesforcePassword.bind(this);
    this.onSalesforcePasswordUpdatedAction = this.onSalesforcePasswordUpdatedAction.bind(this);

    this.handleUpdateSalesforceRedirectUri = this.handleUpdateSalesforceRedirectUri.bind(this);
    this.onSalesforceRedirectUriUpdatedAction = this.onSalesforceRedirectUriUpdatedAction.bind(this);

    this.handleUpdateSalesforceOrgUrl = this.handleUpdateSalesforceOrgUrl.bind(this);
    this.handleUpdateSalesforceLoginUrl = this.handleUpdateSalesforceLoginUrl.bind(this);
    this.onSalesforceOrgUrlUpdatedAction = this.onSalesforceOrgUrlUpdatedAction.bind(this);
    this.onSalesforceLoginUrlUpdatedAction = this.onSalesforceLoginUrlUpdatedAction.bind(this);

    this.handleUpdateSalesforceSecurityToken = this.handleUpdateSalesforceSecurityToken.bind(this);
    this.onSalesforceSecurityTokenUpdatedAction = this.onSalesforceSecurityTokenUpdatedAction.bind(this);

    this.verifySalesforceConfiguration = this.verifySalesforceConfiguration.bind(this);
    this.handleCloseSalesforceConfigurationModal = this.handleCloseSalesforceConfigurationModal.bind(this);
    this.onCheckSalesforceConfigurationResponseAction = this.onCheckSalesforceConfigurationResponseAction.bind(this);

    this.checkSalesforceIntegration = this.checkSalesforceIntegration.bind(this);
    this.handleCloseSalesforceIntegrationModal = this.handleCloseSalesforceIntegrationModal.bind(this);
    this.onCheckSalesforceIntegrationResponseAction = this.onCheckSalesforceIntegrationResponseAction.bind(this);
    this.handleConfigureSalesforce = this.handleConfigureSalesforce.bind(this);
    this.onConfigureSalesforceIntegrationResponseAction = this.onConfigureSalesforceIntegrationResponseAction.bind(this);

    this.devicesInfoReloaded = 0;
    this.usersInfoReloaded = 0;
    this.clientsInfoReloaded = 0;
    this.buttonsInfoReloaded = 0;

    //this.handleDeviceSearchText = this.handleDeviceSearchText.bind(this);

    this.convertTime12to24 = this.convertTime12to24.bind(this);
    this.getAccounts = this.getAccounts.bind(this);

    //User password key clicked
    this.onUserResetPassword = this.onUserResetPassword.bind(this);
    this.onUserResetPasswordClear = this.onUserResetPasswordClear.bind(this);

    this.timePickerOptions = [
      '12:00 AM',
      '12:30 AM',
      '01:00 AM',
      '01:30 AM',
      '02:00 AM',
      '02:30 AM',
      '03:00 AM',
      '03:30 AM',
      '04:00 AM',
      '04:30 AM',
      '05:00 AM',
      '05:30 AM',
      '06:00 AM',
      '06:30 AM',
      '07:00 AM',
      '07:30 AM',
      '08:00 AM',
      '08:30 AM',
      '09:00 AM',
      '09:30 AM',
      '10:00 AM',
      '10:30 AM',
      '11:00 AM',
      '11:30 AM',
      '12:00 PM',
      '12:30 PM',
      '01:00 PM',
      '01:30 PM',
      '02:00 PM',
      '02:30 PM',
      '03:00 PM',
      '03:30 PM',
      '04:00 PM',
      '04:30 PM',
      '05:00 PM',
      '05:30 PM',
      '06:00 PM',
      '06:30 PM',
      '07:00 PM',
      '07:30 PM',
      '08:00 PM',
      '08:30 PM',
      '09:00 PM',
      '09:30 PM',
      '10:00 PM',
      '10:30 PM',
      '11:00 PM',
      '11:30 PM',
    ];
  }

  getAccounts() {
    this.setState({
      loadingAccountsAbleToMoveDevices: true,
      successAccountsAbleToMoveDevices: false,
      errorAccountsAbleToMoveDevices: false,
    });

    httpApi
      .get('/account/' + AuthStore.getCsUserID() + '/list')
      .then(({ data }) => {
        //console.log("getAccounts Response",data);
        let currentAccountIndex = data.findIndex((acc) => acc.ID.toLowerCase() == this.state.accountID);
        //console.log("currentAccountIndex",data,currentAccountIndex,this.state.accountID);
        data.splice(currentAccountIndex, 1);
        data.sort((a, b) => {
          let textA = a.AccountName.toLowerCase();
          let textB = b.AccountName.toLowerCase();
          if (textA === '' || textA === null) return 1;
          if (textB === '' || textB === null) return -1;
          if (textA === textB) return 0;
          return textA < textB ? -1 : 1;
        });
        this.setState({
          loadingAccountsAbleToMoveDevices: false,
          accountsAbleToMoveDevices: data,
          successAccountsAbleToMoveDevices: true,
          errorAccountsAbleToMoveDevices: false,
        });
      })
      .catch((err) => {
        console.log('getAccounts Error', err);
        this.setState({
          loadingAccountsAbleToMoveDevices: false,
          accountsAbleToMoveDevices: [],
          successAccountsAbleToMoveDevices: false,
          errorAccountsAbleToMoveDevices: false,
        });
      });
  }

  componentDidMount() {
    //Register Listener

    const { match } = this.props;

    this.setState(
      {
        accountID: match.params.accountId,
      },
      () => {
        this.getAccounts();
        // This is needed to have the list of all the accounts ready for the functionality of moving devices
        //  from one account to another.
      },
    );

    switch (GeneralStore.getClientTab()) {
      case 1:
        this.handleStatsTab();
        break;
      case 2:
        this.handleDevicesTab();
        break;
      case 3:
        this.handleUsersTab();
        break;
      case 4:
        this.handleClientsTab();
        break;
      case 5:
        this.handleButtonsTab();
        break;
      case 6:
        this.handleBioDevicesTab();
        break;
      case 7:
        this.handleScheduleTab();
        break;
      case 8:
        this.handlePatientsTab();
        break;
      case 9:
        this.handlePatientsTimeZones();
        break;
      case 10:
        this.handleExternalAppsSubTab();
        break;
    }

    CareAccountStore.on('onGetAllAccountsData', this.onGetAllAccountsDataAction);

    CareAccountStore.on('onGetAccountData', this.onGetAccountDataAction);

    CareAccountStore.on('onGetUpdateGroupsData', this.onGetUpdateGroupsDataAction);

    CareAccountStore.on('onGetAccountAlertsData', this.onGetAccountAlertsDataAction);
    CareAccountStore.on('onTemplatesData', this.onTemplatesDataAction);

    CareAccountStore.on('onEscalationTimeOutUpdated', this.onEscalationTimeOutUpdatedAction);

    CareAccountStore.on('onUpdatesGroupUpdated', this.onUpdatesGroupUpdatedAction);

    CareAccountStore.on('onShowCallButtonUpdated', this.onShowCallButtonUpdatedAction);
    CareAccountStore.on('onShowCallButton2Updated', this.onShowCallButton2UpdatedAction);

    CareAccountStore.on('onShowCheckInButtonUpdated', this.onShowCheckInButtonUpdatedAction);
    CareAccountStore.on('onShowEmergencyButtonUpdated', this.onShowEmergencyButtonUpdatedAction);

    CareAccountStore.on('onRingOnRequestUpdated', this.onRingOnRequestUpdatedAction);

    CareAccountStore.on('onEnableBluetoothButtonsUpdated', this.onEnableBluetoothButtonsUpdatedAction);

    CareAccountStore.on('onShowCallControlsUpdated', this.onShowCallControlsUpdatedAction);
    CareAccountStore.on('onShowVideoStatsUpdated', this.onShowVideoStatsUpdatedAction);

    CareAccountStore.on('onShowFamilyCallButtonUpdated', this.onShowFamilyCallButtonUpdatedAction);

    CareAccountStore.on('onEnableAutoAnswerUpdated', this.onEnableAutoAnswerUpdatedAction);

    CareAccountStore.on('onForceLandscapeVideoUpdated', this.onForceLandscapeVideoUpdatedAction);

    CareAccountStore.on('onEnableEmergencyScheduleUpdated', this.onEnableEmergencyScheduleUpdatedAction);
    CareAccountStore.on('onEnableCallScheduleUpdated', this.onEnableCallScheduleUpdatedAction);

    CareAccountStore.on('onEnableAudioCallsOnlyUpdated', this.onHandleAudioCallsOnlyAction);

    CareAccountStore.on('onGetAccountDevices', this.onGetAccountDevicesAction);

    CareAccountStore.on('onDeleteDeviceModal', this.onDeleteDeviceModalAction);
    CareAccountStore.on('onDeviceFromAccountRemoved', this.onDeviceFromAccountRemovedAction);

    CareAccountStore.on('onDeviceAdded', this.onDeviceAddedAction);

    CareAccountStore.on('onDeviceFromLegacyAdded', this.onDeviceFromLegacyAddedAction);

    CareAccountStore.on('onRebootDeviceRequested', this.onRebootDeviceRequestedAction);

    CareAccountStore.on('onDownloadDeviceLogsModal', this.onDownloadDeviceLogsModalAction);

    CareAccountStore.on('onGetAccountUsers', this.onGetAccountUsersAction);
    CareAccountStore.on('onGetAccountPatientsStats', this.onGetAccountPatientsStatsAction);
    CareAccountStore.on('onGetAccountButtons', this.onGetAccountButtonsAction);

    //CareAccountStore.on("onButtonAdded", this.onButtonAddedAction);

    CareAccountStore.on('onUnblockApiButtonModal', this.onUnblockApiButtonModalAction);
    CareAccountStore.on('onUnblockApiUpdated', this.onUnblockApiUpdatedAction);

    CareAccountStore.on('onGenerateApiTokenButtonModal', this.onGenerateApiTokenButtonModalAction);
    CareAccountStore.on('onApiTokenUpdated', this.onApiTokenUpdatedAction);

    CareAccountStore.on('onHipaaCompliantUpdated', this.onHipaaCompliantUpdatedAction);
    CareAccountStore.on('onAutoLogoutUpdated', this.onAutoLogoutUpdatedAction);

    CareAccountStore.on('onSendToPagerDutyUpdated', this.onSendToPagerDutyUpdatedAction);
    CareAccountStore.on('onPagerDutyEmailUpdated', this.onPagerDutyEmailUpdatedAction);

    CareAccountStore.on('onClearingSMSandEmailAccountAlertsUpdated', this.onClearingSMSandEmailAccountAlertsUpdatedAction);
    CareAccountStore.on('onAlertOnDutyUsersByEmailUpdated', this.onAlertOnDutyUsersByEmailUpdatedAction);
    CareAccountStore.on('onNotifyOnDeviceOfflineUpdated', this.onNotifyOnDeviceOfflineUpdatedAction);
    CareAccountStore.on('onDeviceOfflineEnableUpdated', this.onDeviceOfflineEnableUpdatedAction);
    CareAccountStore.on('onDeviceOfflineSMSUpdated', this.onDeviceOfflineSMSUpdatedAction);
    CareAccountStore.on('onDeviceOfflineEmailUpdated', this.onDeviceOfflineEmailUpdatedAction);
    CareAccountStore.on('onCallNotAnsweredEnableUpdated', this.onCallNotAnsweredEnableUpdatedAction);
    CareAccountStore.on('onCallNotEstablishedEnabledUpdated', this.onCallNotEstablishedEnabledUpdatedAction);

    CareAccountStore.on('onCallNotAnsweredSMSUpdated', this.onCallNotAnsweredSMSUpdatedAction);
    CareAccountStore.on('onCallNotEstablishedSMSUpdated', this.onCallNotEstablishedSMSUpdatedAction);

    CareAccountStore.on('onCallNotAnsweredEmailUpdated', this.onCallNotAnsweredEmailUpdatedAction);
    CareAccountStore.on('onCallNotEstablishedEmailUpdated', this.onCallNotEstablishedEmailUpdatedAction);

    CareAccountStore.on('onMessageAlertEnableUpdated', this.onMessageAlertEnableUpdatedAction);
    CareAccountStore.on('onMessageAlertSMSUpdated', this.onMessageAlertSMSUpdatedAction);
    CareAccountStore.on('onMessageAlertEmailUpdated', this.onMessageAlertEmailUpdatedAction);
    CareAccountStore.on('onBiometricAlertEnableUpdated', this.onBiometricAlertEnableUpdatedAction);
    CareAccountStore.on('onBiometricAlertSMSUpdated', this.onBiometricAlertSMSUpdatedAction);
    CareAccountStore.on('onBiometricAlertEmailUpdated', this.onBiometricAlertEmailUpdatedAction);
    CareAccountStore.on('onMiscAlertEnableUpdated', this.onMiscAlertEnableUpdatedAction);
    CareAccountStore.on('onBatteryAlertEnableUpdated', this.onBatteryAlertEnableUpdatedAction);
    CareAccountStore.on('onMiscAlertSMSUpdated', this.onMiscAlertSMSUpdatedAction);
    CareAccountStore.on('onMiscAlertEmailUpdated', this.onMiscAlertEmailUpdatedAction);
    CareAccountStore.on('onBatteryAlertSMSUpdated', this.onBatteryAlertSMSUpdatedAction);
    CareAccountStore.on('onBatteryAlertEmailUpdated', this.onBatteryAlertEmailUpdatedAction);
    CareAccountStore.on('onHomeAlertEnableUpdated', this.onHomeAlertEnableUpdatedAction);
    CareAccountStore.on('onHomeAlertSMSUpdated', this.onHomeAlertSMSUpdatedAction);
    CareAccountStore.on('onHomeAlertEmailUpdated', this.onHomeAlertEmailUpdatedAction);
    CareAccountStore.on('onBleDevicesAlertEnableUpdated', this.onBleDevicesAlertEnableUpdatedAction);
    CareAccountStore.on('onBleDevicesAlertSMSUpdated', this.onBleDevicesAlertSMSUpdatedAction);
    CareAccountStore.on('onBleDevicesAlertEmailUpdated', this.onBleDevicesAlertEmailUpdatedAction);
    CareAccountStore.on('onAlertOnExtremeRoomTemperatureUpdated', this.onAlertOnExtremeRoomTemperatureUpdatedAction);
    //CareAccountStore.on("onMessageToAllPatientsUpdated", this.onMessageToAllPatientsUpdatedAction);

    CareAccountStore.on('onEnableControlVideoCallShortcutUpdated', this.onEnableControlVideoCallUpdatedAction);
    CareAccountStore.on('onEnableControlAudioCallShortcutUpdated', this.onEnableControlAudioCallUpdatedAction);
    CareAccountStore.on('onEnableControlBargeInCallShortcutUpdated', this.onEnableControlBargeInCallUpdatedAction);
    CareAccountStore.on('onEnableControlSilentBargeInCallShortcutUpdated', this.onEnableControlSilentBargeInCallUpdatedAction);
    CareAccountStore.on('onEnableControlAutomaticCallRequestBargeInUpdated', this.onEnableControlAutomaticCallRequestBargeInUpdatedAction);

    CareAccountStore.on('onRemoteLogsUpdated', this.onRemoteLogsUpdatedAction);

    CareAccountStore.on('onEnableMemoryBoxUpdated', this.onEnableMemoryBoxUpdatedAction);

    CareAccountStore.on('onScreenSaverDelayUpdated', this.onScreenSaverDelayUpdatedAction);

    CareAccountStore.on('onOfflineTimeSpanUpdated', this.onOfflineTimeSpanUpdatedAction);

    CareAccountStore.on('onDimStartUpdated', this.onDimStartUpdatedAction);
    CareAccountStore.on('onDimEndUpdated', this.onDimEndUpdatedAction);
    CareAccountStore.on('onDimOffBrightnessUpdated', this.onDimOffBrightnessUpdatedAction);
    CareAccountStore.on('onDimOnBrightnessUpdated', this.onDimOnBrightnessUpdatedAction);

    CareAccountStore.on('onSettingsAccessPINUpdated', this.onSettingsAccessPINUpdatedAction);
    CareAccountStore.on('onInactiveDaysUpdated', this.onInactiveDaysUpdatedAction);
    CareAccountStore.on('onInactiveDaysAlertUpdated', this.onInactiveDaysAlertUpdatedAction);
    CareAccountStore.on('onHexnodeUserNameUpdated', this.onHexnodeUserNameUpdatedAction);
    CareAccountStore.on('onHexnodeAPIUpdated', this.onHexnodeAPIUpdatedAction);
    CareAccountStore.on('onHexnodeGroupIDUpdated', this.onHexnodeGroupIDUpdated);
    CareAccountStore.on('onHexnodeUrlUpdated', this.onHexnodeUrlUpdatedAction);
    CareAccountStore.on('onHexnodePolicyIDUpdated', this.onHexnodePolicyIDUpdated);

    CareAccountStore.on('onHexnodeIdRequiredUpdated', this.onHexnodeIdRequiredUpdatedAction);

    CareAccountStore.on('onCheckedInScreenStaysOnUpdated', this.onCheckedInScreenStaysOnUpdatedAction);
    CareAccountStore.on('onRestartDeviceOfflineUpdated', this.onRestartDeviceOfflineUpdatedAction);
    CareAccountStore.on('onRestartOfflineDeviceTimeSpanUpdated', this.onRestartOfflineDeviceTimeSpanUpdatedAction);
    CareAccountStore.on('onShowBatteryStatusUpdated', this.onShowBatteryStatusUpdatedAction);

    CareAccountStore.on('onUseDeviceTemplateUpdated', this.onUseDeviceTemplateUpdatedAction);
    CareAccountStore.on('onBackgroundImgUrlUpdated', this.onBackgroundImgUrlUpdatedAction);

    CareAccountStore.on('onAccountTemplateUpdated', this.onAccountTemplateUpdatedAction);
    CareAccountStore.on('onEnableBackgroundImageUpdated', this.onEnableBackgroundImageUpdatedAction);
    CareAccountStore.on('onEnableBackgroundLogoUpdated', this.onEnableBackgroundLogoUpdatedAction);

    CareAccountStore.on('onUploadBackgroundImageComplete', this.onUploadBackgroundImageCompleteAction);
    CareAccountStore.on('onUploadBackgroundLogoComplete', this.onUploadBackgroundLogoCompleteAction);

    CareAccountStore.on('onEnableBulletinButtonUpdated', this.onEnableBulletinButtonUpdatedAction);
    CareAccountStore.on('onBulletinButtonNameUpdated', this.onBulletinButtonNameUpdatedAction);

    CareAccountStore.on('onEnableMessageResponseUpdated', this.onEnableMessageResponseUpdatedAction);
    CareAccountStore.on('onMessageResponseEmailUpdated', this.onMessageResponseEmailUpdatedAction);
    CareAccountStore.on('onEscalationEmailUpdated', this.onEscalationEmailUpdatedAction);

    // Biometric Devices
    CareAccountStore.on('onGetAccountBiometricDevices', this.onGetAccountBiometricDevicesAction);

    CareAccountStore.on('onGetAccountBulletinButtons', this.onGetAccountBulletinButtonsAction);

    CareAccountStore.on('onAccountDeleted', this.onAccountDeletedAction);

    CareAccountStore.on('onEnableRemoteLogsChanged', this.onEnableRemoteLogsChangedAction);

    CareAccountStore.on('onSalesforceEnabledUpdated', this.onSalesforceEnabledUpdatedAction);
    CareAccountStore.on('onSalesforceOrgUrlUpdated', this.onSalesforceOrgUrlUpdatedAction);
    CareAccountStore.on('onSalesforceLoginUrlUpdated', this.onSalesforceLoginUrlUpdatedAction);

    CareAccountStore.on('onSalesforceClientIDUpdated', this.onSalesforceClientIDUpdatedAction);
    CareAccountStore.on('onSalesforceClientSecretUpdated', this.onSalesforceClientSecretUpdatedAction);
    CareAccountStore.on('onSalesforceUserNameUpdated', this.onSalesforceUserNameUpdatedAction);
    CareAccountStore.on('onSalesforcePasswordUpdated', this.onSalesforcePasswordUpdatedAction);
    CareAccountStore.on('onSalesforceRedirectUriUpdated', this.onSalesforceRedirectUriUpdatedAction);
    CareAccountStore.on('onSalesforceSecurityTokenUpdated', this.onSalesforceSecurityTokenUpdatedAction);

    CareAccountStore.on('onCheckSalesforceConfigurationResponse', this.onCheckSalesforceConfigurationResponseAction);
    CareAccountStore.on('onCheckSalesforceIntegrationResponse', this.onCheckSalesforceIntegrationResponseAction);
    CareAccountStore.on('onConfigureSalesforceIntegrationResponse', this.onConfigureSalesforceIntegrationResponseAction);

    //@ts-ignore
    window.$('.modal').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });

    //@ts-ignore
    window.$('.modalSelectTemplate').modal({
      dismissible: true,
      complete: function () {}, // Callback for Modal close
    });

    this.setState({
      updateGroupsArray: CareAccountStore.getUpdateGroups(),
      updateAccountLocaleArray: CareAccountStore.getAccountLocale(),
      accountsCountriesArray: CareAccountStore.getAccountsCountries(),
      accountAlertsArray: CareAccountStore.getAccountAlerts(),
      templatesArray: CareAccountStore.getAvailableTemplates(),
    });

    this.Init(null);

    this.devicesInfoReloaded = -1;

    CareAccountStore.getAccountDevices({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
      AccountID: this.state.accountID,
    });
    this.setState({
      loading: true,
    });

    this.usersInfoReloaded = -1;
    CareAccountStore.getAccountUsers({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
      AccountID: this.state.accountID,
    });

    this.clientsInfoReloaded = -1;
    CareAccountStore.getAccountPatientsStats({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
      AccountID: this.state.accountID,
    });

    CareAccountStore.getAccountBiometricDevices({
      AccountID: this.state.accountID,
    });

    this.setState({
      missingBiometricDevicesInfo: false,
      missingBiometricDevicesPatientInfo: false,
    });

    //@ts-ignore
    window.$('.tooltipped').tooltip({ delay: 50 });

    setTimeout(() => {
      CareAccountStore.getTemplatesData();
    }, 700);
  }

  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  componentWillUnmount() {
    //Remove Listener
    CareAccountStore.removeListener('onGetAllAccountsData', this.onGetAllAccountsDataAction);
    CareAccountStore.removeListener('onGetAccountData', this.onGetAccountDataAction);
    CareAccountStore.removeListener('onEscalationTimeOutUpdated', this.onEscalationTimeOutUpdatedAction);

    CareAccountStore.removeListener('onGetUpdateGroupsData', this.onGetUpdateGroupsDataAction);
    CareAccountStore.removeListener('onGetAccountAlertsData', this.onGetAccountAlertsDataAction);
    CareAccountStore.removeListener('onTemplatesData', this.onTemplatesDataAction);

    CareAccountStore.removeListener('onUpdatesGroupUpdated', this.onUpdatesGroupUpdatedAction);

    CareAccountStore.removeListener('onShowCallButtonUpdated', this.onShowCallButtonUpdatedAction);
    CareAccountStore.removeListener('onShowCallButton2Updated', this.onShowCallButton2UpdatedAction);

    CareAccountStore.removeListener('onShowCheckInButtonUpdated', this.onShowCheckInButtonUpdatedAction);
    CareAccountStore.removeListener('onShowEmergencyButtonUpdated', this.onShowEmergencyButtonUpdatedAction);

    CareAccountStore.removeListener('onRingOnRequestUpdated', this.onRingOnRequestUpdatedAction);
    CareAccountStore.removeListener('onEnableBluetoothButtonsUpdated', this.onEnableBluetoothButtonsUpdatedAction);

    CareAccountStore.removeListener('onShowCallControlsUpdated', this.onShowCallControlsUpdatedAction);
    CareAccountStore.removeListener('onShowVideoStatsUpdated', this.onShowVideoStatsUpdatedAction);
    CareAccountStore.removeListener('onShowFamilyCallButtonUpdated', this.onShowFamilyCallButtonUpdatedAction);
    CareAccountStore.removeListener('onEnableAutoAnswerUpdated', this.onEnableAutoAnswerUpdatedAction);

    CareAccountStore.removeListener('onForceLandscapeVideoUpdated', this.onForceLandscapeVideoUpdatedAction);

    CareAccountStore.removeListener('onEnableEmergencyScheduleUpdated', this.onEnableEmergencyScheduleUpdatedAction);
    CareAccountStore.removeListener('onEnableCallScheduleUpdated', this.onEnableCallScheduleUpdatedAction);

    CareAccountStore.removeListener('onEnableAudioCallsOnlyUpdated', this.onHandleAudioCallsOnlyAction);

    CareAccountStore.removeListener('onGetAccountDevices', this.onGetAccountDevicesAction);

    CareAccountStore.removeListener('onDeleteDeviceModal', this.onDeleteDeviceModalAction);
    CareAccountStore.removeListener('onDeviceFromAccountRemoved', this.onDeviceFromAccountRemovedAction);

    CareAccountStore.removeListener('onDeviceAdded', this.onDeviceAddedAction);

    CareAccountStore.removeListener('onDeviceFromLegacyAdded', this.onDeviceFromLegacyAddedAction);

    CareAccountStore.removeListener('onRebootDeviceRequested', this.onRebootDeviceRequestedAction);

    CareAccountStore.removeListener('onDownloadDeviceLogsModal', this.onDownloadDeviceLogsModalAction);

    CareAccountStore.removeListener('onGetAccountUsers', this.onGetAccountUsersAction);
    CareAccountStore.removeListener('onGetAccountPatientsStats', this.onGetAccountPatientsStatsAction);
    CareAccountStore.removeListener('onGetAccountButtons', this.onGetAccountButtonsAction);

    CareAccountStore.removeListener('onUnblockAPIButtonModal', this.onUnblockApiButtonModalAction);
    CareAccountStore.removeListener('onUnblockApiUpdated', this.onUnblockApiUpdatedAction);

    CareAccountStore.removeListener('onGenerateApiTokenButtonModal', this.onGenerateApiTokenButtonModalAction);
    CareAccountStore.removeListener('onApiTokenUpdated', this.onApiTokenUpdatedAction);

    CareAccountStore.removeListener('onHipaaCompliantUpdated', this.onHipaaCompliantUpdatedAction);
    CareAccountStore.removeListener('onAutoLogoutUpdated', this.onAutoLogoutUpdatedAction);

    CareAccountStore.removeListener('onSendToPagerDutyUpdated', this.onSendToPagerDutyUpdatedAction);
    CareAccountStore.removeListener('onPagerDutyEmailUpdated', this.onPagerDutyEmailUpdatedAction);

    CareAccountStore.removeListener('onClearingSMSandEmailAccountAlertsUpdated', this.onClearingSMSandEmailAccountAlertsUpdatedAction);
    CareAccountStore.removeListener('onAlertOnDutyUsersByEmailUpdated', this.onAlertOnDutyUsersByEmailUpdatedAction);
    CareAccountStore.removeListener('onNotifyOnDeviceOfflineUpdated', this.onNotifyOnDeviceOfflineUpdatedAction);
    CareAccountStore.removeListener('onDeviceOfflineEnableUpdated', this.onDeviceOfflineEnableUpdatedAction);
    CareAccountStore.removeListener('onDeviceOfflineSMSUpdated', this.onDeviceOfflineSMSUpdatedAction);
    CareAccountStore.removeListener('onDeviceOfflineEmailUpdated', this.onDeviceOfflineEmailUpdatedAction);
    CareAccountStore.removeListener('onCallNotAnsweredEnableUpdated', this.onCallNotAnsweredEnableUpdatedAction);
    CareAccountStore.removeListener('onCallNotEstablishedEnabledUpdated', this.onCallNotEstablishedEnabledUpdatedAction);

    CareAccountStore.removeListener('onCallNotAnsweredSMSUpdated', this.onCallNotAnsweredSMSUpdatedAction);
    CareAccountStore.removeListener('onCallNotEstablishedSMSUpdated', this.onCallNotEstablishedSMSUpdatedAction);
    CareAccountStore.removeListener('onCallNotAnsweredEmailUpdated', this.onCallNotAnsweredEmailUpdatedAction);
    CareAccountStore.removeListener('onCallNotEstablishedEmailUpdated', this.onCallNotEstablishedEmailUpdatedAction);
    CareAccountStore.removeListener('onMessageAlertEnableUpdated', this.onMessageAlertEnableUpdatedAction);
    CareAccountStore.removeListener('onMessageAlertSMSUpdated', this.onMessageAlertSMSUpdatedAction);
    CareAccountStore.removeListener('onMessageAlertEmailUpdated', this.onMessageAlertEmailUpdatedAction);
    CareAccountStore.removeListener('onBiometricAlertEnableUpdated', this.onBiometricAlertEnableUpdatedAction);
    CareAccountStore.removeListener('onBiometricAlertSMSUpdated', this.onBiometricAlertSMSUpdatedAction);
    CareAccountStore.removeListener('onBiometricAlertEmailUpdated', this.onBiometricAlertEmailUpdatedAction);
    CareAccountStore.removeListener('onMiscAlertEnableUpdated', this.onMiscAlertEnableUpdatedAction);
    CareAccountStore.removeListener('onBatteryAlertEnableUpdated', this.onBatteryAlertEnableUpdatedAction);
    CareAccountStore.removeListener('onBatteryAlertSMSUpdated', this.onBatteryAlertSMSUpdatedAction);
    CareAccountStore.removeListener('onBatteryAlertEmailUpdated', this.onBatteryAlertEmailUpdatedAction);
    CareAccountStore.removeListener('onMiscAlertSMSUpdated', this.onMiscAlertSMSUpdatedAction);
    CareAccountStore.removeListener('onMiscAlertEmailUpdated', this.onMiscAlertEmailUpdatedAction);
    CareAccountStore.removeListener('onHomeAlertEnableUpdated', this.onHomeAlertEnableUpdatedAction);
    CareAccountStore.removeListener('onHomeAlertSMSUpdated', this.onHomeAlertSMSUpdatedAction);
    CareAccountStore.removeListener('onHomeAlertEmailUpdated', this.onHomeAlertEmailUpdatedAction);
    CareAccountStore.removeListener('onBleDevicesAlertEnableUpdated', this.onBleDevicesAlertEnableUpdatedAction);
    CareAccountStore.removeListener('onBleDevicesAlertSMSUpdated', this.onBleDevicesAlertSMSUpdatedAction);
    CareAccountStore.removeListener('onBleDevicesAlertEmailUpdated', this.onBleDevicesAlertEmailUpdatedAction);
    CareAccountStore.removeListener('onAlertOnExtremeRoomTemperatureUpdated', this.onAlertOnExtremeRoomTemperatureUpdatedAction);

    CareAccountStore.removeListener('onEnableControlVideoCallShortcutUpdated', this.onEnableControlVideoCallUpdatedAction);
    CareAccountStore.removeListener('onEnableControlAudioCallShortcutUpdated', this.onEnableControlAudioCallUpdatedAction);
    CareAccountStore.removeListener('onEnableControlBargeInCallShortcutUpdated', this.onEnableControlBargeInCallUpdatedAction);
    CareAccountStore.removeListener('onEnableControlSilentBargeInCallShortcutUpdated', this.onEnableControlSilentBargeInCallUpdatedAction);
    CareAccountStore.removeListener('onEnableControlAutomaticCallRequestBargeInUpdated', this.onEnableControlAutomaticCallRequestBargeInUpdatedAction);

    CareAccountStore.removeListener('onRemoteLogsUpdated', this.onRemoteLogsUpdatedAction);

    CareAccountStore.removeListener('onEnableMemoryBoxUpdated', this.onEnableMemoryBoxUpdatedAction);

    CareAccountStore.removeListener('onScreenSaverDelayUpdated', this.onScreenSaverDelayUpdatedAction);
    CareAccountStore.removeListener('onOfflineTimeSpanUpdated', this.onOfflineTimeSpanUpdatedAction);

    CareAccountStore.removeListener('onDimStartUpdated', this.onDimStartUpdatedAction);
    CareAccountStore.removeListener('onDimEndUpdated', this.onDimEndUpdatedAction);
    CareAccountStore.removeListener('onDimOffBrightnessUpdated', this.onDimOffBrightnessUpdatedAction);
    CareAccountStore.removeListener('onDimOnBrightnessUpdated', this.onDimOnBrightnessUpdatedAction);

    CareAccountStore.removeListener('onSettingsAccessPINUpdated', this.onSettingsAccessPINUpdatedAction);
    CareAccountStore.removeListener('onInactiveDaysUpdated', this.onInactiveDaysUpdatedAction);
    CareAccountStore.removeListener('onInactiveDaysAlertUpdated', this.onInactiveDaysAlertUpdatedAction);
    CareAccountStore.removeListener('onHexnodeUserNameUpdated', this.onHexnodeUserNameUpdatedAction);
    CareAccountStore.removeListener('onHexnodeAPIUpdated', this.onHexnodeAPIUpdatedAction);
    CareAccountStore.removeListener('onHexnodeGroupIDUpdated', this.onHexnodeGroupIDUpdated);
    CareAccountStore.removeListener('onHexnodeUrlUpdated', this.onHexnodeUrlUpdatedAction);
    CareAccountStore.removeListener('onHexnodePolicyIDUpdated', this.onHexnodePolicyIDUpdated);

    CareAccountStore.removeListener('onHexnodeIdRequiredUpdated', this.onHexnodeIdRequiredUpdatedAction);

    CareAccountStore.removeListener('onCheckedInScreenStaysOnUpdated', this.onCheckedInScreenStaysOnUpdatedAction);
    CareAccountStore.removeListener('onRestartDeviceOfflineUpdated', this.onRestartDeviceOfflineUpdatedAction);
    CareAccountStore.removeListener('onRestartOfflineDeviceTimeSpanUpdated', this.onRestartOfflineDeviceTimeSpanUpdatedAction);
    CareAccountStore.removeListener('onShowBatteryStatusUpdated', this.onShowBatteryStatusUpdatedAction);

    CareAccountStore.removeListener('onUseDeviceTemplateUpdated', this.onUseDeviceTemplateUpdatedAction);
    CareAccountStore.removeListener('onBackgroundImgUrlUpdated', this.onBackgroundImgUrlUpdatedAction);

    CareAccountStore.removeListener('onAccountTemplateUpdated', this.onAccountTemplateUpdatedAction);
    CareAccountStore.removeListener('onEnableBackgroundImageUpdated', this.onEnableBackgroundImageUpdatedAction);
    CareAccountStore.removeListener('onEnableBackgroundLogoUpdated', this.onEnableBackgroundLogoUpdatedAction);
    CareAccountStore.removeListener('onUploadBackgroundImageComplete', this.onUploadBackgroundImageCompleteAction);
    CareAccountStore.removeListener('onUploadBackgroundLogoComplete', this.onUploadBackgroundLogoCompleteAction);

    CareAccountStore.removeListener('onEnableBulletinButtonUpdated', this.onEnableBulletinButtonUpdatedAction);
    CareAccountStore.removeListener('onBulletinButtonNameUpdated', this.onBulletinButtonNameUpdatedAction);

    CareAccountStore.removeListener('onEnableMessageResponseUpdated', this.onEnableMessageResponseUpdatedAction);
    CareAccountStore.removeListener('onMessageResponseEmailUpdated', this.onMessageResponseEmailUpdatedAction);
    CareAccountStore.removeListener('onEscalationEmailUpdated', this.onEscalationEmailUpdatedAction);

    CareAccountStore.removeListener('onGetAccountBiometricDevices', this.onGetAccountBiometricDevicesAction);

    CareAccountStore.removeListener('onGetAccountBulletinButtons', this.onGetAccountBulletinButtonsAction);

    CareAccountStore.removeListener('onAccountDeleted', this.onAccountDeletedAction);

    CareAccountStore.removeListener('onEnableRemoteLogsChanged', this.onEnableRemoteLogsChangedAction);

    CareAccountStore.removeListener('onSalesforceEnabledUpdated', this.onSalesforceEnabledUpdatedAction);
    CareAccountStore.removeListener('onSalesforceOrgUrlUpdated', this.onSalesforceOrgUrlUpdatedAction);
    CareAccountStore.removeListener('onSalesforceClientIDUpdated', this.onSalesforceClientIDUpdatedAction);
    CareAccountStore.removeListener('onSalesforceClientSecretUpdated', this.onSalesforceClientSecretUpdatedAction);
    CareAccountStore.removeListener('onSalesforceUserNameUpdated', this.onSalesforceUserNameUpdatedAction);
    CareAccountStore.removeListener('onSalesforcePasswordUpdated', this.onSalesforcePasswordUpdatedAction);
    CareAccountStore.removeListener('onSalesforceRedirectUriUpdated', this.onSalesforceRedirectUriUpdatedAction);
    CareAccountStore.removeListener('onSalesforceSecurityTokenUpdated', this.onSalesforceSecurityTokenUpdatedAction);
    CareAccountStore.removeListener('onCheckSalesforceConfigurationResponse', this.onCheckSalesforceConfigurationResponseAction);
    CareAccountStore.removeListener('onCheckSalesforceIntegrationResponse', this.onCheckSalesforceIntegrationResponseAction);

    CareAccountStore.removeListener('onConfigureSalesforceIntegrationResponse', this.onConfigureSalesforceIntegrationResponseAction);
    //@ts-ignore
    window.$('.tooltipped').tooltip('remove');
  }

  Init(responseData) {
    const { match } = this.props;
    console.log('INIT -', responseData);
    if (match.params.accountId != null) {
      //Get the Account Data from the Store

      let accountData = null;
      if (responseData) {
        accountData = responseData;
        console.log('AccountData 1: ', accountData);
      } else {
        accountData = CareAccountStore.getAccountInfo(match.params.accountId);
        console.log('AccountData 2: ', accountData);
      }

      console.log('accountData.AccountTemplateBulletinButtons', accountData.AccountTemplateBulletinButtons)

      if (accountData) {
        this.setState({
          accountApiToken: accountData.APIToken,
          accountApiUsageLimit: accountData.APIUsageConsecutiveLimitViolation,
          accountID: match.params.accountId,
          accountName: accountData.Name,
          accountLogo: accountData.Logo,
          accountGroupID: accountData.AccountGroupID ? accountData.AccountGroupID.toLowerCase() : '',
          accountUpdateGroupID: accountData.UpdateGroupID ? accountData.UpdateGroupID.toLowerCase() : '',
          accountZipCode: accountData.ZipCode,
          accountLocaleTag: accountData.LocaleTag,
          accountCountryCode: accountData.CountryCode,

          accountShowCallButton: accountData.ShowCallButton,
          accountShowCallButton2: accountData.ShowCallButton2,
          accountClientRoleNamePlural: accountData.ClientRoleNamePlural,
          accountShowCheckInButton: accountData.ShowCheckInButton,
          accountShowEmergencyButton: accountData.ShowEmergencyButton,
          accountRingOnRequest: accountData.RingOnRequest,
          accountEnableBiometrics: accountData.EnableBiometrics,
          accountEnableBluetoothButtons: accountData.EnableBluetoothButtons,

          accountShowCallControls: accountData.ShowCallButtonsBar,
          accountShowStats: accountData.ShowVideoStats,
          accountShowFamilyCallBtn: accountData.ShowFamilyCallButton,
          accountAutoAnswer: accountData.EnableAutoAnswer,
          accountFamilyAutoAnswer: accountData.EnableAutoAnswerForFamily,
          accountFamilyAppAutoAnswer: accountData.EnableAutoAnswerForFamilyApp,
          accountForceLandscapeVideo: accountData.ForceLandscapeVideo,
          accountRingIncomingCall: accountData.EnableRingOnIncomingCall,
          accountVoiceMessage: accountData.EnableVoiceMessage,

          // Start of Languages
          accountSelectedLanguage: accountData.LocaleTag,
          btnUpdateCallButtonTextStyle: accountData.ShowCallButton ? '' : 'disabled',
          btnUpdateCallButtonText2Style: accountData.ShowCallButton2 ? '' : 'disabled',
          btnUpdateEmergencyButtonTextStyle: accountData.ShowEmergencyButton ? '' : 'disabled',
          accountMemoryBoxStyle: accountData.EnableMemoryBox ? '' : 'disabled',
          accountMemoryBox: accountData.EnableMemoryBox,
          accountFamilyMemberStyle: accountData.ShowFamilyCallButton ? '' : 'disabled',
          accountBiometricsStyle: accountData.EnableBiometrics ? '' : 'disabled',
          btnOffHoursMessageStyle: accountData.EnableEmergencyButtonSchedule || accountData.EnableCallButtonSchedule ? '' : 'disabled',
          loadingStrings: true,
          // End of Languages

          accountAudioCallsOnly: accountData.AudioCallsOnly,
          intercomButtonEnabled: accountData.IntercomButtonEnabled,
          powerDisconnectedWarningEnabled: accountData?.PowerDisconnectedWarningEnabled,
          accountScheduleEnabled: accountData.EnableEmergencyButtonSchedule || accountData.EnableCallButtonSchedule,
          accountEmergencySchedule: accountData.EnableEmergencyButtonSchedule,
          accountCallSchedule: accountData.EnableCallButtonSchedule,

          deviceUxVersion: accountData.NucleusDeviceUXVersion,

          accountImportClientsEnabled: accountData.EnableImportClients,
          accountSendToPagerDuty: accountData.SendToPagerDuty,
          accountPagerDutyEmail: accountData.PagerDutyEmailAddress,
          btnPagerDutyStyle: accountData.SendToPagerDuty ? '' : 'disabled',

          accountSendWelcomeEmail: accountData.SendWelcomeEmail,
          accountSendWelcomeSms: accountData.SendWelcomeSMS,

          enableVideoCallShortcutValue: accountData.VideoCallShortcut,
          enableAudioCallShortcutValue: accountData.AudioCallShortcut,
          enableBargeInCallShortcutValue: accountData.BargeInCallShortcut,
          enableSilentBargeInCallShortcutValue: accountData.SilentBargeInCallShortcut,

          enableAutomaticCallRequestBargeInValue: accountData.AutomaticCallRequestBargeIn,
          enableMoveFamily: accountData.EnableMoveFamily,

          alertOnDutyUsers: accountData.AlertOnDutyUsers,
          notifyDeviceOffline: accountData.NotifyOnDeviceOffline,
          alertExtremeRoomTemperature: accountData.AlertOnExtremeRoomTemperature,

          panicEmergencyRingtone: accountData.UsePanicEmergencyRingtone,

          messageToAllPatients: accountData.EnableMessageToAllPatients,
          enableIncomingCall: accountData.EnableControlIncomingCall,
          enableAppIncomingCall: accountData.EnableAppIncomingCall,

          screenSaverDelay: accountData.ScreenSaverDelayInSeconds,
          escalationTimeOut: accountData.ReceiveEscalationCallRequestTimeout,
          offlineTimeSpan: accountData.OfflineTimeSpan,
          callTimeout: accountData.CallTimeoutDelayInSeconds,
          dimOffBrightness: accountData.DimOffValue,
          dimOnBrightness: accountData.DimOnValue,
          dimFrom: accountData.DimFrom,
          dimTo: accountData.DimTo,

          inactiveDays: accountData.InactiveDays,
          inactiveDaysAlert: accountData.InactiveDaysAlert,
          hexnodeUserName: accountData.HexnodeUserName,
          hexnodeAPI: accountData.HexnodeAPI,
          hexnodeGroupID: accountData.HexnodeGroupID,
          hexnodeUrl: accountData.HexnodeUrl,
          hexnodePolicyID: accountData.HexnodePolicyID,
          hexnodeIdRequired: accountData.HexnodeIdRequired,
          isCheckedInScreenStaysOn: accountData.IsCheckedInScreenStaysOn,

          accountSettingsPin: accountData.SettingsAccessPIN,

          accountUseBgImageTemplate: accountData.UseDeviceTemplate, // DEPRECATED Sep 2nd, 2019
          accountBackgroundImgUrl: accountData.BackgroundImage,

          accountTemplate: accountData.DeviceTemplate,
          accountTemplateName: accountData.AccountTemplateName,
          accountTemplateImageUrl: accountData.AccountTemplateImage,

          enableBackgroundImage: accountData.EnableBackgroundImage,
          enableBackgroundLogo: accountData.EnableBackgroundLogo,

          accountBackgroundImageUrl: accountData.BackgroundImage,
          accountBackgroundLogoUrl: accountData.BackgroundLogoImage,

          
          accountBulletinButtons: accountData.AccountTemplateBulletinButtons,

          sendMessageResponseByEmail: accountData.EnableMessageResponseByEmail,
          sendMessageResponseEmail: accountData.SendMessageResponseEmail,
          sendMessageResponseMode: accountData.EnableMessageResponseByEmail ? '' : 'disabled',

          escalationEmailAddress: accountData.EscalationAlertEmailAddress,

          updateGroupsArray: CareAccountStore.getUpdateGroups(),
          updateAccountLocaleArray: CareAccountStore.getAccountLocale(),
          accountsCountriesArray: CareAccountStore.getAccountsCountries(),
          accountAlertsArray: CareAccountStore.getAccountAlerts(),
          //accountDevices : accountData.TotalDevices,
          //accountUsers : accountData.TotalUsers

          isHipaaCompliant: accountData.IsHipaaCompliant,
          autoLogoutTime: accountData.AutoLogoutTimeout,
          autoLogoutTimeMode: !!accountData.IsHipaaCompliant,

          mobileCallResponse: accountData.MobileCallResponse,

          enable2FactorAuthValue: accountData.TwoFactorAuth,
          remoteLogsValue: accountData.RemoteLogs,

          salesforceEnabled: accountData.SalesforceEnabled,
          salesforceName: accountData.SalesforceName,
          salesforceClientID: accountData.SalesforceClientID,
          salesforceClientSecret: accountData.SalesforceClientSecret,
          salesforceUserName: accountData.SalesforceUserName,
          salesforcePassword: accountData.SalesforcePassword,
          salesforceRedirectUri: accountData.SalesforceRedirectUri,
          salesforceOrgUrl: accountData.SalesforceOrgUrl,
          salesforceLoginUrl: accountData.SalesforceLoginUrl,
          salesforceValidCredentials: accountData.SalesforceValidCredentials,
          salesforceSecurityToken: accountData.SalesforceSecurityToken,

          restartDeviceOfflineEnabled: accountData.RestartOfflineDeviceEnabled,
          restartDeviceOfflineTimeSpan: accountData.RestartOfflineDeviceTimeSpan,
          btnRestartDeviceOfflineTimeSpanStyle: accountData.RestartOfflineDeviceEnabled ? '' : 'disabled',

          showBatteryStatus: accountData.ShowBatteryStatus,
          screenOffBatterySaver: accountData.ScreenOffBatterySaver,
        });

        CareAccountStore.set2FactorAuthEnabled(accountData.TwoFactorAuth);
      }
    } else {
      this.props.history.push('/accounts');
    }
  }

  refreshAccountData = () => {
    CareAccountStore.getAccountData({
      AccountID: this.state.accountID,
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
    });
  };

  onGetAllAccountsDataAction = (success) => {
    if (success) {
      // Message.show("Account Information updated.");
      //alert("Response de Todos");
      this.Init(null);
    } else {
      Message.show('Sorry, There was a problem getting the account information. Please try again.');
    }
  };

  onGetAccountDataAction = (response) => {
    if (response && response.ok) {
      //console.log("Response Unica ", response);
      //Message.show("Account Information updated.");
      console.log('Response Unique');
      this.Init(response);
    } else {
      Message.show('Sorry, There was a problem getting the account information. Please try again.');
    }
  };

  onGetUpdateGroupsDataAction = (response) => {
    if (response) {
      this.setState({
        updateGroupsArray: CareAccountStore.getUpdateGroups(),
      });
      this.selectUpdateGroup.current.value = this.state.accountUpdateGroupID;
    } else {
      Message.show('Sorry, There was a problem getting the update groups information. Please try again.');
    }
  };

  onGetAccountAlertsDataAction = (response) => {
    console.log('onGetAccountAlertsDataAction: ');
    if (response) {
      this.setState({
        accountAlertsArray: CareAccountStore.getAccountAlerts(),
      });
      // setTimeout(() => {
      //     //this.refs.selectAccountAlerts.value = this.state.
      // }, 90);
    } else {
      Message.show('Sorry, There was a problem getting the update alerts information. Please try again.');
    }
  };

  onTemplatesDataAction = (response) => {
    console.log('onTemplatesDataAction: ');

    if (response) {
      this.setState({
        templatesArray: CareAccountStore.getAvailableTemplates(),
      });
    } else {
      Message.show('Sorry, There was a problem getting the templates information. Please try again.');
    }
  };

  handleDeleteAccount = () => {
    //@ts-ignore
    window.$('#modalDeleteAccount').modal('open');
    this.setState({
      deleteAccountError: '',
      btnDeleteAccountMode: 'nucleus-submit-btn-small',
    });
  };

  handleCloseDeleteAccountModal = () => {
    //@ts-ignore
    window.$('#modalDeleteAccount').modal('close');
  };

  handleSubmitDeleteAccount = () => {
    console.log('handleSubmitDeleteAccount');
    // console.log("VALIDATE");

    this.setState({
      btnDeleteAccountMode: 'nucleus-submit-btn-small-disabled',
    });
    CareAccountStore.DeleteAccount({
      AccountID: this.state.accountID,
    });
    //onAccountDeleted
  };

  onAccountDeletedAction = (response) => {
    console.log('onAccountDeletedAction', response);

    this.setState({
      btnDeleteAccountMode: 'nucleus-submit-btn-small',
    });

    if (response.ok) {
      Message.show('Account Deleted!');
      //@ts-ignore
      window.$('#modalDeleteAccount').modal('close');

      CareAccountStore.getAllAccountsData({
        UserID: AuthStore.getCsUserID(),
        Token: AuthStore.getCsUserToken(),
      });

      setTimeout(function () {
        window.location.assign('#/accounts');
      }, 500);
    } else {
      if (response.ruleViolation) {
        this.setState({
          deleteAccountError: response.ruleViolation,
          btnDeleteAccountMode: 'nucleus-submit-btn-small-disabled',
        });
      } else {
        Message.show('Error Deleting Account');
      }
    }
  };

  resetMainTabsSelection = () => {
    this.setState({
      accountConfigSelectedTab: 'None',
    });
  };

  handleAccountSettingsTab = () => {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.AccountSettings,
    });
    // CareAccountStore.getAllAccountsData({
    //     UserID : AuthStore.getCsUserID(),
    //     Token : AuthStore.getCsUserToken()
    // });
    this.refreshAccountData();
  };

  handleAccountStringsTab = () => {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.AccountStrings,
    });
    this.refreshAccountData();
  };

  handleAccountFeaturesTab = () => {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.AccountFeatures,
    });
    this.refreshAccountData();
  };

  handleDeviceSettingsTab = () => {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.DeviceSettings,
    });
    CareAccountStore.getAccountBulletinButtons({
      AccountID: this.state.accountID,
    });
    this.refreshAccountData();
  };

  handleAlertsSettingsTab = () => {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.AlertsSettings,
    });
    this.refreshAccountData();
    //CareAccountStore.getUpdateGroupsData();
    //CareAccountStore.getAccountLocaleData();
    CareAccountStore.getAccountAlertsData({
      AccountID: this.state.accountID,
    });
  };

  handleExternalSettingsTab = () => {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.ExternalIntegrations,
    });
    this.refreshAccountData();
  };

  handleHipaaComplianceSettingsTab() {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.HipaaCompliance,
    });
    this.refreshAccountData();
  }

  handleHexnodeSettingsTab() {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.Hexnode,
    });
    this.refreshAccountData();
  }

  handleSalesforceSettingsTab() {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.Salesforce,
    });
    this.refreshAccountData();
  }

  handleClassroomSettingsTab() {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.ClassSession,
    });
  }

  handleAppsSettingsTab() {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.Apps,
    });
  }

  handleLinkSettingsTab() {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.LinkButton,
    });
  }

  handleSchedulingSettingsTab = () => {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.SchedulingTemplate,
    });
  };

  handleNewThemeSettingsTab = () => {
    this.resetMainTabsSelection();
    this.setState({
      accountConfigSelectedTab: AccountConfigTab.NewThemeSettings,
    });
  };

  handleChangeUpdateGroup() {
    console.log('Original Group ', this.state.accountUpdateGroupID);
    console.log('New Group ', this.selectUpdateGroup.current.value);

    CareAccountStore.updateUpdatesGroup({
      AccountID: this.state.accountID,
      UpdateGroupID: this.selectUpdateGroup.current.value,
    });
  }

  onUpdatesGroupUpdatedAction(response) {
    console.log('onUpdatesGroupUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Account Update Group updated!');
    } else {
      Message.show('Error updating account update group');
    }
  }

  handleShowCallButton(event) {
    console.log('This has changed ', event.target.checked);

    CareAccountStore.updateShowCallButton({
      AccountID: this.state.accountID,
      Show: event.target.checked,
    });
  }
  onShowCallButtonUpdatedAction(response) {
    console.log('onShowCallButtonUpdatedAction');
    console.log(response);

    if (response.ok) {
      this.setState({
        btnUpdateCallButtonTextStyle: response.show ? '' : 'disabled',
      });
      Message.show('Show Call button set to: ' + response.show);
    } else {
      Message.show('Error updating Show Call Button state');
    }
  }

  handleShowCallButton2(event) {
    console.log('this has changed ', event.target.checked);

    CareAccountStore.updateShowCallButton2({
      AccountID: this.state.accountID,
      Show: event.target.checked,
    });
    // Listen to: "onShowCallButton2Updated"
  }
  onShowCallButton2UpdatedAction(response) {
    console.log('onShowCallButton2UpdatedAction');
    console.log(response);

    if (response.ok) {
      this.setState({
        btnUpdateCallButtonText2Style: response.show ? '' : 'disabled',
      });

      Message.show('Show Call Button 2 set to: ' + response.show);
    } else {
      Message.show('Error updating Show Call Button 2 state');
    }
  }

  handleShowCheckInButton(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateShowCheckInButton({
      AccountID: this.state.accountID,
      Show: e.target.checked,
    });
  }
  onShowCheckInButtonUpdatedAction(response) {
    console.log('onShowCheckInButtonUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Show Check-In button set to: ' + response.show);
    } else {
      Message.show('Error updating Show Check-In button state');
    }
  }

  handleShowEmergencyButton(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateShowEmergencyButton({
      AccountID: this.state.accountID,
      Show: e.target.checked,
    });
  }
  onShowEmergencyButtonUpdatedAction(response) {
    console.log('onShowEmergencyButtonUpdatedAction');
    console.log(response);

    if (response.ok) {
      this.setState({
        btnUpdateEmergencyButtonTextStyle: response.show ? '' : 'disabled',
      });

      Message.show('Show Emergency button set to: ' + response.show);
    } else {
      Message.show('Error updating Show Emergency button state');
    }
  }

  handleRingOnRequest(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateRingOnRequest({
      AccountID: this.state.accountID,
      ring: e.target.checked,
    });
  }
  onRingOnRequestUpdatedAction(response) {
    console.log('onRingOnRequestUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Ring on Request set to: ' + response.ring);
    } else {
      Message.show('Error updating Ring on Request state');
    }
  }

  handleEnableBluetoothButtons(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateEnableBluetoothButtons({
      AccountID: this.state.accountID,
      EnableBluetoothButtons: e.target.checked,
    });
    //onEnableBluetoothButtonsUpdated
  }

  onEnableBluetoothButtonsUpdatedAction(response) {
    console.log('onEnableBluetoothButtonsUpdatedAction ', response);

    if (response.ok) {
      let responseResult = response.enableBluetoothButtons ? ' enabled' : ' disabled';
      Message.show('Bluetooth Buttons: ' + responseResult);
    } else {
      Message.show('Error updating Bluetooth Buttons state');
    }
  }

  handleShowCallControls(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateShowCallControls({
      AccountID: this.state.accountID,
      ShowCallButtonsBar: e.target.checked,
    });
    //onShowCallControlsUpdated
  }
  onShowCallControlsUpdatedAction(response) {
    console.log('onShowCallControlsUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Show Call Buttons Bar set to: ' + response.show);
    } else {
      Message.show('Error updating Show Call Buttons Bar state');
    }
  }

  handleShowStats(e) {
    console.log('this has changed ', e.target.checked);
    CareAccountStore.updateShowVideoStats({
      AccountID: this.state.accountID,
      ShowVideoStats: e.target.checked,
    });
    // onShowVideoStatsUpdated
  }
  onShowVideoStatsUpdatedAction(response) {
    console.log('onShowVideoStatsUpdatedAction');
    console.log(response);

    if (response.ok) {
      let responseResult = response.show ? ' Enabled.' : ' Disabled.';
      Message.show('Show Call Stats ' + responseResult);
    } else {
      Message.show('Error updating Show Call Stats state');
    }
  }

  handleAutoAnswer(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateEnableAutoAnswer({
      AccountID: this.state.accountID,
      EnableAutoAnswer: e.target.checked,
    });
    //onEnableAutoAnswerUpdated
  }
  onEnableAutoAnswerUpdatedAction(response) {
    console.log('onEnableAutoAnswerUpdatedAction');
    console.log(response);

    if (response.ok) {
      let responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Auto Answer ' + responseResult);
    } else {
      Message.show('Error updating Auto Answer');
    }
  }

  handleForceLandscapeVideo(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateForceLandscapeVideo({
      AccountID: this.state.accountID,
      ForceLandscapeVideo: e.target.checked,
    });
    //onForceLandscapeVideoUpdated
  }
  onForceLandscapeVideoUpdatedAction(response) {
    console.log('onForceLandscapeVideoUpdatedAction', response);

    if (response.ok) {
      let responseResult = response.force ? ' Enabled.' : ' Disabled.';
      Message.show('Force Landscape Video ' + responseResult);
    } else {
      Message.show('Error updating Force Landscape Video');
    }
  }

  handleEmergencySchedule(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateEmergencySchedule({
      AccountID: this.state.accountID,
      Enabled: e.target.checked,
    });
    //onEnableEmergencyScheduleUpdated
  }
  onEnableEmergencyScheduleUpdatedAction(response) {
    console.log('onEnableEmergencyScheduleUpdatedAction', response);

    if (response.ok) {
      let responseResult = response.enabled ? ' enabled' : ' disabled';
      let scheduleEnabled = response.enabled || this.state.accountCallSchedule;
      console.log('SCHEDULE ENABLED ', scheduleEnabled);
      this.setState({
        accountEmergencySchedule: response.enabled,
        btnOffHoursMessageStyle: scheduleEnabled ? '' : 'disabled',
      });
      Message.show('Emergency Schedule ' + responseResult);
    } else {
      Message.show('Error updating Emergency Schedule');
    }
  }

  handleCallSchedule(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateCallSchedule({
      AccountID: this.state.accountID,
      Enabled: e.target.checked,
    });
    //onEnableCallScheduleUpdated
  }
  onEnableCallScheduleUpdatedAction(response) {
    console.log('onEnableCallScheduleUpdatedAction', response);

    if (response.ok) {
      let responseResult = response.enabled ? ' enabled' : ' disabled';
      let scheduleEnabled = response.enabled || this.state.accountEmergencySchedule;
      console.log('SCHEDULE ENABLED ', scheduleEnabled);
      this.setState({
        accountCallSchedule: response.enabled,
        btnOffHoursMessageStyle: scheduleEnabled ? '' : 'disabled',
      });
      Message.show('Call Button Schedule ' + responseResult);
    } else {
      Message.show('Error updating Call Button Schedule');
    }
  }

  handleAudioCallsOnly(e) {
    console.log('handleAudioCallsOnly', e.target.checked);

    CareAccountStore.updateAudioCallsOnly({
      AccountID: this.state.accountID,
      Enabled: e.target.checked,
    });
  }
  onHandleAudioCallsOnlyAction(response) {
    console.log('onHandleAudioCallsOnlyAction', response);
    if (response.ok) {
      let responseLabel = response.enabled ? ' enabled' : ' disabled';
      this.setState({
        accountAudioCallsOnly: response.enabled,
      });
      Message.show('Audio calls only ' + responseLabel);
    } else {
      Message.show('Error updating Audio calls Only');
    }
  }

  handleIntercomButton = async (e) => {
    try {
      const payload = { IntercomButtonEnabled: e.target.checked };
      const response: IntercomResponse = await httpApi.put('account/' + this.state.accountID + '/update_account_config', payload);
      if (response?.data?.ok) {
        this.setState({ intercomButtonEnabled: e.target.checked });
        Message.show('Intercom Button ' + (e.target.checked ? 'enabled' : 'disabled'));
      }
    } catch (error) {
      Message.show('Error updating Intercom Button');
    }
  };

  async handleAccountThemeVersion(e) {
    const selectedVersion = e.target.value;
    const accountThemeVersionUpdated = await CareAccountStore.updateAccountDeviceUXVersion(this.state.accountID, selectedVersion);
    if (accountThemeVersionUpdated) {
      this.setState({
        deviceUxVersion: selectedVersion,
      });
      Message.show('Device UX version ' + selectedVersion + ' selected');
    } else {
      Message.show('Error saving the Device UX version');
    }
  }

  handlePowerDisconnectedWarningEnabled = async (newValue) => {
    try {
      const payload = { PowerDisconnectedWarningEnabled: newValue};
      const response = await httpApi.put('account/' + this.state.accountID + '/update_account_config', payload);
      if (response?.status === 200) {
        this.setState({ powerDisconnectedWarningEnabled: newValue });
        Message.show('Power disconnected warning ' + (newValue ? 'enabled' : 'disabled'));
      }
    } catch (error) {
      Message.show('Error updating Power disconnected warning');
    }
  }

  handleStatsTab() {
    GeneralStore.setClientTab(1);
    this.setState({
      devicesContent: 'hide',
      usersContent: 'hide',
      statsContent: '',
      clientsContent: 'hide',

      devicesTab: '',
      usersTab: '',
      clientsTab: '',
      buttonsTab: '',
      bioDevicesTab: '',
    });

    CareAccountStore.getDailyCalls({
      AccountID: this.state.accountID, //'31DF0A14-25EF-4654-B7AE-D188B1C4BD8B'
      // callback onGetDailyCallsAction
    });

    CareAccountStore.getDailyHandledCalls({
      AccountID: this.state.accountID, //'31DF0A14-25EF-4654-B7AE-D188B1C4BD8B'
    });

    CareAccountStore.getFamilyMembers({
      AccountID: this.state.accountID,
    });

    CareAccountStore.getMessagesReport({
      AccountID: this.state.accountID,
    });

    CareAccountStore.getFamilyCalls({
      AccountID: this.state.accountID,
    });

    CareAccountStore.getMediaItemsPerDayReport({
      AccountID: this.state.accountID,
    });

    //Message.show("Accounts stats reloading.");
  }

  handleDevicesTab = () => {
    GeneralStore.setClientTab(2);
    this.setState({
      devicesContent: '',
      usersContent: 'hide',
      statsContent: 'hide',
      clientsContent: 'hide',

      usersTab: '',
      statsTab: '',
      clientsTab: '',
      buttonsTab: '',
      bioDevicesTab: '',
      scheduleTab: '',

      loading: true,
    });
    this.devicesInfoReloaded = 0;

    CareAccountStore.getAccountDevices({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
      AccountID: this.state.accountID,
    });
  };

  handleUsersTab() {
    GeneralStore.setClientTab(3);
    this.setState({
      devicesContent: 'hide',
      statsContent: 'hide',
      usersContent: '',
      clientsContent: 'hide',

      devicesTab: '',
      statsTab: '',
      clientsTab: '',
      buttonsTab: '',
      bioDevicesTab: '',
      scheduleTab: '',

      loading: true,
    });

    this.usersInfoReloaded = 0;
    CareAccountStore.getAccountUsers({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
      AccountID: this.state.accountID,
    });
  }

  handleClientsTab() {
    GeneralStore.setClientTab(4);
    this.setState({
      devicesContent: 'hide',
      statsContent: 'hide',
      usersContent: 'hide',
      clientsContent: '',

      devicesTab: '',
      statsTab: '',
      usersTab: '',
      buttonsTab: '',
      bioDevicesTab: '',
      scheduleTab: '',

      loading: true,
    });
    this.clientsInfoReloaded = 0;
    CareAccountStore.getAccountPatientsStats({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
      AccountID: this.state.accountID,
    });
  }

  handlePatientsTab() {
    GeneralStore.setClientTab(8);
    this.setState({
      devicesContent: 'hide',
      usersContent: 'hide',
      statsContent: 'hide',
      clientsContent: 'hide',

      usersTab: '',
      statsTab: '',
      clientsTab: '',
      buttonsTab: '',
      bioDevicesTab: '',
      scheduleTab: '',
    });

    // this.setState({
    // 	loading:true
    // });
  }

  handlePatientsTimeZones() {
    GeneralStore.setClientTab(9);
    this.setState({
      devicesContent: 'hide',
      usersContent: 'hide',
      statsContent: 'hide',
      clientsContent: 'hide',

      usersTab: '',
      statsTab: '',
      clientsTab: '',
      buttonsTab: '',
      bioDevicesTab: '',
      scheduleTab: '',
    });

    // this.setState({
    // 	loading:true
    // });
  }

  handleExternalAppsSubTab() {
    GeneralStore.setClientTab(10);
    this.setState({
      devicesContent: 'hide',
      usersContent: 'hide',
      statsContent: 'hide',
      clientsContent: 'hide',

      usersTab: '',
      statsTab: '',
      clientsTab: '',
      buttonsTab: '',
      bioDevicesTab: '',
      scheduleTab: '',
    });
  }

  // DEPRECATED - Fri Dec 6th 2019
  // https://nucleusintercom.atlassian.net/browse/CARE-6239

  handleButtonsTab() {
    GeneralStore.setClientTab(5);
    this.setState({
      devicesContent: 'hide',
      statsContent: 'hide',
      usersContent: 'hide',
      clientsContent: '',

      devicesTab: '',
      statsTab: '',
      usersTab: '',
      clientsTab: '',
      bioDevicesTab: '',
      scheduleTab: '',
    });

    // DEPRECATED - Fri Dec 6th 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6239
    // Message.show("DEPRECATED BLE Buttons 1");
    // this.buttonsInfoReloaded = 0;
    // CareAccountStore.getAccountButtons({
    //     AccountID : this.state.accountID
    // });
  }

  handleBioDevicesTab() {
    GeneralStore.setClientTab(6);

    this.setState({
      devicesContent: 'hide',
      statsContent: 'hide',
      usersContent: 'hide',
      clientsContent: '',

      devicesTab: '',
      statsTab: '',
      usersTab: '',
      clientsTab: '',
      buttonsTab: '',
      scheduleTab: '',
    });

    CareAccountStore.getAccountBiometricDevices({
      AccountID: this.state.accountID,
    });
    //Message.show("Biometric Devices reloaded")
  }

  handleScheduleTab() {
    GeneralStore.setClientTab(7);

    this.setState({
      devicesContent: 'hide',
      statsContent: 'hide',
      usersContent: 'hide',
      clientsContent: '',

      devicesTab: '',
      statsTab: '',
      usersTab: '',
      clientsTab: '',
      buttonsTab: '',
      bioDevicesTab: '',
    });
    CareAccountStore.getAccountSchedule({
      UserID: AuthStore.getCsUserID(),
      Token: AuthStore.getCsUserToken(),
      AccountID: this.state.accountID,
    });
  }

  onGetAccountDevicesAction(response) {
    console.log('onGetAccountDevicesAction');
    console.log(response);

    this.setState({
      loading: false,
    });

    if (response && response.ok) {
      this.setState({
        accountDevicesArray: [],
        accountDevices: 0,
      });

      this.setState({
        accountDevicesArray: response.deviceList,
        accountDevices: response.deviceList.length,
      });
      this.devicesInfoReloaded = this.devicesInfoReloaded + 1;
      if (this.devicesInfoReloaded == 1) {
        //Message.show("Devices information reloaded.");
      }
      if (this.state.missingBiometricDevicesInfo) {
        this.refreshBiometricDevicesData();
      }
    } else {
      Message.show('Error getting account devices, please try again.');
    }
  }

  onDeleteDeviceModalAction(deviceInfo) {
    console.log('onDeleteDeviceModalAction');
    console.log(deviceInfo);

    this.setState({
      selectedDeviceID: deviceInfo.DeviceID,
      selectedDeviceMac: deviceInfo.DeviceMac,
      selectedDeviceName: deviceInfo.DeviceName || deviceInfo.PreAssignedName,
      selectedDeviceAssigned: deviceInfo.DeviceAssigned,
      selectedDeviceOnHexnode: deviceInfo.OnHexnode,
      selectedDeviceOnNucleusMDM: deviceInfo.OnNucleusMDM,
      selectedDeviceMdmDeviceID: deviceInfo.MdmDeviceID,
      selectedDeviceUnassign: false,
      selectedDeviceDisenrollHexnode: false,
      selectedDeviceRemoveFromHexnode: false,
      selectedDeviceRemoveFromSystem: false,
      selectedDeviceRemoveFromMDM: false,
      deleteDeviceBtnStyle: 'nucleus-submit-btn',
    });
    //@ts-ignore
    window.$('#modalDeleteAccountDevice').modal('open');
  }

  handleCancelDeleteDevice() {
    //@ts-ignore
    window.$('#modalDeleteAccountDevice').modal('close');
  }

  handleDeleteDevice() {
    if (
      this.state.selectedDeviceUnassign ||
      this.state.selectedDeviceDisenrollHexnode ||
      this.state.selectedDeviceRemoveFromHexnode ||
      this.state.selectedDeviceRemoveFromSystem ||
      this.state.selectedDeviceRemoveFromMDM
    ) {
      this.setState({
        deleteDeviceBtnStyle: 'nucleus-submit-btn-disabled',
      });
      CareAccountStore.removeDevice({
        AccountID: this.state.accountID,
        CsUserID: AuthStore.getCsUserID(),
        DeviceID: this.state.selectedDeviceID,
        Unassign: this.state.selectedDeviceUnassign && this.state.selectedDeviceAssigned,
        DisenrollHexnode: this.state.selectedDeviceDisenrollHexnode,
        RemoveFromHexnode: this.state.selectedDeviceRemoveFromHexnode,
        RemoveFromSystem: this.state.selectedDeviceRemoveFromSystem,
        RemoveFromNucleusMdm: this.state.selectedDeviceRemoveFromMDM,
        MdmDeviceID: this.state.selectedDeviceMdmDeviceID,
      });
      const newDevicesToMove = this.state.selectedDevicesToMoveArray.filter((device) => device.ID !== this.state.selectedDeviceID);
      this.setState({ selectedDevicesToMoveArray: newDevicesToMove });
    } else {
      Message.show('Please check at least one option');
    }
  }
  onDeviceFromAccountRemovedAction(response) {
    console.log('onDeviceFromAccountRemovedAction');
    console.log(response);
    //@ts-ignore
    window.$('#modalDeleteAccountDevice').modal('close');
    if (response.ok) {
      this.devicesInfoReloaded = -1;
      CareAccountStore.getAccountDevices({
        UserID: AuthStore.getCsUserID(),
        Token: AuthStore.getCsUserToken(),
        AccountID: this.state.accountID,
      });
      this.setState({
        loading: true,
      });
      this.refreshAccountData();
      this.setState({
        accountDevicesArray: [],
        deleteDeviceBtnStyle: 'nucleus-submit-btn',
      });
      if (response.RemoveFromNucleusMDM) {
        Message.show('Device removed from the Nucleus MDM');
      }
      if (response.RemoveFromSystem) {
        Message.show('Device removed from the system');
      }
      if (response.DisenrollHexnode) {
        Message.show('Device removed from Hexnode');
      }
      if (response.Unassign) {
        Message.show('Device unassigned');
      }
    } else {
      Message.show('Error deleting the device, please try again.');
    }
  }

  handleNewDevice() {
    //@ts-ignore
    window.$('#modalNewAccountDevice').modal('open');
    this.setState({
      deviceMacAddressError: ' hide',
      deviceMacAddressText: ' Please enter a valid mac address',
      deviceAddBtnStyle: '',
    });
    this.refs.txtNewAccountDeviceMac.value = '';
  }

  handleMoveDevices() {
    //@ts-ignore
    window.$('#modalMoveDevices').modal('open');
    // this.setState({
    // });
  }

  handleCloseNewDeviceModal() {
    //@ts-ignore
    window.$('#modalNewAccountDevice').modal('close');
    this.refs.txtNewAccountDeviceMac.value = '';
  }
  handleClosehandleMoveDevicesModal() {
    //@ts-ignore
    window.$('#modalMoveDevices').modal('close');
  }

  validateMacAddressText() {
    if (this.state.deviceMacAddressError != ' hide') {
      this.setState({
        deviceMacAddressError: ' hide',
        deviceMacAddressText: ' Please enter a valid mac address',
      });
    }
  }
  handleSubmitNewDevice() {
    if (this.refs.txtNewAccountDeviceMac.value == '') {
      this.setState({
        deviceMacAddressError: ' ',
        deviceMacAddressText: ' Please enter the mac address',
      });
      return;
    }

    this.setState({
      deviceMacAddressError: ' hide',
      deviceMacAddressText: ' Please enter a valid mac address',
      deviceAddBtnStyle: '-disabled',
    });

    CareAccountStore.addDevice({
      AccountID: this.state.accountID,
      Mac: this.refs.txtNewAccountDeviceMac.value,
      cleanLegacy: false,
    });
  }

  handleSubmitMoveDevices() {
    let { selectedDevicesToMove, selectedAccountToMoveID } = this.state;
    let devicesIDs = [];
    devicesIDs = Object.keys(selectedDevicesToMove);
    this.setState({
      movingDevices: false,
      movingDevicesStatus: 'loading',
      movingDevicesStatusMessage: '',
    });
    if (!selectedAccountToMoveID || selectedAccountToMoveID == '') {
      this.setState({
        movingDevices: false,
        movingDevicesStatus: 'error',
        movingDevicesStatusMessage: 'Please, select an account to move',
      });
      Message.show('Please, select an account to move the devices');
      return;
    }
    if (devicesIDs.length <= 0) {
      this.setState({
        movingDevices: false,
        movingDevicesStatus: 'error',
        movingDevicesStatusMessage: 'Please, select device(s) to move',
      });
      Message.show('Please, select device(s) to move');
      return;
    }
    let dataToSend = {
      accountId: selectedAccountToMoveID,
      devices: devicesIDs,
    };
    console.log('Aqui vamos!', dataToSend);

    this.setState({
      movingDevicesStatus: 'loading',
    });
    httpApi
      .post('/device/move_account', dataToSend)
      .then((res) => {
        Message.show('Device/s moved');
        this.setState({
          selectedDevicesToMove: {},
          selectedDevicesToMoveArray: [],
          movingDevicesStatus: '',
        });
        this.setState({
          movingDevices: false,
          movingDevicesStatus: 'success',
          movingDevicesStatusMessage: 'Success',
        });
        this.devicesInfoReloaded = -1;
        CareAccountStore.getAccountDevices({
          UserID: AuthStore.getCsUserID(),
          Token: AuthStore.getCsUserToken(),
          AccountID: this.state.accountID,
        });
        this.setState({
          loading: true,
          accountDevicesArray: [],
        });
        this.refreshAccountData();
        this.handleClosehandleMoveDevicesModal();
      })
      .catch((err) => {
        this.setState({
          selectedDevicesToMove: {},
          selectedDevicesToMoveArray: [],
          movingDevicesStatus: '',
        });
        this.setState({
          movingDevices: false,
          movingDevicesStatus: 'error',
          movingDevicesStatusMessage: 'We have not been able to complete your request',
        });
        Message.show('We have not been able to complete your request');
      });
  }

  onDeviceAddedAction(response) {
    console.log('onDeviceAddedAction', response);

    this.setState({
      deviceAddBtnStyle: '',
    });

    if (response.ok) {
      //@ts-ignore
      window.$('#modalNewAccountDevice').modal('close');

      if (response.MacsAdded.length > 0) {
        this.devicesInfoReloaded = -1;
        CareAccountStore.getAccountDevices({
          UserID: AuthStore.getCsUserID(),
          Token: AuthStore.getCsUserToken(),
          AccountID: this.state.accountID,
        });
        this.setState({
          loading: true,
          accountDevicesArray: [],
        });
        this.refreshAccountData();
      } else {
        //Message.show("No Device added!");
      }

      if (response.Macs.length == response.MacsAdded.length) {
        Message.show('Device/s added to the Account');
      } else {
        Message.show(response.MacsAdded.length + ' Device/s added to the Account');
        Message.show(response.MacsExisting.length + ' Device/s are already registered.');
      }

      if (response.MacsInLegacy.length > 0) {
        let macInLegacyString = '';
        for (var i = 0; i < response.MacsInLegacy.length; i++) {
          console.log('Agregando la mac ' + response.MacsInLegacy[i].Mac);
          if (i > 0) {
            macInLegacyString = macInLegacyString + ', ';
          }
          macInLegacyString = macInLegacyString + response.MacsInLegacy[i].Mac;
        }

        this.setState({
          devicesToAddInLegacy: macInLegacyString,
          deviceLegacyAddBtnStyle: '',
        });
        //@ts-ignore
        window.$('#modalNewAccountLegacyDevice').modal('open');
      }
    } else {
      this.setState({
        deviceMacAddressError: ' ',
        deviceMacAddressText: 'Error adding Mac address',
      });
    }
  }

  handleCloseLegacyDeviceModal() {
    window.$('#modalNewAccountLegacyDevice').modal('close');
  }

  handleSubmitLegacyDevice() {
    this.setState({
      deviceLegacyAddBtnStyle: '-disabled',
    });

    let legacyDevicesToAdd = this.state.devicesToAddInLegacy.replace(/,/g, '');
    console.log('Legacy Devices ', legacyDevicesToAdd);

    CareAccountStore.addDeviceFromLegacy({
      AccountID: this.state.accountID,
      Mac: legacyDevicesToAdd,
      cleanLegacy: true,
    });
  }

  onDeviceFromLegacyAddedAction(response) {
    console.log('onDeviceAddedAction', response);

    this.setState({
      deviceLegacyAddBtnStyle: '',
    });

    if (response.ok) {
      //@ts-ignore
      window.$('#modalNewAccountLegacyDevice').modal('close');

      if (response.MacsAdded.length > 0) {
        this.devicesInfoReloaded = -1;
        CareAccountStore.getAccountDevices({
          UserID: AuthStore.getCsUserID(),
          Token: AuthStore.getCsUserToken(),
          AccountID: this.state.accountID,
        });
        this.setState({
          loading: true,
          accountDevicesArray: [],
        });
        this.refreshAccountData();
      } else {
        //Message.show("No Device added!");
      }

      if (response.Macs.length == response.MacsAdded.length) {
        Message.show('Device/s added to the Account');
      } else {
        Message.show(response.MacsAdded.length + ' Device/s added to the Account');
        Message.show(response.MacsExisting.length + ' Device/s are already registered.');
      }
    } else {
      this.setState({
        deviceMacAddressError: ' ',
        deviceMacAddressText: 'Error adding Mac address',
      });
    }
  }

  onRebootDeviceRequestedAction(response) {
    console.log('onRebootDeviceRequestedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Reboot device requested, the device will restart soon.');

      // setTimeout(function(){
      //     this.handleRefresh();
      // }, 30000);
    } else {
      Message.show('There was a error requesting the device reboot, please refresh.');
    }
  }

  onDownloadDeviceLogsModalAction(deviceInfo) {
    console.log('onDownloadDeviceLogsModalAction');
    console.log(deviceInfo);

    this.setState({
      selectedDeviceMac: deviceInfo.DeviceMac,
    });
    //@ts-ignore
    window.$('#modalDownloadDeviceLogs').modal('open');
  }
  handleCancelDownloadDeviceLogs() {
    //@ts-ignore
    window.$('#modalDownloadDeviceLogs').modal('close');
  }
  handleDownloadDeviceLogs() {
    let macString = this.state.selectedDeviceMac.replace(/:/g, '');
    var url = 'https://analytics.nucleuslife.io/upload/' + macString + '.txt';
    //@ts-ignore
    window.$('#modalDownloadDeviceLogs').modal('close');
    window.open(url, '_blank');
  }

  // Unblock API button
  onUnblockApiButtonModalAction() {
    console.log('onUnblockApiButtonModalAction');
    //@ts-ignore
    window.$('#modalUnblockApi').modal('open');
  }

  handleCloseUnblockApi() {
    //@ts-ignore
    window.$('#modalUnblockApi').modal('close');
  }

  handleSubmitUnblockApi() {
    CareAccountStore.unblockApi({
      AccountID: this.state.accountID,
    });
  }

  onUnblockApiUpdatedAction(response) {
    console.log('onUnblockApiUpdatedAction');
    console.log(response);

    if (response.ok) {
      //@ts-ignore
      window.$('#modalUnblockApi').modal('close');
      this.setState({
        accountApiUsageLimit: response.APIUsageConsecutiveLimitViolation,
      });
      console.log('RESPONSE Unblock API: ', response.APIUsageConsecutiveLimitViolation);
      Message.show('API Unblocked');
    } else {
      Message.show('Error unblocking API');
    }
  }

  // Generate API token button
  onGenerateApiTokenButtonModalAction() {
    console.log('onGenerateApiTokenButtonModalAction');
    //@ts-ignore
    window.$('#modalGenerateApiToken').modal('open');
  }

  handleCloseGenerateApiTokenModal() {
    //@ts-ignore
    window.$('#modalGenerateApiToken').modal('close');
  }

  handleSubmitGenerateApiToken() {
    CareAccountStore.updateApiToken({
      AccountID: this.state.accountID,
    });
  }

  onApiTokenUpdatedAction(response) {
    console.log('onApiTokenUpdatedAction');
    console.log(response);

    if (response.ok) {
      //@ts-ignore
      window.$('#modalGenerateApiToken').modal('close');
      this.setState({
        accountApiToken: response.ApiToken,
      });
      console.log('RESPONSE API TOKEN: ', response.ApiToken);
      Message.show('API token updated');
    } else {
      Message.show('Error updating API token');
    }
  }

  copyApiTokenToClipboard() {
    var copyText = document.getElementById('api-token-label');
    var textArea = document.createElement('textarea');

    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    Message.show('API token copied to clipboard');
  }

  handleIsHipaaCompliant() {
    console.log('handleIsHipaaCompliant');

    this.setState({
      autoLogoutTimeMode: !!this.refs.checkIsHipaaCompliant.checked,
    });

    CareAccountStore.updateIsHipaaCompliant({
      AccountID: this.state.accountID,
      IsHipaaCompliant: !!this.refs.checkIsHipaaCompliant.checked,
    });
  }

  onHipaaCompliantUpdatedAction(response) {
    console.log('onHipaaCompliantUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('HIPAA Compliant updated');
    } else {
      Message.show('Error updating HIPAA Compliant');
    }
  }

  handleUpdateAutoLogout() {
    console.log('handleUpdateAutoLogout');
    if (this.refs.txtAutoLogoutTimeout.value == '') {
      Message.show('Enter the Auto Logout Timeout (in minutes) please.');
      this.refs.txtAutoLogoutTimeout.focus();
      return;
    }

    CareAccountStore.updateAutoLogoutTimeout({
      AccountID: this.state.accountID,
      AutoLogoutTimeout: this.refs.txtAutoLogoutTimeout.value,
    });
  }

  onAutoLogoutUpdatedAction(response) {
    console.log('onAutoLogoutUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Auto Logout updated');
    } else {
      Message.show('Error updating Auto Logout');
    }
  }

  // End Buttons

  //Start Biometric Devices
  //- - - - - - - - - - - -
  onGetAccountBiometricDevicesAction(response) {
    console.log('onGetAccountBiometricDevicesAction');
    console.log(response);

    //console.log("accountDevicesArray ", this.state.accountDevicesArray);

    this.setState({
      accountBiometricDevicesArray: [],
    });

    if (response.ok) {
      let biometricDevicesArrayTemp = [].concat(response.BiometricDevices);

      biometricDevicesArrayTemp = biometricDevicesArrayTemp.sort((a, b) => new Date(b.LastUsedTimeStamp) - new Date(a.LastUsedTimeStamp));

      this.setState({
        accountBiometricDevicesArray: biometricDevicesArrayTemp,
        accountBioDevices: response.BiometricDevices.length,
      });

      // this.buttonsInfoReloaded = this.buttonsInfoReloaded + 1;
      // if (this.buttonsInfoReloaded == 1){
      //     Message.show("Account buttons reloaded.");
      // }
    } else {
      Message.show('Error getting account biometric Devices, please try again.');
    }
  }

  refreshBiometricDevicesData() {
    let biometricDevicesArrayTemp = this.state.accountBiometricDevicesArray;

    this.setState({
      accountBiometricDevicesArray: biometricDevicesArrayTemp,
      accountBioDevices: biometricDevicesArrayTemp.length,
      missingBiometricDevicesInfo: false,
    });
  }

  refreshBiometricDevicesPatientData() {
    let biometricDevicesArrayTemp = this.state.accountBiometricDevicesArray;

    if (this.state.accountClientsArray.length > 0) {
      this.setState({
        accountBiometricDevicesArray: biometricDevicesArrayTemp,
        accountBioDevices: biometricDevicesArrayTemp.length,
        missingBiometricDevicesPatientInfo: false,
      });
    }
  }

  // End Biometric Devices

  onGetAccountUsersAction(response) {
    console.log('onGetAccountUsersAction');
    console.log(response);

    this.setState({
      loading: false,
    });
    if (response.ok) {
      this.setState({
        accountUsersArray: response.users,
        accountUsers: response.users.length,
      });

      this.usersInfoReloaded = this.usersInfoReloaded + 1;
      if (this.usersInfoReloaded == 1) {
        //Message.show("Users information reloaded.");
      }
    } else {
      Message.show('Error getting account users, please try again.');
    }
  }

  onGetAccountPatientsStatsAction(response) {
    console.log('onGetAccountPatientsStatsAction');
    console.log(response);

    this.setState({
      loading: false,
    });

    if (response && response.ok) {
      this.setState({
        accountClientsArray: response.Data,
        accountClients: response.Data.length,
        accountPatients: response.Data.length,
      });
      this.clientsInfoReloaded = this.clientsInfoReloaded + 1;
      if (this.clientsInfoReloaded == 1) {
        //Message.show("Clients information reloaded.");
      }

      if (this.state.missingBiometricDevicesPatientInfo) {
        this.refreshBiometricDevicesPatientData();
      }
    } else {
      Message.show('Error getting account patients, please try again.');
    }
  }

  onGetAccountButtonsAction(response) {
    console.log('onGetAccountButtonsAction');
    console.log(response);

    if (response.ok) {
      this.setState({
        accountButtonsArray: response.Buttons,
        accountButtons: response.Buttons.length,
      });

      this.buttonsInfoReloaded = this.buttonsInfoReloaded + 1;
      if (this.buttonsInfoReloaded == 1) {
        //Message.show("Account buttons reloaded.");
      }
    } else {
      Message.show('Error getting account buttons, please try again.');
    }
  }

  onGetAccountBulletinButtonsAction(response) {
    console.log('onGetAccountBulletinButtonsAction');
    console.log(response);

    if (response.ok) {
      this.setState({
        accountBulletinButtonsArray: response.BulletinButtons,
        accountBulletinButtons: response.AccountTemplateBulletinButtons,
      });

      let buttons = response.BulletinButtons;
      let accountButtonsCount = response.AccountTemplateBulletinButtons;

      // try {
      for (var i = 0; i < buttons.length; i++) {
        if (i == 0 && accountButtonsCount > 0) {
          this.checkBulletinButton1.current.checked = buttons[i].ShouldShow;
          this.txtButton1Title.current.value = buttons[i].Name;
        } else if (i == 1 && accountButtonsCount > 1) {
          console.log('Button 2');
          this.checkBulletinButton2.current.checked = buttons[i].ShouldShow;
          this.txtButton2Title.current.value = buttons[i].Name;
        } else if (i == 2 && accountButtonsCount > 2) {
          console.log('Button 3');
          this.checkBulletinButton3.current.checked = buttons[i].ShouldShow;
          this.txtButton3Title.current.value = buttons[i].Name;
        } else if (i == 3 && accountButtonsCount > 3) {
          console.log('Button 4');
          this.checkBulletinButton4.current.checked = buttons[i].ShouldShow;
          this.txtButton4Title.current.value = buttons[i].Name;
        }
      }
      // } catch (e) {
      //   console.log('Error ', e);
      //   setTimeout(() => {
      //     CareAccountStore.getAccountBulletinButtons({
      //       AccountID: this.state.accountID,
      //     });
      //   }, 2000);
      // }
    } else {
      Message.show('Error getting Account bulletin buttons, please try again.');
    }
  }

  handleChangeAccountTemplate() {
    //@ts-ignore
    window.$('#modalSelectTemplate').modal('open');
    this.setState({
      checkedTemplateId: this.state.accountTemplate,
    });
  }

  handleCancelSelectTemplate() {
    //@ts-ignore
    window.$('#modalSelectTemplate').modal('close');
  }

  handleSelectTemplate(templateId) {
    this.setState({
      checkedTemplateId: templateId,
    });
    CareAccountStore.updateAccountTemplate({
      AccountID: this.state.accountID,
      TemplateID: templateId,
    });
  }

  onAccountTemplateUpdatedAction(response) {
    console.log('onAccountTemplateUpdatedAction', response);

    if (response.ok) {
      Message.show(response.TemplateName + ' selected');

      this.setState({
        accountTemplate: response.TemplateID,
        accountTemplateName: response.TemplateName,
        accountTemplateImageUrl: response.TemplateExampleImageUrl,

        accountBulletinButtons: response.AccountTemplateBulletinButtons,
      });

      if (response.TemplateID == 10) {
        CareAccountStore.updateCallTimeoutDelay({
          AccountID: this.state.accountID,
          CallTimeoutDelayInSeconds: 300,
        });
      }

      CareAccountStore.getAccountBulletinButtons({
        AccountID: this.state.accountID,
      });
      //@ts-ignore
      window.$('#modalSelectTemplate').modal('close');
    } else {
      Message.show('Error updating Main Device Template');
    }
  }

  handleEnableBackgroundImage(e) {
    console.log('handleEnableBackgroundImage', e.target.checked);

    CareAccountStore.updateEnableBackgroundImage({
      AccountID: this.state.accountID,
      Enable: e.target.checked,
    });
    //onEnableBackgroundImageUpdated
  }

  onEnableBackgroundImageUpdatedAction(response) {
    if (response.ok) {
      let responseString = response.Enable ? ' enabled' : ' disabled';
      Message.show('Background Image' + responseString);
    } else {
      Message.show('Error, please try again');
    }
  }

  handleEnableBackgroundLogo(e) {
    console.log('handleEnableBackgroundLogo', e.target.checked);

    CareAccountStore.updateEnableBackgroundLogo({
      AccountID: this.state.accountID,
      Enable: e.target.checked,
    });
    //onEnableBackgroundLogoUpdated
  }

  onEnableBackgroundLogoUpdatedAction(response) {
    if (response.ok) {
      let responseString = response.Enable ? ' enabled' : ' disabled';
      Message.show('Background Logo Image' + responseString);
    } else {
      Message.show('Error, please try again');
    }
  }

  handleChangeBackgroundImage() {
    document.getElementById('selectFile1').click();
  }

  selectedBackgroundImageFile(e) {
    console.log('selectedBackgroundImageFile', e);

    let files = e.target.files;
    if (files.length > 0) {
      let selectedImage = files[0];
      console.log('selectedImage', selectedImage);

      this.setState({
        uploadingBgImageStatus: 'Uploading...',
        uploadingBgLogoStatus: '',
      });

      CareAccountStore.uploadBackgroundImage({
        AccountID: this.state.accountID,
        MediaType: selectedImage.type,
        File: selectedImage,
      });
      // onUploadBackgroundImageComplete
    } else {
      console.log('No File Selected');
    }
  }

  onUploadBackgroundImageCompleteAction(response) {
    console.log('onUploadBackgroundImageCompleteAction', response);

    if (response.ok) {
      Message.show('Background Image Updated');
      console.log('Response', response.ImageUrl);

      // this.setState({
      //         accountBackgroundImageUrl : ""
      //     });
      setTimeout(() => {
        this.setState({
          accountBackgroundImageUrl: response.ImageUrl,
          uploadingBgImageStatus: '',
          uploadingBgLogoStatus: '',
        });
      }, 1000);
    } else {
      Message.show('Error uploading Background Image, please try again');
      this.setState({
        uploadingBgImageStatus: '',
        uploadingBgLogoStatus: '',
      });
    }
  }

  handleChangeBackgroundLogo() {
    document.getElementById('selectFile2').click();
  }

  selectedBackgroundLogoImageFile(e) {
    console.log('selectedBackgroundLogoImageFile', e);

    let files = e.target.files;
    if (files.length > 0) {
      let selectedImage = files[0];
      console.log('selectedImage', selectedImage);

      this.setState({
        uploadingBgImageStatus: '',
        uploadingBgLogoStatus: 'Uploading...',
      });

      CareAccountStore.uploadBackgroundLogo({
        AccountID: this.state.accountID,
        MediaType: selectedImage.type,
        File: selectedImage,
      });
      // onUploadBackgroundLogoComplete
    } else {
      console.log('No File Selected');
    }
  }

  onUploadBackgroundLogoCompleteAction(response) {
    console.log('onUploadBackgroundLogoCompleteAction', response);

    if (response.ok) {
      Message.show('Background Logo Image Updated');
      console.log('Response', response.ImageUrl);

      // this.setState({
      //         accountBackgroundLogoUrl : ""
      //     });
      setTimeout(() => {
        this.setState({
          accountBackgroundLogoUrl: response.ImageUrl,
          uploadingBgImageStatus: '',
          uploadingBgLogoStatus: '',
        });
      }, 1000);
    } else {
      Message.show('Error uploading Background Logo Image, please try again');
      this.setState({
        uploadingBgImageStatus: '',
        uploadingBgLogoStatus: '',
      });
    }
  }

  handleEnableBulletinButton(e, f) {
    console.log('handleEnableBulletinButton', e, f.target.checked);

    let selectedBulletinButton = this.state.accountBulletinButtonsArray[e];

    console.log('Selected Button', selectedBulletinButton);

    CareAccountStore.updateEnableBulletinButton({
      ButtonID: selectedBulletinButton.ID,
      Enable: f.target.checked,
    });
  }

  onEnableBulletinButtonUpdatedAction(response) {
    if (response.ok) {
      let responseString = response.Enable ? ' enabled' : ' disabled';

      Message.show('Button ' + response.BtnIndex + responseString);
    } else {
      Message.show('Error, please try again');
    }
  }

  handleUpdateBulletinButtonName(e) {
    console.log('handleUpdateBulletinButtonName', e);
    console.log('this.state.accountBulletinButtonsArray', this.state.accountBulletinButtonsArray);

    let selectedBulletinButton = this.state.accountBulletinButtonsArray[e];
    let newName = '';

    switch (e) {
      case 0:
        newName = this.txtButton1Title.current.value;
        break;
      case 1:
        newName = this.txtButton2Title.current.value;
        break;
      case 2:
        newName = this.txtButton3Title.current.value;
        break;
      case 3:
        newName = this.refs.txtButton4Title.value;
        break;
    }

    console.log('Name', newName);
    if (newName == '') {
      Message.show('There is no name to save');
      return;
    }

    CareAccountStore.updateBulletinButtonName({
      ButtonID: selectedBulletinButton.ID,
      Name: newName,
    });
  }

  onBulletinButtonNameUpdatedAction(response) {
    if (response.ok) {
      Message.show('Button Name updated');
    } else {
      Message.show('Error, please try again');
    }
  }

  handleEnableMemoryBox(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateEnableMemoryBox({
      AccountID: this.state.accountID,
      EnableMemoryBox: e.target.checked,
    });
    // onEnableMemoryBoxUpdated
  }

  onEnableMemoryBoxUpdatedAction(response) {
    console.log('onEnableMemoryBoxUpdatedAction');
    console.log(response);

    if (response.ok) {
      let responseResult = response.EnableMemoryBox ? ' enabled' : ' disabled';

      Message.show('Memory box' + responseResult);
      this.setState({
        accountMemoryBoxStyle: response.EnableMemoryBox ? '' : 'disabled',
      });
    } else {
      Message.show('Error updating Memory Box');
    }
  }

  handleShowFamilyCallBtn(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateShowFamilyCallBtn({
      AccountID: this.state.accountID,
      ShowFamilyCallButton: e.target.checked,
    });
    //onShowFamilyCallButtonUpdated
  }
  onShowFamilyCallButtonUpdatedAction(response) {
    console.log('onShowFamilyCallButtonUpdatedAction');
    console.log(response);

    if (response.ok) {
      let responseResult = response.show ? ' enabled' : ' disabled';

      Message.show('Show Family Call Button ' + responseResult);
      this.setState({
        accountFamilyMemberStyle: response.ShowFamilyCallButton ? '' : 'disabled',
      });
    } else {
      Message.show('Error updating Show Family Call Button');
    }
  }

  handleUpdateScreenSaverDelay() {
    if (this.refs.txtScreenSaverDelay.value == '') {
      Message.show('Enter the Screen Saver delay (in seconds) please.');
      this.refs.txtScreenSaverDelay.focus();
      return;
    }

    CareAccountStore.updateScreenSaverDelay({
      AccountID: this.state.accountID,
      ScreenSaverDelayInSeconds: this.refs.txtScreenSaverDelay.value,
    });
    //onScreenSaverDelayUpdated
  }

  onScreenSaverDelayUpdatedAction(response) {
    console.log('onScreenSaverDelayUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Screen Saver Delay updated');
    } else {
      Message.show('Error updating Screen Saver Delay');
    }
  }

  onDimStartUpdatedAction(response) {
    console.log('onDimStartUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Dim start time updated');
    } else {
      Message.show('Error updating Dim start time');
    }
  }

  onDimEndUpdatedAction(response) {
    console.log('onDimEndUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Dim end time updated');
    } else {
      Message.show('Error updating Dim end time');
    }
  }

  handleChangeDimStartTime(e) {
    console.log('handleChangeDimStartTime', e.target.value);
    CareAccountStore.updateDimFrom({
      AccountID: this.state.accountID,
      DimFrom: e.target.value,
    });
  }

  handleChangeDimEndTime(e) {
    console.log('handleChangeDimEndTime', e.target.value);
    CareAccountStore.updateDimTo({
      AccountID: this.state.accountID,
      DimTo: e.target.value,
    });
  }

  handleUpdateDimOffBrightness() {
    console.log('handleUpdateDimOffBrightness');
    if (this.refs.txtDimOffBrightness.value == '') {
      Message.show('Enter a Dim Off value please.');
      this.refs.txtDimOffBrightness.focus();
      return;
    }

    if (this.refs.txtDimOffBrightness.value < 0 || this.refs.txtDimOffBrightness.value > 255) {
      Message.show('You should only enter a value between 0 and 255');
      return;
    }

    CareAccountStore.updateDimOffValue({
      AccountID: this.state.accountID,
      DimOffValue: this.refs.txtDimOffBrightness.value,
    });
  }

  onDimOffBrightnessUpdatedAction(response) {
    console.log('onDimOffBrightnessUpdateAction');
    console.log(response);
    if (response.ok) {
      Message.show('Dim Off value updated');
    } else {
      Message.show('Error updating Dim Off value');
    }
  }

  handleUpdateDimOnBrightness() {
    if (this.refs.txtDimOnBrightness.value == '') {
      Message.show('Enter a Dim On value please.');
      this.refs.txtDimOnBrightness.focus();
      return;
    }

    if (this.refs.txtDimOnBrightness.value < 0 || this.refs.txtDimOnBrightness.value > 255) {
      Message.show('You should only enter a value between 0 and 255');
      return;
    }

    CareAccountStore.updateDimOnValue({
      AccountID: this.state.accountID,
      DimOnValue: this.refs.txtDimOnBrightness.value,
    });
  }

  onDimOnBrightnessUpdatedAction(response) {
    console.log('onDimOnBrightnessUpdateAction');
    console.log(response);
    if (response.ok) {
      Message.show('Dim On value updated');
    } else {
      Message.show('Error updating Dim On value');
    }
  }

  handleUpdateOfflineTimeSpan() {
    if (this.refs.txtOfflineTimeSpan.value == '') {
      Message.show('Enter the Offline Time Span (in minutes) please.');
      this.refs.txtOfflineTimeSpan.focus();
      return;
    }

    CareAccountStore.updateOfflineTimeSpan({
      AccountID: this.state.accountID,
      Minutes: this.refs.txtOfflineTimeSpan.value,
    });
    //onOfflineTimeSpanUpdated
  }
  onOfflineTimeSpanUpdatedAction(response) {
    console.log('onOfflineTimeSpanUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Offline Time Span updated');
    } else {
      Message.show('Error updating Offline Time Span');
    }
  }

  //Settings PIN Code
  handleUpdateSettingsPin() {
    if (this.refs.txtSettingsPinCode.value.length != 4) {
      Message.show('Enter a valid Settings PIN Code please.');
      this.refs.txtSettingsPinCode.focus();
      return;
    }

    CareAccountStore.updateSettingsPinCode({
      AccountID: this.state.accountID,
      SettingsAccessPIN: this.refs.txtSettingsPinCode.value,
    });
    //onSettingsAccessPINUpdated
  }
  onSettingsAccessPINUpdatedAction(response) {
    console.log('onSettingsAccessPINUpdatedAction ', response);

    if (response.ok) {
      Message.show('Settings Access PIN updated');
    } else {
      Message.show('Error updating Settings Access PIN');
    }
  }

  handleUpdateInactiveDays() {
    console.log('handleUpdateInactiveDays');
    if (this.refs.txtInactiveDays.value == '') {
      Message.show('Enter a value for Inactive Days please.');
      this.refs.txtInactiveDays.focus();
      return;
    }

    if (parseInt(this.refs.txtInactiveDays.value) < 2 || parseInt(this.refs.txtInactiveDays.value > 365)) {
      Message.show('You should only enter a value between 2 and 365');
      return;
    }

    if (parseInt(this.refs.txtInactiveDays.value) <= parseInt(this.refs.txtInactiveDaysAlert.value)) {
      Message.show("You should enter a number greater than the 'Inactive Days in Hexnode Alert' value");
      return;
    }

    CareAccountStore.updateInactiveDays({
      AccountID: this.state.accountID,
      Days: this.refs.txtInactiveDays.value,
    });
    // onInactiveDaysUpdated
  }

  onInactiveDaysUpdatedAction(response) {
    console.log('onInactiveDaysUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Inactive Days before disenroll updated');
    } else {
      Message.show('Error updating Inactive Days value');
    }
  }

  handleUpdateInactiveDaysAlert() {
    console.log('handleUpdateInactiveDaysAlert');
    if (this.refs.txtInactiveDaysAlert.value == '') {
      Message.show('Enter a value for Inactive Days in Hexnode Alert please.');
      this.refs.txtInactiveDaysAlert.focus();
      return;
    }

    if (parseInt(this.refs.txtInactiveDaysAlert.value) < 1 || parseInt(this.refs.txtInactiveDaysAlert.value > 365)) {
      Message.show("You should enter a valid number between 1 and 'Inactive Days'");
      return;
    }

    if (parseInt(this.refs.txtInactiveDaysAlert.value) >= parseInt(this.refs.txtInactiveDays.value)) {
      Message.show("You should enter a number lower than the 'Inactive Days' value");
      return;
    }

    CareAccountStore.updateInactiveDaysAlert({
      AccountID: this.state.accountID,
      Days: this.refs.txtInactiveDaysAlert.value,
    });
    // onInactiveDaysAlertUpdated
  }

  onInactiveDaysAlertUpdatedAction(response) {
    console.log('onInactiveDaysAlertUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Inactive Days Alert updated');
    } else {
      Message.show('Error updating Inactive Days Alert value');
    }
  }

  handleUpdateHexnodeUsername() {
    console.log('handleUpdateHexnodeUsername');
    // if (this.refs.txtHexnodeUsername.value == "") {
    // 	Message.show("Enter a value for Hexnode Username please.");
    // 	this.refs.txtHexnodeUsername.focus();
    // 	return;
    // }

    CareAccountStore.updateHexnodeUserName({
      AccountID: this.state.accountID,
      UserName: this.refs.txtHexnodeUsername.value,
    });
    // onHexnodeUserNameUpdatedAction
  }
  handleUpdateHexnodeAPI() {
    console.log('handleUpdateHexnodeAPI');
    // if (this.refs.txtHexnodeHexnodeAPI.value == "") {
    // 	Message.show("Enter a value for Hexnode HexnodeAPI please.");
    // 	this.refs.txtHexnodeHexnodeAPI.focus();
    // 	return;
    // }

    CareAccountStore.updateHexnodeAPI({
      AccountID: this.state.accountID,
      HexnodeAPI: this.refs.txtHexnodeAPI.value,
    });
    // onHexnodeUserNameUpdatedAction
  }

  handleUpdateHexnodeGroupID() {
    console.log('handleUpdateHexnodeGroupID');
    CareAccountStore.updateHexnodeGroupID({
      AccountID: this.state.accountID,
      HexnodeGroupID: this.refs.txtHexnodeGroupID.value,
    });
  }
  onHexnodeGroupIDUpdated(response) {
    console.log('onHexnodeGroupIDUpdated');
    console.log(response);
    if (response.ok) {
      Message.show('Hexnode Group ID updated');
    } else {
      Message.show('Error updating Hexnode Group ID value');
    }
  }

  handleUpdateHexnodePolicyID() {
    console.log('handleUpdateHexnodePolicyID');
    if (this.refs.txtHexnodePolicyID.value == '') {
      this.refs.txtHexnodePolicyID.focus();
      Message.show('Please enter the Policy Id number');
      return;
    }
    CareAccountStore.updateHexnodePolicyID({
      AccountID: this.state.accountID,
      HexnodePolicyID: this.refs.txtHexnodePolicyID.value,
    });
  }
  onHexnodePolicyIDUpdated(response) {
    console.log('onHexnodePolicyIDUpdated');
    console.log(response);
    if (response.ok) {
      Message.show('Hexnode Default Policy ID updated');
    } else {
      Message.show('Error updating Hexnode Default Policy ID value');
    }
  }

  onHexnodeAPIUpdatedAction(response) {
    console.log('onHexnodeAPIUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Hexnode API updated');
    } else {
      Message.show('Error updating Hexnode API value');
    }
  }
  handleUpdateHexnodeUrl() {
    console.log('handleUpdateHexnodeUrl');
    // if (this.refs.txtHexnodeHexnodeUrl.value == "") {
    // 	Message.show("Enter a value for Hexnode HexnodeUrl please.");
    // 	this.refs.txtHexnodeHexnodeUrl.focus();
    // 	return;
    // }

    CareAccountStore.updateHexnodeUrl({
      AccountID: this.state.accountID,
      HexnodeUrl: this.refs.txtHexnodeUrl.value,
    });
    // onHexnodeUserNameUpdatedAction
  }
  onHexnodeUrlUpdatedAction(response) {
    console.log('onHexnodeUrlUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Hexnode URL updated');
    } else {
      Message.show('Error updating Hexnode URL value');
    }
  }
  onHexnodeUserNameUpdatedAction(response) {
    console.log('onHexnodeUserNameUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Hexnode UserName updated');
    } else {
      Message.show('Error updating Hexnode UserName value');
    }
  }

  handleHexnodeIdRequired(e) {
    console.log('this has changed ', e.target.checked);

    CareAccountStore.updateHexnodeIdRequired({
      AccountID: this.state.accountID,
      Required: e.target.checked,
    });
    //onHexnodeIdRequiredUpdatedAction
  }
  onHexnodeIdRequiredUpdatedAction(response) {
    console.log('onHexnodeIdRequiredUpdatedAction', response);

    if (response.ok) {
      let responseResult = response.Required ? ' will be on the Welcome screen.' : ' will not be on the Welcome screen.';
      Message.show('Hexnode Id field  ' + responseResult);
    } else {
      Message.show('Error updating Emergency Schedule');
    }
  }

  //Checked-In Screen Stays on
  handleUpdateCheckedInScreenStaysOn(e) {
    console.log('This has changed', e.target.checked);

    CareAccountStore.updateIsCheckedInScreenStaysOn({
      AccountID: this.state.accountID,
      IsCheckedInScreenStaysOn: e.target.checked,
    });
  }

  onCheckedInScreenStaysOnUpdatedAction(response) {
    console.log('onCheckedInScreenStaysOnUpdatedAction');

    if (response.ok) {
      Message.show('Checked-In Screen Stays on updated');
    } else {
      Message.show('Error updating Checked-In Screen Stays on');
    }
  }

  handleRestartDeviceOffline(e) {
    console.log('This has changed', e.target.checked);

    CareAccountStore.updateRestartDeviceOffline({
      AccountID: this.state.accountID,
      Enabled: e.target.checked,
    });
    // onRestartDeviceOfflineUpdated
  }
  onRestartDeviceOfflineUpdatedAction(response) {
    console.log('onCheckedInScreenStaysOnUpdatedAction');

    if (response.ok) {
      if (response.enabled) {
        Message.show('Restart Offline Device Enabled');
        this.setState({
          btnRestartDeviceOfflineTimeSpanStyle: '',
        });
      } else {
        Message.show('Restart Offline Device Disabled');
        this.setState({
          btnRestartDeviceOfflineTimeSpanStyle: 'disabled',
        });
      }
    } else {
      Message.show('Error updating Restart Offline Device');
    }
  }

  handleUpdateRestartDeviceOfflineTimeSpan() {
    if (this.refs.txtRestartDeviceOfflineTimeSpan.value == '') {
      Message.show('Enter the Time Span (In minutes)');
      return;
    }

    CareAccountStore.updateRestartOfflineDeviceTimeSpan({
      AccountID: this.state.accountID,
      Minutes: this.refs.txtRestartDeviceOfflineTimeSpan.value,
    });
  }
  onRestartOfflineDeviceTimeSpanUpdatedAction(response) {
    console.log('onCheckedInScreenStaysOnUpdatedAction');

    if (response.ok) {
      Message.show('Restart Offline Device TimeSpan Updated');
    } else {
      Message.show('Error updating Restart Offline Device TimeSpan');
    }
  }

  handleShowBatteryStatus(e) {
    console.log('This has changed', e.target.checked);

    CareAccountStore.updateShowBatteryStatus({
      AccountID: this.state.accountID,
      ShowBatteryStatus: e.target.checked,
    });
    // onShowBatteryStatusUpdated
  }
  onShowBatteryStatusUpdatedAction(response) {
    console.log('onShowBatteryStatusUpdatedAction');

    if (response.ok) {
      if (response.enabled) {
        Message.show('Show Battery Status Enabled');
      } else {
        Message.show('Show Battery Status Disabled');
      }
    } else {
      Message.show('Error updating Show Battery Status');
    }
  }

  // REFACTOR:2023!
  handleScreenOffBatterySaver = (event) => {
    console.log('handleScreenOffBatterySaver ', event.target.checked);
    let configValue = event.target.checked;
    CareAccountStore.updateAccountConfig(
      this.state.accountID.toLowerCase(),
      {
        ScreenOffBatterySaver: configValue, // boolean
      },
      (response) => {
        if (response.ok) {
          Message.show('Account Screen Off on Battery Saver ' + (configValue ? ' enabled' : ' disabled'));
        } else {
          Message.show('Error updating Account Screen Off on Battery Saver');
        }
      },
    );
  };

  handleEnableImportClients(event) {
    console.log('handleEnableImportClients ', event.target.checked);
    let configValue = event.target.checked;

    CareAccountStore.updateAccountConfig(
      this.state.accountID.toLowerCase(),
      {
        EnableImportClients: configValue, // boolean
      },
      (response) => {
        if (response.ok) {
          Message.show('Account Enable Import Clients from CSV ' + (configValue ? ' enabled' : ' disabled'));
        } else {
          Message.show('Error updating Account Enable Import Clients from CSV');
        }
      },
    );
  }

  handlePagerDuty(event) {
    console.log('this has changed ', event.target.checked);

    CareAccountStore.updateSendToPagerDuty({
      AccountID: this.state.accountID,
      SendToPagerDuty: event.target.checked,
    });
    //onSendToPagerDutyUpdated
  }
  onSendToPagerDutyUpdatedAction(response) {
    if (response.ok) {
      this.setState({
        btnPagerDutyStyle: response.enable ? '' : 'disabled',
      });

      Message.show('Send to Pager Duty updated');
    } else {
      Message.show('Error updating Send to Pager Duty');
    }
  }

  handleUpdatePagerDutyEmail() {
    if (this.refs.txtPagerDutyEmail.value == '') {
      Message.show('Enter the Pager Duty Email please.');
      return;
    }

    CareAccountStore.updatePagerDutyEmail({
      AccountID: this.state.accountID,
      PagerDutyEmailAddress: this.refs.txtPagerDutyEmail.value,
    });
    //onPagerDutyEmailUpdated
  }
  onPagerDutyEmailUpdatedAction(response) {
    if (response.ok) {
      Message.show('Pager Duty Email updated');
    } else {
      Message.show('Error updating Pager Duty Email');
    }
  }

  onAlertOnDutyUsersByEmailUpdatedAction(response) {
    console.log('onAlertOnDutyUsersByEmailUpdatedAction', response);

    if (response.ok) {
      let stringResponse = response.enabled ? ' enabled.' : ' disabled.';
      Message.show('Alert On Duty Users by Email' + stringResponse);
    } else {
      Message.show('Error checking Alert On Duty Users by Email');
    }
  }

  handleOnDutyUsersByEmail(event) {
    // console.log("this has changed ", event.target.checked);
    CareAccountStore.updateOnDutyUsersByEmail({
      AccountID: this.state.accountID,
      Enabled: event.target.checked,
    });
  }

  onNotifyOnDeviceOfflineUpdatedAction(response) {
    if (response.ok) {
      Message.show('Notify on Device Offline updated');
    } else {
      Message.show('Error checking Notify on Device Offline');
    }
  }

  handleNotifyDeviceOffline(event) {
    // I NEED TO ADD ALL THE PARAMETERS FOR THE UPDATE'S WEB SERVICES

    CareAccountStore.updateNotifyDeviceOffline({
      AccountID: this.state.accountID,
      NotifyOnDeviceOffline: event.target.checked,
    });
  }

  onClearingSMSandEmailAccountAlertsUpdatedAction(response) {
    if (response.ok) {
      Message.show('Text Message and Email alerts updated');
    } else {
      Message.show('Error updating Text MEssage and Email alerts');
    }
  }

  onDeviceOfflineEnableUpdatedAction(response) {
    if (response.ok) {
      if (this.state.deviceOfflineCheckBtnDisabled == '' && (this.refs.checkDeviceOffSms.checked || this.refs.checkDeviceOffEmail.checked)) {
        this.refs.checkDeviceOffSms.checked = false;
        this.refs.checkDeviceOffEmail.checked = false;
        CareAccountStore.clearSMSandEmailAccountAlerts({
          AccountID: this.state.accountID,
          AlertTypeTag: 'deviceOffline',
        });
      }

      CareAccountStore.getAccountAlertsData({
        AccountID: this.state.accountID,
      });

      Message.show('Device went offline alert updated');
    } else {
      Message.show('Error checking Device went offline alert');
    }
  }

  handleDeviceOfflineEnable(event) {
    let alertTypeTag = 'deviceOffline';

    CareAccountStore.updateDeviceOfflineEnable({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      Enable: event.target.checked,
    });
  }

  onDeviceOfflineSMSUpdatedAction(response) {
    if (response.ok) {
      Message.show('Device went offline Text Message updated');
    } else {
      Message.show('Error checking Device went offline Text Message');
    }
  }

  handleDeviceOfflineSMS(event) {
    let alertTypeTag = 'deviceOffline';

    CareAccountStore.updateDeviceOfflineSMS({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSendSMS: event.target.checked,
    });
  }
  onDeviceOfflineEmailUpdatedAction(response) {
    if (response.ok) {
      Message.show('Device went offline email alert updated');
    } else {
      Message.show('Error checking Device went offline email alert');
    }
  }

  handleDeviceOfflineEmail(event) {
    let alertTypeTag = 'deviceOffline';

    CareAccountStore.updateDeviceOfflineEmail({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSend: event.target.checked,
    });
  }

  handleCallNotAnsweredEnable(event) {
    let alertTypeTag = 'callNotAnswered';

    CareAccountStore.updateCallNotAnsweredEnable({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      Enable: event.target.checked,
    });
  }

  onCallNotAnsweredEnableUpdatedAction(response) {
    if (response.ok) {
      if (this.state.callNotAnsweredCheckBtnDisabled == '' && (this.refs.checkCallNotAnsweredSMS.checked || this.refs.checkCallNotAnsweredEmail)) {
        this.refs.checkCallNotAnsweredSMS.checked = false;
        this.refs.checkCallNotAnsweredEmail.checked = false;
        CareAccountStore.clearSMSandEmailAccountAlerts({
          AccountID: this.state.accountID,
          AlertTypeTag: 'callNotAnswered',
        });
      }

      CareAccountStore.getAccountAlertsData({
        AccountID: this.state.accountID,
      });
      Message.show('Calls not answered alert updated');
    } else {
      Message.show('Error checking Calls not answered alert');
    }
  }

  handleCallNotAnsweredSMS(event) {
    let alertTypeTag = 'callNotAnswered';

    CareAccountStore.updateCallNotAnsweredSMS({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSendSMS: event.target.checked,
    });
  }
  onCallNotAnsweredSMSUpdatedAction(response) {
    if (response.ok) {
      Message.show('Calls not answered Text Message updated');
    } else {
      Message.show('Error checking Calls not answered Text Message');
    }
  }

  handleCallNotAnsweredEmail(event) {
    let alertTypeTag = 'callNotAnswered';

    CareAccountStore.updateCallNotAnsweredEmail({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSend: event.target.checked,
    });
  }
  onCallNotAnsweredEmailUpdatedAction(response) {
    console.log('onCallNotAnsweredEmailUpdatedAction');
    if (response.ok) {
      Message.show('Calls not answered email alert updated');
    } else {
      Message.show('Error checking Calls not answered email alert');
    }
  }

  //CallNotEstablished
  handleCallNotEstablishedEnabled(event) {
    let alertTypeTag = 'callNotEstablished';

    CareAccountStore.updateCallNotEstablishedEnabled({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      Enable: event.target.checked,
    });
    //onCallNotEstablishedEnabledUpdated
  }
  onCallNotEstablishedEnabledUpdatedAction(response) {
    if (response.ok) {
      if (this.state.callNotEstablishedCheckBtnDisabled == '' && (this.refs.checkCallNotEstablishedSMS.checked || this.refs.checkCallNotEstablishedEmail)) {
        this.refs.checkCallNotEstablishedSMS.checked = false;
        this.refs.checkCallNotEstablishedEmail.checked = false;
        CareAccountStore.clearSMSandEmailAccountAlerts({
          AccountID: this.state.accountID,
          AlertTypeTag: 'callNotEstablished',
        });
      }

      CareAccountStore.getAccountAlertsData({
        AccountID: this.state.accountID,
      });
      Message.show('Calls not established alert updated');
    } else {
      Message.show('Error checking Calls not established alert');
    }
  }

  handleCallNotEstablishedSMS(event) {
    let alertTypeTag = 'callNotEstablished';

    CareAccountStore.updateCallNotEstablishedSMS({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSendSMS: event.target.checked,
    });
  }
  onCallNotEstablishedSMSUpdatedAction(response) {
    if (response.ok) {
      Message.show('Calls not established Text Message updated');
    } else {
      Message.show('Error checking Calls not established Text Message');
    }
  }

  handleCallNotEstablishedEmail(event) {
    let alertTypeTag = 'callNotEstablished';

    CareAccountStore.updateCallNotEstablishedEmail({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSend: event.target.checked,
    });
  }
  onCallNotEstablishedEmailUpdatedAction(response) {
    console.log('onCallNotEstablishedEmailUpdatedAction');
    if (response.ok) {
      Message.show('Calls not established email alert updated');
    } else {
      Message.show('Error checking Calls not established email alert');
    }
  }

  onMessageAlertEnableUpdatedAction(response) {
    if (response.ok) {
      if (this.state.messageAlertCheckBtnDisabled == '' && (this.refs.checkMessageAlertSMS.checked || this.refs.checkMessageAlertEmail.checked)) {
        this.refs.checkMessageAlertSMS.checked = false;
        this.refs.checkMessageAlertEmail.checked = false;
        CareAccountStore.clearSMSandEmailAccountAlerts({
          AccountID: this.state.accountID,
          AlertTypeTag: 'message',
        });
      }

      CareAccountStore.getAccountAlertsData({
        AccountID: this.state.accountID,
      });
      Message.show('Message alert updated');
    } else {
      Message.show('Error checking Message Alert');
    }
  }

  handleMessageAlertEnable(event) {
    let alertTypeTag = 'message';

    CareAccountStore.updateMessageAlertEnable({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      Enable: event.target.checked,
    });
  }

  onMessageAlertSMSUpdatedAction(response) {
    if (response.ok) {
      Message.show('Message alert Text Message updated');
    } else {
      Message.show('Error checking Message alert text message');
    }
  }

  handleMessageAlertSMS(event) {
    let alertTypeTag = 'message';

    CareAccountStore.updateMessageAlertSMS({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSendSMS: event.target.checked,
    });
  }

  onMessageAlertEmailUpdatedAction(response) {
    if (response.ok) {
      Message.show('Message email alert updated');
    } else {
      Message.show('Error checking Message email alert');
    }
  }

  handleMessageAlertEmail(event) {
    let alertTypeTag = 'message';

    CareAccountStore.updateMessageAlertEmail({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSend: event.target.checked,
    });
  }

  onBiometricAlertEnableUpdatedAction(response) {
    if (response.ok) {
      if (this.state.biometricAlertCheckBtnDisabled == '' && (this.refs.checkBiometricAlertSMS.checked || this.refs.checkBiometricAlertEmail.checked)) {
        this.refs.checkBiometricAlertSMS.checked = false;
        this.refs.checkBiometricAlertEmail.checked = false;
        CareAccountStore.clearSMSandEmailAccountAlerts({
          AccountID: this.state.accountID,
          AlertTypeTag: 'biometric',
        });
      }

      CareAccountStore.getAccountAlertsData({
        AccountID: this.state.accountID,
      });
      Message.show('Biometric alert updated');
    } else {
      Message.show('Error checking Biometric alert');
    }
  }

  handleBiometricAlertEnable(event) {
    let alertTypeTag = 'biometric';

    CareAccountStore.updateBiometricAlertEnable({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      Enable: event.target.checked,
    });
  }

  onBiometricAlertSMSUpdatedAction(response) {
    if (response.ok) {
      Message.show('Biometric alert Text Message updated');
    } else {
      Message.show('Error checking Biometric alert Text Message');
    }
  }

  handleBiometricAlertSMS(event) {
    let alertTypeTag = 'biometric';

    CareAccountStore.updateBiometricAlertSMS({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSendSMS: event.target.checked,
    });
  }

  onBiometricAlertEmailUpdatedAction(response) {
    if (response.ok) {
      Message.show('Biometric email alert updated');
    } else {
      Message.show('Error checking Biometric email alert');
    }
  }

  handleBiometricAlertEmail(event) {
    let alertTypeTag = 'biometric';

    CareAccountStore.updateBiometricAlertEmail({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSend: event.target.checked,
    });
  }

  onMiscAlertEnableUpdatedAction(response) {
    if (response.ok) {
      if (this.state.miscAlertCheckBtnDisabled == '' && (this.refs.checkMiscAlertSMS.checked || this.refs.checkMiscAlertEmail.checked)) {
        this.refs.checkMiscAlertSMS.checked = false;
        this.refs.checkMiscAlertEmail.checked = false;
        CareAccountStore.clearSMSandEmailAccountAlerts({
          AccountID: this.state.accountID,
          AlertTypeTag: 'misc',
        });
      }

      CareAccountStore.getAccountAlertsData({
        AccountID: this.state.accountID,
      });
      Message.show('Miscellaneous alert updated');
    } else {
      Message.show('Error checking Miscellaneous alert');
    }
  }

  onBatteryAlertEnableUpdatedAction(response) {
    console.log('onBatteryAlertEnableUpdatedAction', response);
    if (response.ok) {
      if (this.state.batteryAlertCheckBtnDisabled == '' && (this.refs.checkBatteryAlertSMS.checked || this.refs.checkBatteryAlertEmail.checked)) {
        this.refs.checkBatteryAlertSMS.checked = false;
        this.refs.checkBatteryAlertEmail.checked = false;
        CareAccountStore.clearSMSandEmailAccountAlerts({
          AccountID: this.state.accountID,
          AlertTypeTag: 'battery',
        });
      }

      CareAccountStore.getAccountAlertsData({
        AccountID: this.state.accountID,
      });
      Message.show('Battery alert updated');
    } else {
      Message.show('Error checking Battery alert');
    }
  }

  handleMiscAlertEnable(event) {
    let alertTypeTag = 'misc';

    CareAccountStore.updateMiscAlertEnable({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      Enable: event.target.checked,
    });
  }
  handleBatteryAlertEnable(event) {
    let alertTypeTag = 'battery';

    CareAccountStore.updateBatteryAlertEnable({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      Enable: event.target.checked,
    });
  }

  onMiscAlertSMSUpdatedAction(response) {
    if (response.ok) {
      Message.show('Miscellaneous alert Text Message updated');
    } else {
      Message.show('Error checking Miscellaneous alert Text Message');
    }
  }
  onBatteryAlertSMSUpdatedAction(response) {
    if (response.ok) {
      Message.show('Battery alert Text Message updated');
    } else {
      Message.show('Error checking Battery alert Text Message');
    }
  }

  handleMiscAlertSMS(event) {
    let alertTypeTag = 'misc';

    CareAccountStore.updateMiscAlertSMS({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSendSMS: event.target.checked,
    });
  }
  handleBatteryAlertSMS(event) {
    let alertTypeTag = 'battery';

    CareAccountStore.updateBatteryAlertSMS({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSendSMS: event.target.checked,
    });
  }

  onMiscAlertEmailUpdatedAction(response) {
    if (response.ok) {
      Message.show('Miscellaneous email alert updated');
    } else {
      Message.show('Error checking Miscellaneous email alert');
    }
  }
  onBatteryAlertEmailUpdatedAction(response) {
    if (response.ok) {
      Message.show('Batteryellaneous email alert updated');
    } else {
      Message.show('Error checking Batteryellaneous email alert');
    }
  }

  handleMiscAlertEmail(event) {
    let alertTypeTag = 'misc';

    CareAccountStore.updateMiscAlertEmail({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSend: event.target.checked,
    });
  }

  handleBatteryAlertEmail(event) {
    let alertTypeTag = 'battery';

    CareAccountStore.updateBatteryAlertEmail({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSend: event.target.checked,
    });
  }

  onHomeAlertEnableUpdatedAction(response) {
    if (response.ok) {
      if (this.state.homeAlertCheckBtnDisabled == '' && (this.refs.checkHomeAlertSMS.checked || this.refs.checkHomeAlertEmail.checked)) {
        this.refs.checkHomeAlertSMS.checked = false;
        this.refs.checkHomeAlertEmail.checked = false;
        CareAccountStore.clearSMSandEmailAccountAlerts({
          AccountID: this.state.accountID,
          AlertTypeTag: 'home',
        });
      }

      CareAccountStore.getAccountAlertsData({
        AccountID: this.state.accountID,
      });
      Message.show('Home environment alert updated');
    } else {
      Message.show('Error checking Home environment alert');
    }
  }

  handleHomeAlertEnable(event) {
    let alertTypeTag = 'home';

    CareAccountStore.updateHomeAlertEnable({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      Enable: event.target.checked,
    });
  }

  onHomeAlertSMSUpdatedAction(response) {
    if (response.ok) {
      Message.show('Home environment alert Text Message updated');
    } else {
      Message.show('Error checking Home environment alert Text Message');
    }
  }

  handleHomeAlertSMS(event) {
    let alertTypeTag = 'home';

    CareAccountStore.updateHomeAlertSMS({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSendSMS: event.target.checked,
    });
  }

  onHomeAlertEmailUpdatedAction(response) {
    if (response.ok) {
      Message.show('Home environment email alert updated');
    } else {
      Message.show('Error checking Home environment email alert');
    }
  }

  handleHomeAlertEmail(event) {
    let alertTypeTag = 'home';

    CareAccountStore.updateHomeAlertEmail({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSend: event.target.checked,
    });
  }

  onBleDevicesAlertEnableUpdatedAction(response) {
    if (response.ok) {
      if (this.state.bleDevicesAlertCheckBtnDisabled == '' && (this.refs.checkBleDevicesAlertSMS.checked || this.refs.checkBleDevicesAlertEmail.checked)) {
        this.refs.checkBleDevicesAlertSMS.checked = false;
        this.refs.checkBleDevicesAlertEmail.checked = false;
        CareAccountStore.clearSMSandEmailAccountAlerts({
          AccountID: this.state.accountID,
          AlertTypeTag: 'bleDevices',
        });
      }

      CareAccountStore.getAccountAlertsData({
        AccountID: this.state.accountID,
      });
      Message.show('BLE devices alert updated');
    } else {
      Message.show('Error checking BLE devices alert');
    }
  }

  handleBleDevicesAlertEnable(event) {
    let alertTypeTag = 'bleDevices';

    CareAccountStore.updateBleDevicesAlertEnable({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      Enable: event.target.checked,
    });
  }

  onBleDevicesAlertSMSUpdatedAction(response) {
    if (response.ok) {
      Message.show('BLE devices alert Text Message updated');
    } else {
      Message.show('Error checking Text Message BLE devices alert');
    }
  }

  handleBleDevicesAlertSMS(event) {
    let alertTypeTag = 'bleDevices';

    CareAccountStore.updateBleDevicesAlertSMS({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSendSMS: event.target.checked,
    });
  }

  onBleDevicesAlertEmailUpdatedAction(response) {
    if (response.ok) {
      Message.show('BLE devices email alert updated');
    } else {
      Message.show('Error checking email BLE devices email alert');
    }
  }

  handleBleDevicesAlertEmail(event) {
    let alertTypeTag = 'bleDevices';

    CareAccountStore.updateBleDevicesAlertEmail({
      AccountID: this.state.accountID,
      AlertTypeTag: alertTypeTag,
      ShouldSend: event.target.checked,
    });
  }

  onAlertOnExtremeRoomTemperatureUpdatedAction(response) {
    if (response.ok) {
      let stringResponse = response.enabled ? ' enabled.' : ' disabled.';
      Message.show('Alert on Extreme Room Temperature' + stringResponse);
    } else {
      Message.show('Error checking Alert on Extreme Room Temperature');
    }
  }

  handleExtremeRoomTemperature(event) {
    // console.log("this has changed ", event.target.checked);

    CareAccountStore.updateAlertExtremeTemperature({
      AccountID: this.state.accountID,
      Enabled: event.target.checked,
    });
  }

  handleEnableRemoteLogs = (event) => {
    console.log('handleEnableRemoteLogs', event.target.checked);

    CareAccountStore.updateRemoteLogs({
      AccountID: this.state.accountID,
      Enabled: event.target.checked,
    });
  };

  onRemoteLogsUpdatedAction = (response) => {
    if (response.ok) {
      let responseResult = response.RemoteLogs ? ' Enabled.' : ' Disabled.';

      Message.show('Remote Logs ' + responseResult);
    } else {
      Message.show('Error updating Remote Logs');
    }
  };

  onEnableControlVideoCallUpdatedAction = (response) => {
    if (response.ok) {
      let responseResult = response.status ? ' Enabled.' : ' Disabled.';
      Message.show('Video Call on Web Control ' + responseResult);
    } else {
      Message.show('Error updating Video Call on Web Control');
    }

    this.refreshAccountData();
  };
  onEnableControlAudioCallUpdatedAction = (response) => {
    if (response.ok) {
      let responseResult = response.status ? ' Enabled.' : ' Disabled.';
      Message.show('Audio Call on Web Control ' + responseResult);
    } else {
      Message.show('Error updating Audio Call on Web Control');
    }

    this.refreshAccountData();
  };
  onEnableControlBargeInCallUpdatedAction = (response) => {
    if (response.ok) {
      let responseResult = response.status ? ' Enabled.' : ' Disabled.';
      Message.show('Barge-in Call on Web Control ' + responseResult);
    } else {
      Message.show('Error updating Barge-in Call on Web Control');
    }

    this.refreshAccountData();
  };

  onEnableControlSilentBargeInCallUpdatedAction = (response) => {
    if (response.ok) {
      let responseResult = response.status ? ' Enabled.' : ' Disabled.';
      Message.show('Silent Barge-in Call on Web Control ' + responseResult);
    } else {
      Message.show('Error updating Silent Barge-in Call on Web Control');
    }

    this.refreshAccountData();
  };

  onEnableControlAutomaticCallRequestBargeInUpdatedAction = (response) => {
    if (response.ok) {
      let responseResult = response.status ? ' Enabled.' : ' Disabled.';
      Message.show('Automatic Call Request Barge In on Web Control ' + responseResult);
    } else {
      Message.show('Error updating Automatic Call Request Barge In on Web Control');
    }
    this.refreshAccountData();
  };

  handleUseBgImgTemplate(event) {
    console.log('this has changed ', event.target.checked);

    Message.show('Deprecated');
    return;

    CareAccountStore.updateUseDeviceTemplate({
      AccountID: this.state.accountID,
      EnableDeviceTemplate: event.target.checked ? 1 : 0,
    });
    // onUseDeviceTemplateUpdated
  }

  onUseDeviceTemplateUpdatedAction(response) {
    console.log('onUseDeviceTemplateUpdatedAction');
    console.log(response);

    if (response.ok) {
      let responseResult = response.EnableDeviceTemplate.toString() == '1' ? ' enabled' : ' disabled';

      // this.setState({
      //     bgImgMode : (response.EnableDeviceTemplate.toString() == "1") ? "" : "disabled"
      // });

      Message.show('Device Template ' + responseResult);
    } else {
      Message.show('Error updating Device Template ');
    }
  }

  handleUpdateBackgroundImgUrl() {
    // if (this.refs.txtBackgroundImgUrl.value == ""){
    //     Message.show("Enter the Background Image Url please");
    //     this.refs.txtBackgroundImgUrl.focus();
    //     return;
    // }

    CareAccountStore.updateBackgroundImgUrl({
      AccountID: this.state.accountID,
      UpdateBackgroundImageUrl: this.refs.txtBackgroundImgUrl.value,
    });
    //onBackgroundImgUrlUpdated
  }

  onBackgroundImgUrlUpdatedAction(response) {
    if (response.ok) {
      Message.show('Background Image Url updated');
    } else {
      Message.show('Error updating Background Image Url ');
    }
  }

  // MESSAGE RESPONSE BY EMAIL
  handleEnableMessageResponse(event) {
    console.log('this has changed ', event.target.checked);

    CareAccountStore.updateEnableMessageResponse({
      AccountID: this.state.accountID,
      Enable: event.target.checked,
    });
    // onEnableMessageResponseUpdated
  }
  onEnableMessageResponseUpdatedAction(response) {
    if (response.ok) {
      let responseResult = response.enable ? ' enabled' : ' disabled';

      this.setState({
        sendMessageResponseMode: response.enable ? '' : 'disabled',
      });

      Message.show('Enable Message Response by Email ' + responseResult);
    } else {
      Message.show('Error updating Enable Message Response by Email');
    }
  }
  handleUpdateMessageResponseEmailAddress() {
    CareAccountStore.updateMessageResponseEmail({
      AccountID: this.state.accountID,
      EmailAddress: this.refs.txtMessageResponseEmailAddress.value,
    });
  }
  onMessageResponseEmailUpdatedAction(response) {
    if (response.ok) {
      Message.show('Enable Message Response by Email updated');
    } else {
      Message.show('Error updating Enable Message Response by Email');
    }
  }

  handleUpdateEscalationEmailAddress() {
    CareAccountStore.updateEscalationEmail({
      AccountID: this.state.accountID,
      EmailAddress: this.refs.txtEscalationEmailAddress.value,
    });
    //onEscalationEmailUpdated
  }
  onEscalationEmailUpdatedAction(response) {
    if (response.ok) {
      if (this.refs.txtEscalationEmailAddress.value.length == 0) {
        Message.show('Escalation Alert Email Address deleted');
      } else {
        Message.show('Escalation Alert Email Address updated');
      }
    } else {
      Message.show('Error updating Escalation Alert Email Address');
    }
  }

  // Listen from Every AccountDeviceItemRow
  onEnableRemoteLogsChangedAction = (response) => {
    console.log('onEnableRemoteLogsChangedAction', response);

    if (response.ok) {
      let responseResult = response.Enabled ? ' enabled' : ' disabled';
      Message.show('Remote Logs ' + responseResult);
    } else {
      Message.show('Error setting Remote Logs, please try again.');
    }
  };

  handleSalesforceEnabled(event) {
    console.log('handleSalesforceEnabled', event.target.checked);

    CareAccountStore.updateSalesforceEnabled({
      AccountID: this.state.accountID,
      Enabled: event.target.checked,
    });
    // onSalesforceEnabledUpdated
  }
  onSalesforceEnabledUpdatedAction = (response) => {
    console.log('onSalesforceEnabledUpdatedAction', response);

    if (response.ok) {
      let responseResult = response.Enabled ? ' enabled' : ' disabled';
      Message.show('Salesforce ' + responseResult);
    } else {
      Message.show('Error setting Salesforce, please try again.');
    }
  };

  handleUpdateSalesforceOrgUrl() {
    console.log('handleUpdateSalesforceOrgUrl');
    if (this.refs.txtSalesforceOrgUrl.value == '') {
      Message.show('Enter a value for the Org Url please.');
      this.refs.txtSalesforceOrgUrl.focus();
      return;
    }
    CareAccountStore.updateSalesforceOrgUrl({
      AccountID: this.state.accountID,
      OrgUrl: this.refs.txtSalesforceOrgUrl.value,
    });
    // onSalesforceOrgUrlUpdated
  }

  handleUpdateSalesforceLoginUrl() {
    console.log('handleUpdateSalesforceLoginUrl');
    if (this.refs.txtSalesforceLoginUrl.value == '') {
      Message.show('Enter a value for the Login Url please.');
      this.refs.txtSalesforceLoginUrl.focus();
      return;
    }
    CareAccountStore.updateSalesforceLoginUrl({
      AccountID: this.state.accountID,
      LoginUrl: this.refs.txtSalesforceLoginUrl.value,
    });
    // onSalesforceOrgUrlUpdated
  }

  onSalesforceOrgUrlUpdatedAction(response) {
    console.log('onSalesforceOrgUrlUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Org Url updated');
      this.setState({
        salesforceValidCredentials: false,
      });
    } else {
      Message.show('Error updating Org Url value');
    }
  }

  onSalesforceLoginUrlUpdatedAction(response) {
    console.log('onSalesforceLoginUrlUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Login Url updated');
      this.setState({
        salesforceValidCredentials: false,
      });
    } else {
      Message.show('Error updating Login Url value');
    }
  }

  handleUpdateSalesforceClientID() {
    console.log('handleUpdateSalesforceClientID');
    if (this.refs.txtSalesforceClientID.value == '') {
      Message.show('Enter a value for Client ID please.');
      this.refs.txtSalesforceClientID.focus();
      return;
    }
    CareAccountStore.updateSalesforceClientID({
      AccountID: this.state.accountID,
      ClientID: this.refs.txtSalesforceClientID.value,
    });
    // onSalesforceClientIDUpdated
  }
  onSalesforceClientIDUpdatedAction(response) {
    console.log('onSalesforceClientIDUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Client ID updated');
      this.setState({
        salesforceValidCredentials: false,
      });
    } else {
      Message.show('Error updating Client ID value');
    }
  }

  handleUpdateSalesforceClientSecret() {
    console.log('handleUpdateSalesforceClientSecret');
    if (this.refs.txtSalesforceClientSecret.value == '') {
      Message.show('Enter a value for Client ID please.');
      this.refs.txtSalesforceClientSecret.focus();
      return;
    }
    CareAccountStore.updateSalesforceClientSecret({
      AccountID: this.state.accountID,
      ClientSecret: this.refs.txtSalesforceClientSecret.value,
    });
    // onSalesforceClientSecretUpdated
  }
  onSalesforceClientSecretUpdatedAction(response) {
    console.log('onSalesforceClientSecretUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Client Secret updated');
      this.setState({
        salesforceValidCredentials: false,
      });
    } else {
      Message.show('Error updating Client Secret value');
    }
  }

  handleUpdateSalesforceUserName() {
    console.log('handleUpdateSalesforceUserName');
    if (this.refs.txtSalesforceUserName.value == '') {
      Message.show('Enter a value for UserName please.');
      this.refs.txtSalesforceUserName.focus();
      return;
    }
    CareAccountStore.updateSalesforceUserName({
      AccountID: this.state.accountID,
      UserName: this.refs.txtSalesforceUserName.value,
    });
    // onSalesforceUserNameUpdated
  }
  onSalesforceUserNameUpdatedAction(response) {
    console.log('onSalesforceUserNameUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('UserName updated');
      this.setState({
        salesforceValidCredentials: false,
      });
    } else {
      Message.show('Error updating UserName value');
    }
  }

  handleUpdateSalesforcePassword() {
    console.log('handleUpdateSalesforcePassword');
    if (this.refs.txtSalesforcePassword.value == '') {
      Message.show('Enter a value for Password please.');
      this.refs.txtSalesforcePassword.focus();
      return;
    }
    CareAccountStore.updateSalesforcePassword({
      AccountID: this.state.accountID,
      Password: this.refs.txtSalesforcePassword.value,
    });
    // onSalesforcePasswordUpdated
  }
  onSalesforcePasswordUpdatedAction(response) {
    console.log('onSalesforcePasswordUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Password updated');
      this.setState({
        salesforceValidCredentials: false,
      });
    } else {
      Message.show('Error updating Password value');
    }
  }

  handleUpdateSalesforceRedirectUri() {
    console.log('handleUpdateSalesforceRedirectUri');
    if (this.refs.txtSalesforceRedirectUri.value == '') {
      Message.show('Enter a value for the Redirect Uri please.');
      this.refs.txtSalesforceRedirectUri.focus();
      return;
    }
    CareAccountStore.updateSalesforceRedirectUri({
      AccountID: this.state.accountID,
      RedirectUri: this.refs.txtSalesforceRedirectUri.value,
    });
    // onSalesforceRedirectUriUpdated
  }
  onSalesforceRedirectUriUpdatedAction(response) {
    console.log('onSalesforceRedirectUriUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Redirect Uri updated');
      this.setState({
        salesforceValidCredentials: false,
      });
    } else {
      Message.show('Error updating Redirect Uri value');
    }
  }

  handleUpdateSalesforceSecurityToken() {
    console.log('handleUpdateSalesforceSecurityToken');
    if (this.refs.txtSalesforceSecurityToken.value == '') {
      Message.show('Enter a value for the Redirect Uri please.');
      this.refs.txtSalesforceSecurityToken.focus();
      return;
    }
    CareAccountStore.updateSalesforceSecurityToken({
      AccountID: this.state.accountID,
      SecurityToken: this.refs.txtSalesforceSecurityToken.value,
    });
    // onSalesforceSecurityTokenUpdated
  }
  onSalesforceSecurityTokenUpdatedAction(response) {
    console.log('onSalesforceSecurityTokenUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Security Token updated');
    } else {
      Message.show('Error updating Security Token value');
    }
  }

  verifySalesforceConfiguration() {
    console.log('verifySalesforceConfiguration');
    this.setState({
      salesforceCheckStatus: 'Checking Salesforce Connection Status...',
    });
    CareAccountStore.requestNucleusApi(
      'Salesforce.asmx/CheckSalesforceConfiguration',
      {
        AccountID: this.state.accountID,
      },
      'onCheckSalesforceConfigurationResponse',
    );
    window.$('#modalCheckSalesforceConnection').modal('open');
  }

  handleCloseSalesforceConfigurationModal() {
    window.$('#modalCheckSalesforceConnection').modal('close');
  }

  onCheckSalesforceConfigurationResponseAction = (response) => {
    console.log('onCheckSalesforceConfigurationResponseAction', response);
    if (response.ok) {
      this.setState({
        salesforceCheckStatus: 'Salesforce Connection Ok',
        salesforceValidCredentials: true,
      });
    } else {
      this.setState({
        salesforceCheckStatus: 'Salesforce Connection Error: ' + response.error,
      });
    }
  };

  checkSalesforceIntegration() {
    console.log('checkSalesforceIntegration');
    let params = {
      UserID: AuthStore.getCsUserID(),
      AccountID: this.state.accountID,
      ObjectName: 'NucleusCare__Nucleus_Event__c', // "Nucleus Event"
    };

    CareAccountStore.requestNucleusApi('Salesforce.asmx/CheckSalesforceIntegration', params, 'onCheckSalesforceIntegrationResponse');
    this.setState({
      salesforceConfigurationStatus: 'Checking Salesforce Configuration ...',
    });
    window.$('#modalSalesforceIntegration').modal('open');
  }

  handleCloseSalesforceIntegrationModal() {
    //@ts-ignore
    window.$('#modalSalesforceIntegration').modal('close');
  }

  onCheckSalesforceIntegrationResponseAction = (response) => {
    console.log('onCheckSalesforceIntegrationResponseAction', response);
    if (response.ok && response.salesforceData) {
      this.setState({
        salesforceConfigurationStatus: "Object 'Nucleus Event' is already created!",
      });
      //let sData = JSON.parse(response.salesforceData);
      //console.log("sData", sData);
    } else {
      this.setState({
        salesforceConfigurationStatus: "Object 'Nucleus Event' doesn't exist.",
        salesforceConfigurationNeeded: true,
      });
    }
  };

  handleConfigureSalesforce() {
    let params = {
      AccountID: this.state.accountID,
      ObjectName: 'NucleusCare__Nucleus_Event__c', // "Nucleus Event"
    };
    CareAccountStore.requestNucleusApi('Salesforce.asmx/ConfigureSalesforceIntegration', params, 'onConfigureSalesforceIntegrationResponse');
    this.setState({
      salesforceConfigurationStatus: 'Configuring Nucleus-Salesforce Integration ...',
      salesforceConfigurationNeeded: false,
    });
  }

  onConfigureSalesforceIntegrationResponseAction = (response) => {
    console.log('onConfigureSalesforceIntegrationResponseAction', response);
    let status = '';
    if (response.ok) {
      if (response.info) {
        status = response.info;
      } else {
        status = 'Configuration Objects for salesforce created.';
      }
    } else {
      if (response.error) {
        status = response.error;
      } else {
        status = 'Error creating configuration objects for Salesforce.';
      }
    }
    this.setState({
      salesforceConfigurationStatus: status,
      salesforceConfigurationNeeded: false,
    });
  };

  handleUpdateEscalationTimeOut = () => {
    if (this.refs.txtEscalationTimeOut.value == '') {
      Message.show('Enter the Escalation Call Request Timeout (in seconds) please.');
      this.refs.txtEscalationTimeOut.focus();
      return;
    }

    CareAccountStore.updateEscalationTimeOut({
      AccountID: this.state.accountID,
      ReceiveEscalationCallRequestTimeout: this.refs.txtEscalationTimeOut.value,
    });
    //onEscalationTimeOutUpdated
  };

  onEscalationTimeOutUpdatedAction = (response) => {
    console.log('onEscalationTimeOutUpdatedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Escalation Call Request Timeout updated');
    } else {
      Message.show('Error updating Escalation Call Request Timeout');
    }
  };

  onUserResetPassword = (userID, userName) => {
    console.log('onUserResetPassword');
    console.log('onUserResetPassword userID', userID);
    console.log('onUserResetPassword userName', userName);
    this.setState({
      selectedUserId: userID,
      selectedUserName: userName,
    });
    // 	selectedUserResetPassword: false,
    // 	selectedUserResetPasswordDone: false
  };

  onUserResetPasswordClear = () => {
    console.log('onUserResetPasswordClear');
    this.setState({
      selectedUserId: null,
      selectedUserName: null,
    });
  };

  render() {
    let deviceStatusIcon = 'img/icon_device_inactive.png';

    let showCallValue = this.state.accountShowCallButton;
    let showCallValue2 = this.state.accountShowCallButton2;

    let showCheckInValue = this.state.accountShowCheckInButton;
    let showEmergencyValue = this.state.accountShowEmergencyButton;

    let ringOnRequestValue = this.state.accountRingOnRequest;

    //let enableBluetoothButtonsValue = this.state.accountEnableBluetoothButtons ? "checked" : "";

    let importClientsValue = this.state.accountImportClientsEnabled;
    let pagerDutyValue = this.state.accountSendToPagerDuty;

    let enableVideoCallShortcutValue = this.state.enableVideoCallShortcutValue;
    let enableAudioCallShortcutValue = this.state.enableAudioCallShortcutValue;
    let enableBargeInCallShortcutValue = this.state.enableBargeInCallShortcutValue;
    let enableSilentBargeInCallShortcutValue = this.state.enableSilentBargeInCallShortcutValue;

    let alertOnDutyUsersValue = this.state.alertOnDutyUsers;
    let notifyDeviceOfflineValue = this.state.notifyDeviceOffline;
    let extremeRoomTemperatureValue = this.state.alertExtremeRoomTemperature;

    let enableMemoryBoxValue = this.state.accountMemoryBox;

    let showCallControlsValue = this.state.accountShowCallControls;
    let showCallStatsValue = this.state.accountShowStats;
    let showFamilyCallBtnValue = this.state.accountShowFamilyCallBtn;

    // DEPRECATED on March 1st 2023, https://nucleusintercom.atlassian.net/browse/CARE-10228
    //let enablePhoneCallValue = this.state.enablePhoneCall ? "checked" : "";

    let autoAnswerValue = this.state.accountAutoAnswer;

    let mobileCallResponseValue = this.state.mobileCallResponse;

    let forceLandscapeVideoValue = this.state.accountForceLandscapeVideo;
    let ringIncomingCallValue = this.state.accountRingIncomingCall;

    //DEPRECATED: https://nucleusintercom.atlassian.net/browse/CARE-3379 Oct-8-2018
    //let voiceMessageValue = this.state.accountVoiceMessage ? "checked" : "";

    let callScheduleValue = this.state.accountCallSchedule;
    let emergencyScheduleValue = this.state.accountEmergencySchedule;
    let audioCallsOnlyValue = this.state.accountAudioCallsOnly;
    let intercomButtonEnabled = this.state.intercomButtonEnabled;
    let hexnodeIdRequiredValue = this.state.hexnodeIdRequired;
    let useBgImage = this.state.accountUseBgImageTemplate;

    let bgImageEnabledValue = this.state.enableBackgroundImage;
    let bgLogoEnabledValue = this.state.enableBackgroundLogo;

    let enableCheckedInScreenStaysOn = this.state.isCheckedInScreenStaysOn;

    //let sendPalCareEmergencyValue = this.state.sendPalCareEmergency ? "checked" : "";

    let messageResponseByEmailValue = this.state.sendMessageResponseByEmail;
    //accountBackgroundImgUrl : accountData.DeviceTemplate,

    let isHipaaCompliant = this.state.isHipaaCompliant;

    let remoteLogsValue = this.state.remoteLogsValue;

    let salesforceEnabledValue = this.state.salesforceEnabled;
    let restartDeviceOfflineEnabledValue = this.state.restartDeviceOfflineEnabled;
    let showBatteryStatusValue = this.state.showBatteryStatus;

    let availableUpdateGroups = [];
    if (this.state.updateGroupsArray != null && this.state.updateGroupsArray.length) {
      this.state.updateGroupsArray.map((group, key) => {
        availableUpdateGroups.push(
          <option key={group.ID} value={group.ID}>
            {group.Name}
          </option>,
        );
      });
    }

    let deviceOfflineEnabled = '';
    let deviceOfflineSMS = '';
    let deviceOfflineEmail = '';
    let callNotAnsweredEnabled = '';
    let callNotAnsweredSMS = '';
    let callNotAnsweredEmail = '';
    let callNotEstablishedEnabled = '';
    let callNotEstablishedSMS = '';
    let callNotEstablishedEmail = '';
    let messageAlertEnable = '';
    let messageAlertSMS = '';
    let messageAlertEmail = '';
    let biometricAlertEnable = '';
    let biometricAlertSMS = '';
    let biometricAlertEmail = '';
    let miscAlertEnable = '';
    let batteryAlertEnable = '';
    let miscAlertSMS = '';
    let batteryAlertSMS = '';
    let miscAlertEmail = '';
    let batteryAlertEmail = '';
    let homeAlertEnable = '';
    let homeAlertSMS = '';
    let homeAlertEmail = '';
    let bleDevicesAlertEnable = '';
    let bleDevicesAlertSMS = '';
    let bleDevicesAlertEmail = '';
    let deviceOfflineCheckBtnDisabled = false;
    let callNotAnsweredCheckBtnDisabled = false;
    let callNotEstablishedCheckBtnDisabled = false;
    let messageAlertCheckBtnDisabled = false;
    let biometricAlertCheckBtnDisabled = false;
    let miscAlertCheckBtnDisabled = false;
    let batteryAlertCheckBtnDisabled = false;
    let homeAlertCheckBtnDisabled = false;
    let bleDevicesAlertCheckBtnDisabled = false;
    console.log('accountAlertsArray', this.state.accountAlertsArray);
    if (this.state.accountAlertsArray != null && this.state.accountAlertsArray.length) {
      this.state.accountAlertsArray.map((alert, key) => {
        if (alert.AlertTag == 'deviceOffline') {
          deviceOfflineEnabled = alert.Enable;
          deviceOfflineSMS = alert.ShouldSendSMS;
          deviceOfflineEmail = alert.ShouldSendEmail;
          deviceOfflineCheckBtnDisabled = !deviceOfflineEnabled ? true : false;
        }
        if (alert.AlertTag == 'callNotAnswered') {
          callNotAnsweredEnabled = alert.Enable;
          callNotAnsweredSMS = alert.ShouldSendSMS;
          callNotAnsweredEmail = alert.ShouldSendEmail;
          callNotAnsweredCheckBtnDisabled = !callNotAnsweredEnabled;
        }
        if (alert.AlertTag == 'callNotEstablished') {
          callNotEstablishedEnabled = alert.Enable;
          callNotEstablishedSMS = alert.ShouldSendSMS;
          callNotEstablishedEmail = alert.ShouldSendEmail;
          callNotEstablishedCheckBtnDisabled = !callNotEstablishedEnabled;
        }
        if (alert.AlertTag == 'message') {
          messageAlertEnable = alert.Enable;
          messageAlertSMS = alert.ShouldSendSMS;
          messageAlertEmail = alert.ShouldSendEmail;
          messageAlertCheckBtnDisabled = !messageAlertEnable;
        }
        if (alert.AlertTag == 'biometric') {
          biometricAlertEnable = alert.Enable;
          biometricAlertSMS = alert.ShouldSendSMS;
          biometricAlertEmail = alert.ShouldSendEmail;
          biometricAlertCheckBtnDisabled = !biometricAlertEnable;
        }
        if (alert.AlertTag == 'misc') {
          miscAlertEnable = alert.Enable;
          miscAlertSMS = alert.ShouldSendSMS;
          miscAlertEmail = alert.ShouldSendEmail;
          miscAlertCheckBtnDisabled = !miscAlertEnable;
        }
        if (alert.AlertTag == 'battery') {
          batteryAlertEnable = alert.Enable;
          batteryAlertSMS = alert.ShouldSendSMS;
          batteryAlertEmail = alert.ShouldSendEmail;
          batteryAlertCheckBtnDisabled = !batteryAlertEnable;
        }
        if (alert.AlertTag == 'home') {
          homeAlertEnable = alert.Enable;
          homeAlertSMS = alert.ShouldSendSMS;
          homeAlertEmail = alert.ShouldSendEmail;
          homeAlertCheckBtnDisabled = !homeAlertEnable;
        }
        if (alert.AlertTag == 'bleDevices') {
          bleDevicesAlertEnable = alert.Enable;
          bleDevicesAlertSMS = alert.ShouldSendSMS;
          bleDevicesAlertEmail = alert.ShouldSendEmail;
          bleDevicesAlertCheckBtnDisabled = !bleDevicesAlertEnable;
        }
      });
    }

    console.log('callNotEstablishedCheckBtnDisabled', callNotEstablishedCheckBtnDisabled);

    let accountUsersList = [];
    if (this.state.accountUsersArray != null) {
      this.state.accountUsersArray.map((user, key) => {
        accountUsersList.push(
          <AccountUserItemRow
            key={key}
            AccountID={this.state.accountID}
            UserID={user.UserID}
            FirstName={user.FirstName}
            LastName={user.LastName}
            Email={user.Email}
            Telephone={user.Telephone}
            IsAdmin={user.IsAdmin}
            IsOnDuty={user.IsOnDuty}
            Status={user.Created}
            onUserResetPassword={this.onUserResetPassword}
          />,
        );
      });
    }

    let clientsUsersList = [];
    if (this.state.accountClientsArray != null) {
      if (this.state.accountClientsArray && this.state.accountClientsArray.length > 0) {
        this.state.accountClientsArray.map((patient, key) => {
          clientsUsersList.push(
            <AccountPatientItemRow
              key={key}
              PatientName={patient.PatientName}
              Devices={patient.Devices}
              MemoryBox={patient.MemoryBoxMediaCount}
              TotalFamilyMembers={patient.FamilyMembersCount}
              ActiveFamilyMembers={patient.ActiveFamilyMembersCount}
              Messages={patient.MessagesCount}
              MessagesRead={patient.MessageReadCount}
              MessagesExpired={patient.MessageExpiredCount}
            />,
          );
        });
      } else {
        clientsUsersList.push(
          <tr key={clientsUsersList.length} style={{ borderBottomStyle: 'none' }}>
            <td colSpan={6} className="center-align ">
              <br />
              <br />
              <br />
              <span className="nucleus-table-header-medium text-gray"> {`There are no ${this.state.accountClientRoleNamePlural} in this account`} </span>
              <br />
              <br />
              <br />
            </td>
          </tr>,
        );
      }
    }

    let accountBioDevicesList = [];
    if (this.state.accountBiometricDevicesArray != null) {
      this.state.accountBiometricDevicesArray.map((bioDevice, key) => {
        accountBioDevicesList.push(
          <AccountBioDeviceItemRow
            key={key}
            Mac={bioDevice.BioDeviceMac}
            Name={bioDevice.BioDeviceName}
            PatientName={bioDevice.PatientName}
            TimesUsed={bioDevice.TimesUsed}
            LastUsed={bioDevice.LastUsed}
          />,
        );
      });
    }

    // Show according to the GeneralStore.getClientTab() Value
    let statsContentVisibility = ' hide';
    let devicesContentVisibility = ' hide';
    let usersContentVisibility = ' hide';
    let clientsContentVisibility = ' hide';
    let patientsContentVisibility = ' hide';
    let patientsTimeZoneContentVisibility = ' hide';
    let buttonsContentVisibility = ' hide';
    let bioDevicesContentVisibility = ' hide';
    let scheduleContentVisibility = ' hide';
    let externalAppsContentVisibility = ' hide'; // Not needed

    let statsTabStyle = ' ';
    let devicesTabStyle = ' ';
    let usersTabStyle = ' ';
    let clientsTabStyle = ' ';
    let patientsTabStyle = ' ';
    let patientsTimeZoneTabStyle = ' ';
    let buttonsTabStyle = ' ';
    let bioDevicesTabStyle = ' ';
    let scheduleTabStyle = ' ';
    let externalAppsTabStyle = ' ';

    let scheduleVisibility = this.state.accountEmergencySchedule || this.state.accountCallSchedule ? ' ' : ' hide';

    if (GeneralStore.getClientTab() == 1) {
      statsContentVisibility = ' ';
      statsTabStyle = ' staffTab';
    } else if (GeneralStore.getClientTab() == 2) {
      devicesContentVisibility = ' ';
      devicesTabStyle = ' staffTab';
    } else if (GeneralStore.getClientTab() == 3) {
      usersContentVisibility = ' ';
      usersTabStyle = ' staffTab';
    } else if (GeneralStore.getClientTab() == 4) {
      clientsContentVisibility = ' ';
      clientsTabStyle = ' staffTab';
    } else if (GeneralStore.getClientTab() == 5) {
      buttonsContentVisibility = ' ';
      buttonsTabStyle = ' staffTab';
    } else if (GeneralStore.getClientTab() == 6) {
      bioDevicesContentVisibility = ' ';
      bioDevicesTabStyle = ' staffTab';
    } else if (GeneralStore.getClientTab() == 7) {
      scheduleContentVisibility = ' ';
      scheduleTabStyle = ' staffTab';
    } else if (GeneralStore.getClientTab() == 8) {
      patientsContentVisibility = ' ';
      patientsTabStyle = ' staffTab';
    } else if (GeneralStore.getClientTab() == 9) {
      patientsTimeZoneContentVisibility = ' ';
      patientsTimeZoneTabStyle = ' staffTab';
    } else if (GeneralStore.getClientTab() == 10) {
      externalAppsContentVisibility = ' ';
      externalAppsTabStyle = ' staffTab';
    }

    let enableCopyToClipBoardBtn = this.state.accountApiToken ? '' : 'disabled';
    let stateApiUsageLimit = this.state.accountApiUsageLimit;
    let unblockApiBtn = stateApiUsageLimit > 5 ? '' : 'disabled';
    let unblockLabel = stateApiUsageLimit > 5 ? 'API blocked due to miss use' : '';

    let salesforceValidConfiguration = '';
    if (
      this.state.salesforceOrgUrl == null ||
      this.state.salesforceLoginUrl == null ||
      this.state.salesforceClientID == null ||
      this.state.salesforceClientSecret == null ||
      this.state.salesforceUserName == null ||
      this.state.salesforcePassword == null ||
      this.state.salesforceRedirectUri == null
    ) {
      salesforceValidConfiguration = 'disabled';
    }

    const availableTabs: AccountConfigTabItem[] = [
      { name: AccountConfigTab.AccountSettings },
      { name: AccountConfigTab.AccountStrings },
      { name: AccountConfigTab.AccountFeatures },
      { name: AccountConfigTab.DeviceSettings },
      { name: AccountConfigTab.AlertsSettings },
      { name: AccountConfigTab.ExternalIntegrations },
      { name: AccountConfigTab.HipaaCompliance },
      { name: AccountConfigTab.Hexnode },
      { name: AccountConfigTab.Salesforce },
      { name: AccountConfigTab.ClassSession },
      { name: AccountConfigTab.Apps },
      { name: AccountConfigTab.LinkButton },
      { name: AccountConfigTab.SchedulingTemplate },
      { name: AccountConfigTab.NewThemeSettings },
    ];
    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">
            Account: {this.state.accountName} &nbsp;&nbsp;{' '}
            <span className="nucleus-labels-small nucleus-link-red" onClick={this.handleDeleteAccount}>
              Delete
            </span>
          </p>

          <AccountTabsHeader
            tabs={availableTabs}
            onSelectedTab={(tabItem: AccountConfigTabItem) => {
              switch (tabItem.name) {
                case 'Account Settings':
                  this.handleAccountSettingsTab();
                  break;
                case 'Account Strings':
                  this.handleAccountStringsTab();
                  break;
                case 'Account Features':
                  this.handleAccountFeaturesTab();
                  break;
                case 'Device Settings':
                  this.handleDeviceSettingsTab();
                  break;
                case 'Alerts Settings':
                  this.handleAlertsSettingsTab();
                  break;
                case 'External Integrations':
                  this.handleExternalSettingsTab();
                  break;
                case 'HIPAA Compliance':
                  this.handleHipaaComplianceSettingsTab();
                  break;
                case 'Hexnode':
                  this.handleHexnodeSettingsTab();
                  break;
                case 'Salesforce':
                  this.handleSalesforceSettingsTab();
                  break;
                case 'Class Session':
                  this.handleClassroomSettingsTab();
                  break;
                case 'Apps':
                  this.handleAppsSettingsTab();
                  break;
                case 'Link Button':
                  this.handleLinkSettingsTab();
                  break;
                case 'Scheduling Template':
                  this.handleSchedulingSettingsTab();
                  break;
                case AccountConfigTab.NewThemeSettings:
                  this.handleNewThemeSettingsTab();
                  break;
              }
            }}
          />

          <div className="nucleus-news-separator">&nbsp;</div>

          <div className="row ">
            {this.state.accountConfigSelectedTab === AccountConfigTab.AccountSettings && <AccountSettings accountId={this.state.accountID} match={this.props.match} />}

            {this.state.accountConfigSelectedTab === AccountConfigTab.AccountStrings && <AccountStrings accountId={this.state.accountID} />}

            {this.state.accountConfigSelectedTab === AccountConfigTab.AccountFeatures && <AccountFeatures accountId={this.state.accountID} />}

            {this.state.accountConfigSelectedTab === AccountConfigTab.DeviceSettings && (
              <div className={'row '}>
                <div className={'row no-margin'}>
                  <div className="col s4 m4 l4 xl4 no-margin bordered">
                    <div className="row vbottom-align no-margin top-separated">
                      <div className="col s12 no-margin">
                        <span className="nucleus-labels-bold-height"> Select theme </span>
                      </div>
                      <div className="col s12 no-margin">
                        <NucleusConfigSelect
                          selectId={'accountThemeSelect'}
                          selectOptions={[
                            { name: 'Default', value: 'V1', disabled: false },
                            { name: 'New *', value: 'V2', disabled: false },
                            { name: 'With *New* theme selected, device layout options will no longer be available', value: '', disabled: true },
                          ]}
                          selectedValue={this.state.deviceUxVersion}
                          //defaultValue={"Select..."}
                          handleSelectChange={this.handleAccountThemeVersion}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col s4 m4 l4 xl4 no-margin bordered">
                  <div style={{ display: this.state.deviceUxVersion != 'V2' ? 'grid' : 'none' }}>
                    <div className="row vbottom-align no-margin top-separated">
                      <div className="col s12 no-margin">
                        <span className="nucleus-labels-bold-height"> Device Layout Settings </span>
                      </div>
                    </div>

                    <div className="row vbottom-align no-margin">
                      <div className="col s10 no-margin">
                        <span className="nucleus-labels-small"> Device Main Screen Template </span>
                      </div>
                      <div className="col s2 no-margin"></div>
                    </div>
                    <div className="row vbottom-align no-margin">
                      <div className="col s3 no-margin nucleus-link-light" onClick={this.handleChangeAccountTemplate}>
                        <span className="nucleus-labels-small nucleus-font-13">Click to change template</span>
                      </div>
                      <div className="col s4 no-margin nucleus-link-light" onClick={this.handleChangeAccountTemplate}>
                        &nbsp;
                        <img className="responsive-img" src={this.state.accountTemplateImageUrl} width="100" />
                      </div>
                      <div className="col s5 no-margin">
                        <span className="nucleus-labels-small nucleus-font-13">{this.state.accountTemplateName}</span>
                      </div>
                    </div>
                    <div className="row vbottom-align no-margin top-separated">
                      <div className="col s12 no-margin">
                        <span className="nucleus-labels-bold-height">&nbsp;</span>
                      </div>
                    </div>

                    <div className="row vbottom-align no-margin">
                      <div className="col s10 no-margin">
                        <span className="nucleus-labels-small"> Background Image File</span>
                      </div>
                      <div className="col s2 no-margin">
                        <span>
                          <input
                            ref="checkBgImageEnabled"
                            type="checkbox"
                            className="filled-in"
                            id="checkBgImageEnabled"
                            defaultChecked={bgImageEnabledValue}
                            onChange={this.handleEnableBackgroundImage}
                          />
                          <label htmlFor="checkBgImageEnabled">&nbsp;</label>
                        </span>
                      </div>
                    </div>
                    <div className="row vbottom-align no-margin">
                      <div className="col s3 no-margin nucleus-link-light" onClick={this.handleChangeBackgroundImage}>
                        <span className="nucleus-labels-small nucleus-font-13">Click to change background image</span>
                        <input
                          id="selectFile1"
                          ref="selectFile1"
                          type="file"
                          multiple={false}
                          onChange={this.selectedBackgroundImageFile}
                          accept={'image/png, image/jpeg'}
                          style={{ display: 'none' }}
                        ></input>
                      </div>
                      <div className="col s4 no-margin nucleus-link-light" onClick={this.handleChangeBackgroundImage}>
                        &nbsp;
                        <img className="responsive-img" src={this.state.accountBackgroundImageUrl} width="100" />
                      </div>
                      <div className="col s2 no-margin">
                        <span className="nucleus-labels-small nucleus-font-13">{'Required Resolution 1280 x 800'}</span>
                      </div>
                      <div className="col s3 no-margin"></div>
                    </div>

                    <div className="row vbottom-align no-margin top-separated">
                      <div className="col s12 no-margin">
                        <span className="nucleus-labels-bold-height">{this.state.uploadingBgImageStatus} &nbsp; </span>
                      </div>
                    </div>

                    <div className="row vbottom-align no-margin">
                      <div className="col s10 no-margin">
                        <span className="nucleus-labels-small"> Device Logo Image File</span>
                      </div>
                      <div className="col s2 no-margin">
                        <span>
                          <input
                            ref="checkBgLogoEnabled"
                            type="checkbox"
                            className="filled-in"
                            id="checkBgLogoEnabled"
                            defaultChecked={bgLogoEnabledValue}
                            onChange={this.handleEnableBackgroundLogo}
                          />
                          <label htmlFor="checkBgLogoEnabled">&nbsp;</label>
                        </span>
                      </div>
                    </div>
                    <div className="row vbottom-align no-margin">
                      <div className="col s3 no-margin nucleus-link-light" onClick={this.handleChangeBackgroundLogo}>
                        <span className="nucleus-labels-small nucleus-font-13">Click to change background logo image</span>
                        <input
                          id="selectFile2"
                          ref="selectFile2"
                          type="file"
                          multiple={false}
                          onChange={this.selectedBackgroundLogoImageFile}
                          accept={'image/png, image/jpeg'}
                          style={{ display: 'none' }}
                        ></input>
                      </div>
                      <div className="col s4 no-margin nucleus-link-light" onClick={this.handleChangeBackgroundLogo}>
                        &nbsp;
                        <img className="responsive-img" src={this.state.accountBackgroundLogoUrl} width="50" />
                      </div>
                      <div className="col s2 no-margin">
                        <span className="nucleus-labels-small nucleus-font-13">{'Required Resolution 500 x 250'}</span>
                      </div>
                      <div className="col s3 no-margin"></div>
                    </div>

                    <div className="row vbottom-align no-margin top-separated">
                      <div className="col s12 no-margin">
                        <span className="nucleus-labels-bold-height">{this.state.uploadingBgLogoStatus}&nbsp; </span>
                      </div>
                    </div>
                  </div>

                  <br />
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-bold-height">Bulletin Board Settings </span>
                    </div>
                  </div>

                  {this.state.accountBulletinButtons > 0 && (
                    <div className="row vbottom-align no-margin bit-top-separated ">
                      <div className="col s1 no-margin a-little-right">
                        <span>
                          <input
                            ref={this.checkBulletinButton1}
                            type="checkbox"
                            className="filled-in"
                            id="checkBulletinButton1"
                            onChange={this.handleEnableBulletinButton.bind(this, 0)}
                          />
                          <label htmlFor="checkBulletinButton1">&nbsp;</label>
                        </span>
                      </div>
                      <div className="col s5 no-margin">
                        <span className="nucleus-labels"> Bulletin Board 1 </span>
                      </div>
                      <div className="col s4 no-margin">
                        <input ref={this.txtButton1Title} type="text" className="validate nucleus-input-form txt-1-4" placeholder="Name" />
                      </div>
                      <div className="col s2 no-margin">
                        <a
                          className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
                          onClick={this.handleUpdateBulletinButtonName.bind(this, 0)}
                        >
                          <i className="material-icons nucleus-floating-btn-icon">save</i>
                        </a>
                      </div>
                    </div>
                  )}

                  {this.state.accountBulletinButtons > 1 && (
                    <div className="row vbottom-align no-margin bit-top-separated ">
                      <div className="col s1 no-margin a-little-right">
                        <span>
                          <input
                            ref={this.checkBulletinButton2}
                            type="checkbox"
                            className="filled-in"
                            id="checkBulletinButton2"
                            onChange={this.handleEnableBulletinButton.bind(this, 1)}
                          />
                          <label htmlFor="checkBulletinButton2">&nbsp;</label>
                        </span>
                      </div>
                      <div className="col s5 no-margin">
                        <span className="nucleus-labels"> Bulletin Board 2 </span>
                      </div>
                      <div className="col s4 no-margin">
                        <input ref={this.txtButton2Title} type="text" className="validate nucleus-input-form txt-1-4" placeholder="Name" />
                      </div>
                      <div className="col s2 no-margin">
                        <a
                          className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
                          onClick={this.handleUpdateBulletinButtonName.bind(this, 1)}
                        >
                          <i className="material-icons nucleus-floating-btn-icon">save</i>
                        </a>
                      </div>
                    </div>
                  )}

                  {this.state.accountBulletinButtons > 2 && (
                    <div className="row vbottom-align no-margin bit-top-separated ">
                      <div className="col s1 no-margin a-little-right">
                        <span>
                          <input
                            ref={this.checkBulletinButton3}
                            type="checkbox"
                            className="filled-in"
                            id="checkBulletinButton3"
                            onChange={this.handleEnableBulletinButton.bind(this, 2)}
                          />
                          <label htmlFor="checkBulletinButton3">&nbsp;</label>
                        </span>
                      </div>
                      <div className="col s5 no-margin">
                        <span className="nucleus-labels"> Bulletin Board 3 </span>
                      </div>
                      <div className="col s4 no-margin">
                        <input ref={this.txtButton3Title} type="text" className="validate nucleus-input-form txt-1-4" placeholder="Name" />
                      </div>
                      <div className="col s2 no-margin">
                        <a
                          className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
                          onClick={this.handleUpdateBulletinButtonName.bind(this, 2)}
                        >
                          <i className="material-icons nucleus-floating-btn-icon">save</i>
                        </a>
                      </div>
                    </div>
                  )}

                  {this.state.accountBulletinButtons > 3 && (
                    <div className="row vbottom-align no-margin bit-top-separated ">
                      <div className="col s1 no-margin a-little-right">
                        <span>
                          <input
                            ref={this.checkBulletinButton4}
                            type="checkbox"
                            className="filled-in"
                            id="checkBulletinButton4"
                            onChange={this.handleEnableBulletinButton.bind(this, 3)}
                          />
                          <label htmlFor="checkBulletinButton4">&nbsp;</label>
                        </span>
                      </div>
                      <div className="col s5 no-margin">
                        <span className="nucleus-labels"> Bulletin Board 4 </span>
                      </div>
                      <div className="col s4 no-margin">
                        <input ref={this.txtButton4Title} type="text" className="validate nucleus-input-form txt-1-4" placeholder="Name" />
                      </div>
                      <div className="col s2 no-margin">
                        <a
                          className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
                          onClick={this.handleUpdateBulletinButtonName.bind(this, 3)}
                        >
                          <i className="material-icons nucleus-floating-btn-icon">save</i>
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                {/*  - - - - - - - -  Middle - - - - - - - - */}
                <div className="col s4 m4 l4 xl4 no-margin">
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-bold-height"> Call Settings </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Enable Auto Answer </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input ref="checkAutoAnswer" type="checkbox" className="filled-in" id="checkAutoAnswer" defaultChecked={autoAnswerValue} onChange={this.handleAutoAnswer} />
                        <label htmlFor="checkAutoAnswer">&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Show Call Statistics on the player </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkShowStats"
                          type="checkbox"
                          className="filled-in"
                          id={'checkShowStats' + this.state.accountID}
                          defaultChecked={showCallStatsValue}
                          onChange={this.handleShowStats}
                        />
                        <label htmlFor={'checkShowStats' + this.state.accountID}>&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Show In-Call Control Bar </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkShowCallControls"
                          type="checkbox"
                          className="filled-in"
                          id={'checkShowCallControls' + this.state.accountID}
                          defaultChecked={showCallControlsValue}
                          onChange={this.handleShowCallControls}
                        />
                        <label htmlFor={'checkShowCallControls' + this.state.accountID}>&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Force Landscape Video </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkForceLandscapeVideo"
                          type="checkbox"
                          className="filled-in"
                          id="checkForceLandscapeVideo"
                          defaultChecked={forceLandscapeVideoValue}
                          onChange={this.handleForceLandscapeVideo}
                        />
                        <label htmlFor="checkForceLandscapeVideo">&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Show Call Request Button 1 (Default) </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkShowCallButton"
                          type="checkbox"
                          className="filled-in"
                          id={'checkCall' + this.state.accountID}
                          defaultChecked={showCallValue}
                          onChange={this.handleShowCallButton}
                        />
                        <label htmlFor={'checkCall' + this.state.accountID}>&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Show Call Request Button 2 (Telehelp) </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkShowCallButton2"
                          type="checkbox"
                          className="filled-in"
                          id="checkCallButton2"
                          defaultChecked={showCallValue2}
                          onChange={this.handleShowCallButton2}
                        />
                        <label htmlFor="checkCallButton2">&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Show Emergency Call Button </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkShowEmergencyButton"
                          type="checkbox"
                          className="filled-in"
                          id={'checkEmergency' + this.state.accountID}
                          defaultChecked={showEmergencyValue}
                          onChange={this.handleShowEmergencyButton}
                        />
                        <label htmlFor={'checkEmergency' + this.state.accountID}>&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Show Memory Box Button</span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkEnableMemoryBox"
                          type="checkbox"
                          className="filled-in"
                          id="checkMemoryBox"
                          defaultChecked={enableMemoryBoxValue}
                          onChange={this.handleEnableMemoryBox}
                        />
                        <label htmlFor="checkMemoryBox">&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Show Family Members Button</span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkShowFamilyCallBtn"
                          type="checkbox"
                          className="filled-in"
                          id="checkShowFamilyCallBtn"
                          defaultChecked={showFamilyCallBtnValue}
                          onChange={this.handleShowFamilyCallBtn}
                        />
                        <label htmlFor="checkShowFamilyCallBtn">&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Show Check-In Button </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkShowCheckInButton"
                          type="checkbox"
                          className="filled-in"
                          id={'checkCheckIn' + this.state.accountID}
                          defaultChecked={showCheckInValue}
                          onChange={this.handleShowCheckInButton}
                        />
                        <label htmlFor={'checkCheckIn' + this.state.accountID}>&nbsp;</label>
                      </span>
                      <span className="nucleus-labels-special txt-red">*</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Enable Call Button Schedule </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkCallSchedule"
                          type="checkbox"
                          className="filled-in"
                          id="checkCallSchedule"
                          defaultChecked={callScheduleValue}
                          onChange={this.handleCallSchedule}
                        />
                        <label htmlFor="checkCallSchedule">&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Enable Emergency Button Schedule </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkEmergencySchedule"
                          type="checkbox"
                          className="filled-in"
                          id="checkEmergencySchedule"
                          defaultChecked={emergencyScheduleValue}
                          onChange={this.handleEmergencySchedule}
                        />
                        <label htmlFor="checkEmergencySchedule">&nbsp;</label>
                      </span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Audio Calls Only </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkAudioCallsOnly"
                          type="checkbox"
                          className="filled-in"
                          id="checkAudioCallsOnly"
                          defaultChecked={audioCallsOnlyValue}
                          onChange={this.handleAudioCallsOnly}
                        />
                        <label htmlFor="checkAudioCallsOnly">&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <AccountConfigCheckBoxLine 
                    id="powerDisconnectedWarnings" 
                    state={this.state.powerDisconnectedWarningEnabled} 
                    label={'Enable Power Disconnected warnings'} 
                    onChangeAction={this.handlePowerDisconnectedWarningEnabled} 
                  />

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Enable Intercom Button </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkIntercomButtonEnabled"
                          type="checkbox"
                          className="filled-in"
                          id="checkIntercomButtonEnabled"
                          defaultChecked={intercomButtonEnabled}
                          onChange={this.handleIntercomButton}
                        />
                        <label htmlFor="checkIntercomButtonEnabled">&nbsp;</label>
                      </span>
                    </div>
                  </div>
                </div>

                {/*  - - - - - - - -  Right - - - - - - - - */}
                <div className="col s4 m4 l4 xl4 no-margin">
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-bold-height"> General Device Settings </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated ">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels"> Screen Saver Delay (Seconds) </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        ref="txtScreenSaverDelay"
                        type="text"
                        className="validate nucleus-input-form accountTextBitShort"
                        defaultValue={this.state.screenSaverDelay}
                        placeholder="Seconds"
                      />
                    </div>
                    <div className="col s2 no-margin">
                      <a className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left" onClick={this.handleUpdateScreenSaverDelay}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </a>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated ">
                    <div className="col s10 no-margin" key={this.state.accountSettingsPin}>
                      <span className="nucleus-labels"> Device Settings PIN Code </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        ref="txtSettingsPinCode"
                        type="text"
                        className="validate nucleus-input-form accountTextBitShort"
                        defaultValue={this.state.accountSettingsPin}
                        placeholder="0000"
                      />
                    </div>
                    <div className="col s2 no-margin">
                      <a className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left" onClick={this.handleUpdateSettingsPin}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </a>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Checked-In Screen Stays on </span>
                    </div>
                    <div className="col s2 no-margin ">
                      <span>
                        <input
                          ref="checkCheckedInScreen"
                          type="checkbox"
                          className="filled-in"
                          id="checkCheckedInScreen"
                          defaultChecked={enableCheckedInScreenStaysOn}
                          onChange={this.handleUpdateCheckedInScreenStaysOn}
                        />
                        <label htmlFor="checkCheckedInScreen">&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated-12">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Restart When Device Goes Offline</span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkRestartOfflineDevice"
                          type="checkbox"
                          className="filled-in"
                          id={'checkRestartOfflineDevice' + this.state.accountID}
                          defaultChecked={restartDeviceOfflineEnabledValue}
                          onChange={this.handleRestartDeviceOffline}
                        />
                        <label htmlFor={'checkRestartOfflineDevice' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels"> Time span to restart when Device goes Offline (Minutes): </span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin ">
                    <div className="col s10 no-margin" key={this.state.restartDeviceOfflineTimeSpan}>
                      <input
                        ref="txtRestartDeviceOfflineTimeSpan"
                        type="text"
                        className="validate nucleus-input-form accountTextFull"
                        disabled={!!this.state.btnRestartDeviceOfflineTimeSpanStyle}
                        defaultValue={this.state.restartDeviceOfflineTimeSpan}
                        placeholder="15"
                      />
                    </div>
                    <div className="col s2 no-margin ">
                      <a
                        className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
                        onClick={this.handleUpdateRestartDeviceOfflineTimeSpan}
                      >
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </a>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated-12">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Show Battery Status</span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkShowBatteryStatus"
                          type="checkbox"
                          className="filled-in"
                          id={'checkShowBatteryStatus' + this.state.accountID}
                          defaultChecked={showBatteryStatusValue}
                          onChange={this.handleShowBatteryStatus}
                        />
                        <label htmlFor={'checkShowBatteryStatus' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <br />
                  <br />
                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-bold-height"> Device Dim Settings </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated ">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels"> Start Dim (Time) </span>
                      <div className={'nucleus-select-wrapper'}>
                        <select
                          id="startDimTime"
                          ref="startDimTime"
                          //className="validate nucleus-input-form dim-input-size much-margin-left"
                          className="browser-default accountSelect"
                          //defaultValue={this.state.dimFrom}
                          onChange={this.handleChangeDimStartTime}
                          onfocus="this.size=10;"
                          onblur="this.size=1;"
                        >
                          {this.timePickerOptions.map((option, index) => {
                            if (option == this.state.dimFrom) {
                              return (
                                <option key={index} value={option} selected>
                                  {option}
                                </option>
                              );
                            } else {
                              return (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col s2 no-margin"></div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated ">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels"> End Dim (Time) </span> &nbsp;&nbsp;
                      <div className={'nucleus-select-wrapper'}>
                        <select
                          id="endDimTime"
                          ref="endDimTime"
                          //className="validate nucleus-input-form dim-input-size much-margin-left"
                          className="browser-default accountSelect"
                          //defaultValue={this.state.dimTo}
                          onChange={this.handleChangeDimEndTime}
                          onfocus="this.size=10;"
                          onblur="this.size=1;"
                        >
                          {this.timePickerOptions.map((option, index) => {
                            if (option == this.state.dimTo) {
                              return (
                                <option key={index} value={option} selected>
                                  {option}
                                </option>
                              );
                            } else {
                              return (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col s2 no-margin"></div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated ">
                    <div className="col s10 no-margin" key={this.state.dimOffBrightness}>
                      <span className="nucleus-labels"> Dim Off (Brightness level) </span>
                      <input
                        ref="txtDimOffBrightness"
                        type="text"
                        className="validate nucleus-input-form dim-input-size much-margin-left"
                        defaultValue={this.state.dimOffBrightness}
                        placeholder="255"
                      />
                    </div>
                    <div className="col s2 no-margin">
                      <a className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left" onClick={this.handleUpdateDimOffBrightness}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </a>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated ">
                    <div className="col s10 no-margin" key={this.state.dimOnBrightness}>
                      <span className="nucleus-labels"> Dim On (Brightness level) </span> &nbsp;
                      <input
                        ref="txtDimOnBrightness"
                        type="text"
                        className="validate nucleus-input-form dim-input-size much-margin-left"
                        defaultValue={this.state.dimOnBrightness}
                        placeholder="0"
                      />
                    </div>
                    <div className="col s2 no-margin">
                      <a className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left" onClick={this.handleUpdateDimOnBrightness}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col s12">
                  <span className="nucleus-labels-small txt-red">
                    <span className="nucleus-font-13">
                      *Warning: Once you disable this configuration on the Account level, it will be disabled on all the existing patients and will NOT be enabled once you enabled
                      it on the account level, and you will need to go each patient and enable it manually.
                    </span>
                  </span>
                </div>
              </div>
            )}

            {this.state.accountConfigSelectedTab === AccountConfigTab.AlertsSettings && (
              <div className={'row '}>
                <div className="col s8 m8 l8 xl8 no-margin padding-left-10">
                  <div className="row vbottom-align-not-height no-margin bit-top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-bold"> Alert Type </span>
                    </div>
                    <div className="col s2 no-margin text-center-align">
                      <span className="nucleus-labels-bold margin-right"> Enable Alert </span>
                    </div>
                    <div className="col s2 text-center-align">
                      <span className="nucleus-labels-bold margin-right"> Text Message </span>
                    </div>
                    <div className="col s2 no-margin text-center-align">
                      <span className="nucleus-labels-bold margin-right"> Email </span>
                    </div>
                  </div>

                  <br></br>
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-small no-margin"> Device went offline</span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkNotifyDeviceOff"
                          type="checkbox"
                          className="filled-in"
                          id={'checkNotifyDeviceOff' + this.state.accountID}
                          defaultChecked={deviceOfflineEnabled}
                          onChange={this.handleDeviceOfflineEnable}
                        />
                        <label htmlFor={'checkNotifyDeviceOff' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkDeviceOffSms"
                          type="checkbox"
                          className="filled-in"
                          id={'checkDeviceOffSms' + this.state.accountID}
                          defaultChecked={deviceOfflineSMS}
                          onChange={this.handleDeviceOfflineSMS}
                          disabled={true}
                        />
                        <label htmlFor={'checkDeviceOffSms' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkDeviceOffEmail"
                          type="checkbox"
                          className="filled-in"
                          id={'checkDeviceOffEmail' + this.state.accountID}
                          defaultChecked={deviceOfflineEmail}
                          onChange={this.handleDeviceOfflineEmail}
                          disabled={deviceOfflineCheckBtnDisabled}
                        />
                        <label htmlFor={'checkDeviceOffEmail' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-small no-margin"> Calls not answered by escalation time</span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span key={callNotAnsweredEnabled}>
                        &nbsp;
                        <input
                          ref="checkCallNotAnsweredEnabled"
                          type="checkbox"
                          className="filled-in"
                          id={'checkCallNotAnsweredEnabled' + this.state.accountID}
                          defaultChecked={callNotAnsweredEnabled}
                          onChange={this.handleCallNotAnsweredEnable}
                        />
                        <label htmlFor={'checkCallNotAnsweredEnabled' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span key={callNotAnsweredSMS}>
                        &nbsp;
                        <input
                          ref="checkCallNotAnsweredSMS"
                          type="checkbox"
                          className="filled-in"
                          id={'checkCallNotAnsweredSMS' + this.state.accountID}
                          defaultChecked={callNotAnsweredSMS}
                          onChange={this.handleCallNotAnsweredSMS}
                          disabled={true}
                          //disabled={this.state.callNotAnsweredCheckBtnDisabled}
                        />
                        <label htmlFor={'checkCallNotAnsweredSMS' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkCallNotAnsweredEmail"
                          type="checkbox"
                          className="filled-in"
                          id={'checkCallNotAnsweredEmail' + this.state.accountID}
                          defaultChecked={callNotAnsweredEmail}
                          onChange={this.handleCallNotAnsweredEmail}
                          disabled={callNotAnsweredCheckBtnDisabled}
                        />
                        <label htmlFor={'checkCallNotAnsweredEmail' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-small no-margin"> Calls not established</span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span key={callNotEstablishedEnabled}>
                        &nbsp;
                        <input
                          ref="checkCallNotEstablishedEnabled"
                          type="checkbox"
                          className="filled-in"
                          id={'checkCallNotEstablishedEnabled' + this.state.accountID}
                          defaultChecked={callNotEstablishedEnabled}
                          onChange={this.handleCallNotEstablishedEnabled}
                        />
                        <label htmlFor={'checkCallNotEstablishedEnabled' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkCallNotEstablishedSMS"
                          type="checkbox"
                          className="filled-in"
                          id={'checkCallNotEstablishedSMS' + this.state.accountID}
                          defaultChecked={callNotEstablishedSMS}
                          onChange={this.handleCallNotEstablishedSMS}
                          disabled={true}
                        />
                        <label htmlFor={'checkCallNotEstablishedSMS' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkCallNotEstablishedEmail"
                          type="checkbox"
                          className="filled-in"
                          id={'checkCallNotEstablishedEmail' + this.state.accountID}
                          defaultChecked={callNotEstablishedEmail}
                          onChange={this.handleCallNotEstablishedEmail}
                          disabled={callNotEstablishedCheckBtnDisabled}
                        />
                        <label htmlFor={'checkCallNotEstablishedEmail' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-small no-margin"> Message alerts</span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkMessageAlertEnable"
                          type="checkbox"
                          className="filled-in"
                          id={'checkMessageAlertEnable' + this.state.accountID}
                          defaultChecked={messageAlertEnable}
                          onChange={this.handleMessageAlertEnable}
                        />
                        <label htmlFor={'checkMessageAlertEnable' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkMessageAlertSMS"
                          type="checkbox"
                          className="filled-in"
                          id={'checkMessageAlertSMS' + this.state.accountID}
                          defaultChecked={messageAlertSMS}
                          onChange={this.handleMessageAlertSMS}
                          disabled={true}
                        />
                        <label htmlFor={'checkMessageAlertSMS' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkMessageAlertEmail"
                          type="checkbox"
                          className="filled-in"
                          id={'checkMessageAlertEmail' + this.state.accountID}
                          defaultChecked={messageAlertEmail}
                          onChange={this.handleMessageAlertEmail}
                          disabled={messageAlertCheckBtnDisabled}
                        />
                        <label htmlFor={'checkMessageAlertEmail' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-small no-margin"> Biometric alerts</span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkBiometricAlertEnable"
                          type="checkbox"
                          className="filled-in"
                          id={'checkBiometricAlertEnable' + this.state.accountID}
                          defaultChecked={biometricAlertEnable}
                          onChange={this.handleBiometricAlertEnable}
                        />
                        <label htmlFor={'checkBiometricAlertEnable' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkBiometricAlertSMS"
                          type="checkbox"
                          className="filled-in"
                          id={'checkBiometricAlertSMS' + this.state.accountID}
                          defaultChecked={biometricAlertSMS}
                          onChange={this.handleBiometricAlertSMS}
                          disabled={true}
                        />
                        <label htmlFor={'checkBiometricAlertSMS' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkBiometricAlertEmail"
                          type="checkbox"
                          className="filled-in"
                          id={'checkBiometricAlertEmail' + this.state.accountID}
                          defaultChecked={biometricAlertEmail}
                          onChange={this.handleBiometricAlertEmail}
                          disabled={biometricAlertCheckBtnDisabled}
                        />
                        <label htmlFor={'checkBiometricAlertEmail' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-small no-margin"> Battery alerts</span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkBatteryAlertEnable"
                          type="checkbox"
                          className="filled-in"
                          id={'checkBatteryAlertEnable' + this.state.accountID}
                          defaultChecked={batteryAlertEnable}
                          onChange={this.handleBatteryAlertEnable}
                        />
                        <label htmlFor={'checkBatteryAlertEnable' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkBatteryAlertSMS"
                          type="checkbox"
                          className="filled-in"
                          id={'checkBatteryAlertSMS' + this.state.accountID}
                          defaultChecked={batteryAlertSMS}
                          onChange={this.handleBatteryAlertSMS}
                          disabled={true}
                        />
                        <label htmlFor={'checkBatteryAlertSMS' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span key={batteryAlertEmail}>
                        &nbsp;
                        <input
                          ref="checkBatteryAlertEmail"
                          type="checkbox"
                          className="filled-in"
                          id={'checkBatteryAlertEmail' + this.state.accountID}
                          defaultChecked={batteryAlertEmail}
                          onChange={this.handleBatteryAlertEmail}
                          disabled={batteryAlertCheckBtnDisabled}
                        />
                        <label htmlFor={'checkBatteryAlertEmail' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-small no-margin"> Miscellaneous alerts</span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkMiscAlertEnable"
                          type="checkbox"
                          className="filled-in"
                          id={'checkMiscAlertEnable' + this.state.accountID}
                          defaultChecked={miscAlertEnable}
                          onChange={this.handleMiscAlertEnable}
                        />
                        <label htmlFor={'checkMiscAlertEnable' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span key={miscAlertSMS}>
                        &nbsp;
                        <input
                          ref="checkMiscAlertSMS"
                          type="checkbox"
                          className="filled-in"
                          id={'checkMiscAlertSMS' + this.state.accountID}
                          defaultChecked={miscAlertSMS}
                          onChange={this.handleMiscAlertSMS}
                          disabled={true}
                        />
                        <label htmlFor={'checkMiscAlertSMS' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span key={miscAlertEmail}>
                        &nbsp;
                        <input
                          ref="checkMiscAlertEmail"
                          type="checkbox"
                          className="filled-in"
                          id={'checkMiscAlertEmail' + this.state.accountID}
                          defaultChecked={miscAlertEmail}
                          onChange={this.handleMiscAlertEmail}
                          disabled={miscAlertCheckBtnDisabled}
                        />
                        <label htmlFor={'checkMiscAlertEmail' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-small no-margin"> Home environment alerts</span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkHomeAlertEnable"
                          type="checkbox"
                          className="filled-in"
                          id={'checkHomeAlertEnable' + this.state.accountID}
                          defaultChecked={homeAlertEnable}
                          onChange={this.handleHomeAlertEnable}
                        />
                        <label htmlFor={'checkHomeAlertEnable' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span key={homeAlertSMS}>
                        &nbsp;
                        <input
                          ref="checkHomeAlertSMS"
                          type="checkbox"
                          className="filled-in"
                          id={'checkHomeAlertSMS' + this.state.accountID}
                          defaultChecked={homeAlertSMS}
                          onChange={this.handleHomeAlertSMS}
                          disabled={true}
                        />
                        <label htmlFor={'checkHomeAlertSMS' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkHomeAlertEmail"
                          type="checkbox"
                          className="filled-in"
                          id={'checkHomeAlertEmail' + this.state.accountID}
                          defaultChecked={homeAlertEmail}
                          onChange={this.handleHomeAlertEmail}
                          disabled={homeAlertCheckBtnDisabled}
                        />
                        <label htmlFor={'checkHomeAlertEmail' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s5 no-margin">
                      <span className="nucleus-labels-small no-margin"> BLE devices alerts</span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span>
                        &nbsp;
                        <input
                          ref="checkBleDevicesAlertEnable"
                          type="checkbox"
                          className="filled-in"
                          id={'checkBleDevicesAlertEnable' + this.state.accountID}
                          defaultChecked={bleDevicesAlertEnable}
                          onChange={this.handleBleDevicesAlertEnable}
                        />
                        <label htmlFor={'checkBleDevicesAlertEnable' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span key={bleDevicesAlertSMS}>
                        &nbsp;
                        <input
                          ref="checkBleDevicesAlertSMS"
                          type="checkbox"
                          className="filled-in"
                          id={'checkBleDevicesAlertSMS' + this.state.accountID}
                          defaultChecked={bleDevicesAlertSMS}
                          onChange={this.handleBleDevicesAlertSMS}
                          disabled={true}
                        />
                        <label htmlFor={'checkBleDevicesAlertSMS' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span key={bleDevicesAlertEmail}>
                        &nbsp;
                        <input
                          ref="checkBleDevicesAlertEmail"
                          type="checkbox"
                          className="filled-in"
                          id={'checkBleDevicesAlertEmail' + this.state.accountID}
                          defaultChecked={bleDevicesAlertEmail}
                          onChange={this.handleBleDevicesAlertEmail}
                          disabled={bleDevicesAlertCheckBtnDisabled}
                        />
                        <label htmlFor={'checkBleDevicesAlertEmail' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col s4 m4 l4 xl4 no-margin">
                  <br></br>
                  <br></br>
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s7 no-margin">
                      <span className="nucleus-labels-small"> Escalation Timeout (Minutes) </span>
                    </div>

                    <div className="col s2 margin-left no-margin-right" key={this.state.escalationTimeOut}>
                      <input
                        ref="txtEscalationTimeOut"
                        type="text"
                        className="validate nucleus-input-form-small accountTextFull2"
                        defaultValue={this.state.escalationTimeOut}
                        placeholder="Minutes"
                      />
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span
                        className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right"
                        onClick={this.handleUpdateEscalationTimeOut}
                      >
                        <i className="material-icons nucleus-floating-btn-icon"> save </i>
                      </span>
                    </div>
                  </div>

                  <div className="row no-margin ">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels"> Escalation Alert Email Address: </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin ">
                    <div className="col s9 no-margin" key={this.state.escalationEmailAddress}>
                      <input
                        ref="txtEscalationEmailAddress"
                        type="text"
                        className="validate nucleus-input-form accountTextFull3"
                        defaultValue={this.state.escalationEmailAddress}
                        placeholder="Enter Email Address"
                      />
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span
                        className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-few-right"
                        onClick={this.handleUpdateEscalationEmailAddress}
                      >
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>

                  <br></br>
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s7 no-margin">
                      <span className="nucleus-labels-small"> Offline Time Span (Minutes) </span>
                    </div>

                    <div className="col s2 margin-left no-margin-right" key={this.state.offlineTimeSpan}>
                      <input
                        ref="txtOfflineTimeSpan"
                        type="text"
                        className="validate nucleus-input-form-small accountTextFull2"
                        defaultValue={this.state.offlineTimeSpan}
                        placeholder="Minutes"
                      />
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span
                        className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right"
                        onClick={this.handleUpdateOfflineTimeSpan}
                      >
                        <i className="material-icons nucleus-floating-btn-icon"> save </i>
                      </span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s9 no-margin">
                      <span className="nucleus-labels-small"> Send message responses by e-mail </span>
                    </div>
                    <div className="col s2 no-margin center-align">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span>
                        <input
                          ref="checkEnableMessageResponse"
                          type="checkbox"
                          className="filled-in"
                          id="checkEnableMessageResponse"
                          defaultChecked={messageResponseByEmailValue}
                          onChange={this.handleEnableMessageResponse}
                        />
                        <label htmlFor="checkEnableMessageResponse">&nbsp;</label>
                      </span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels"> Message Response Email Address: </span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin ">
                    <div className="col s9 no-margin">
                      <input
                        ref="txtMessageResponseEmailAddress"
                        type="text"
                        className="validate nucleus-input-form accountTextFull"
                        defaultValue={this.state.sendMessageResponseEmail}
                        placeholder="Email Address"
                        disabled={this.state.sendMessageResponseMode}
                      />
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span
                        className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left"
                        onClick={this.handleUpdateMessageResponseEmailAddress}
                        disabled={this.state.sendMessageResponseMode}
                      >
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.accountConfigSelectedTab === AccountConfigTab.ExternalIntegrations && (
              <div className={'row '}>
                <div className="col s4 m4 l4 xl4 no-margin">
                  <div className="row vbottom-align no-margin">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Import Clients from CSV</span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        <input
                          ref="checkImportClients"
                          type="checkbox"
                          className="filled-in"
                          id={'checkImportClients'}
                          defaultChecked={importClientsValue}
                          onChange={this.handleEnableImportClients}
                        />
                        <label htmlFor={'checkImportClients'}></label>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col s4 m4 l4 xl4 no-margin">
                  <div className="row vbottom-align no-margin">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels-small"> Send to pagerduty</span>
                    </div>
                    <div className="col s2 no-margin">
                      <span>
                        &nbsp;
                        <input
                          ref="checkShowCallButton"
                          type="checkbox"
                          className="filled-in"
                          id={'checkPagerDuty' + this.state.accountID}
                          defaultChecked={pagerDutyValue}
                          onChange={this.handlePagerDuty}
                        />
                        <label htmlFor={'checkPagerDuty' + this.state.accountID}>&nbsp;</label>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin bit-top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels"> Pager Duty Email: </span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin ">
                    <div className="col s10 no-margin">
                      <input
                        ref="txtPagerDutyEmail"
                        type="text"
                        className="validate nucleus-input-form accountTextFull"
                        disabled={this.state.btnPagerDutyStyle}
                        defaultValue={this.state.accountPagerDutyEmail}
                        placeholder="Email"
                      />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdatePagerDutyEmail}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col s4 m4 l4 xl4 no-margin">
                  <div className="row no-margin">
                    <div className="col s12 no-margin">
                      <span className="nucleus-font-small nucleus-link nucleus-unblock-api-btn" onClick={this.onUnblockApiButtonModalAction} disabled={unblockApiBtn}>
                        Unblock API
                      </span>
                    </div>
                  </div>
                  <div className="row no-margin bit-top-separated-2">
                    <div className="col s10 no-margin" key={unblockLabel}>
                      <span className="nucleus-labels-small nucleus-font-13 txt-red">&nbsp;&nbsp;{unblockLabel}</span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated-12">
                    <div className="col s12 no-margin">
                      <span className="nucleus-font-small nucleus-link nucleus-token-btn" onClick={this.onGenerateApiTokenButtonModalAction}>
                        &nbsp;&nbsp;Generate API token&nbsp;&nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin ">
                    <div className="col s10 no-margin" key={this.state.accountApiToken} style={{ marginBottom: 30 }}>
                      <span className="nucleus-token-labels">Token: </span>
                      <span className="nucleus-token-labels" id="api-token-label">
                        {this.state.accountApiToken}
                      </span>
                    </div>
                    <div className="col s2 no-margin">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left" onClick={this.copyApiTokenToClipboard}>
                        <i className="material-icons nucleus-floating-copy-btn-icon">file_copy</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.accountConfigSelectedTab === AccountConfigTab.HipaaCompliance && (
              <div className={'row '}>
                <div className="col s4 m4 l4 xl4 no-margin">
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="row bit-top-separated">
                      <div className="col s9">
                        <span className=" nucleus-labels"> HIPAA Compliant</span>
                      </div>
                      <div className="col s2">
                        <div className="switch a-more-right">
                          <label>
                            <input type="checkbox" ref="checkIsHipaaCompliant" defaultChecked={isHipaaCompliant} onChange={this.handleIsHipaaCompliant} />
                            <span className="lever nucleus-check"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col s12 no-margin top-separated-20">
                    <div className="col s6 no-margin">
                      <span className="nucleus-labels-small a-more-right"> Auto - Logout (Minutes) </span>
                    </div>
                    <div className="col s3 margin-left no-margin-right" key={this.state.autoLogoutTime}>
                      <input
                        ref="txtAutoLogoutTimeout"
                        type="text"
                        className="validate nucleus-input-form-small accountTextFull2"
                        defaultValue={this.state.autoLogoutTime}
                        placeholder="Minutes"
                      />
                    </div>
                    <div className="col s2 no-margin center-align">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-margin-right" onClick={this.handleUpdateAutoLogout}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* HEXNODE CONFIG SECTION */}
            {this.state.accountConfigSelectedTab === AccountConfigTab.Hexnode && (
              <div className={'row'}>
                <div className="col s4 m4 l4 xl4 no-margin">
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Hexnode API</span>
                      <span className="nucleus-labels-special-red">*</span>
                    </div>
                  </div>
                  <div className="row vbottom-align only-top-margin-upper">
                    <div className="col s10 no-margin" key={this.state.hexnodeAPI}>
                      <input ref="txtHexnodeAPI" type="text" className="validate nucleus-input-form-small accountTextLong" defaultValue={this.state.hexnodeAPI} />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateHexnodeAPI}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Hexnode URL</span>
                      <span className="nucleus-labels-special-red">*</span>
                    </div>
                  </div>
                  <div className="row vbottom-align only-top-margin-upper">
                    <div className="col s10 no-margin">
                      <input ref="txtHexnodeUrl" type="text" className="validate nucleus-input-form-small accountTextFull" defaultValue={this.state.hexnodeUrl} />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateHexnodeUrl}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Hexnode Default Kiosk Policy ID</span>
                      <span className="nucleus-labels-special-red">*</span>
                    </div>
                  </div>
                  <div className="row vbottom-align only-top-margin-upper">
                    <div className="col s10 no-margin" key={this.state.hexnodePolicyID}>
                      <input ref="txtHexnodePolicyID" type="text" className="validate nucleus-input-form-small accountTextLong" defaultValue={this.state.hexnodePolicyID} />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateHexnodePolicyID}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Hexnode Username</span>
                    </div>
                  </div>
                  <div className="row vbottom-align only-top-margin-upper">
                    <div className="col s10 no-margin">
                      <input ref="txtHexnodeUsername" type="text" className="validate nucleus-input-form-small accountTextLong" defaultValue={this.state.hexnodeUserName} />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <a className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateHexnodeUsername}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col s4 m4 l4 xl4 no-margin">
                  <div className="row vbottom-align no-margin top-separated ">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels">
                        {''}Inactive Days in Hexnode to Disenroll{''}
                      </span>
                    </div>
                  </div>
                  <div className="row vbottom-align only-top-margin-upper ">
                    <div className="col s10 no-margin">
                      <input ref="txtInactiveDays" type="text" className="validate nucleus-input-form accountTextLong" defaultValue={this.state.inactiveDays} placeholder="30" />
                    </div>
                    <div className="col s2 no-margin">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left" onClick={this.handleUpdateInactiveDays}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>

                  <div className="row vbottom-align no-margin top-separated ">
                    <div className="col s10 no-margin">
                      <span className="nucleus-labels"> Inactive Days in Hexnode Alert </span>
                    </div>
                  </div>
                  <div className="row vbottom-align only-top-margin-upper">
                    <div className="col s10 no-margin">
                      <input
                        ref="txtInactiveDaysAlert"
                        type="text"
                        className="validate nucleus-input-form accountTextLong"
                        defaultValue={this.state.inactiveDaysAlert}
                        placeholder="7"
                      />
                    </div>
                    <div className="col s2 no-margin">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-left" onClick={this.handleUpdateInactiveDaysAlert}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col s4 m4 l4 xl4 no-margin"></div>
              </div>
            )}

            {this.state.accountConfigSelectedTab === AccountConfigTab.Salesforce && (
              <div className={'row '}>
                <div className="col s12 m10 l9 xl8 no-margin">
                  <div className="row vbottom-align no-margin top-separated">
                    <div className="col s4 no-margin">
                      <div className="row bit-top-separated no-margin">
                        <div className="col s9 no-margin">
                          <span className="nucleus-labels">Enabled</span>
                        </div>
                        <div className="col s3">
                          <div className="switch a-more-right">
                            <label>
                              <input type="checkbox" ref="checkSalesforceEnabled" defaultChecked={salesforceEnabledValue} onChange={this.handleSalesforceEnabled} />
                              <span className="lever nucleus-check"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col s8"></div>
                  </div>
                  <div className="row no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Org Url</span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin">
                    <div className="col s6 no-margin">
                      <input ref="txtSalesforceOrgUrl" type="text" className="validate nucleus-input-form-small accountTextFull" defaultValue={this.state.salesforceOrgUrl} />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateSalesforceOrgUrl}>
                        <i className="material-icons nucleus-floating-btn-icon">save </i>
                      </span>
                    </div>
                    <div className="col s4 no-margin"></div>
                  </div>

                  <div className="row no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Login Url</span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin">
                    <div className="col s6 no-margin">
                      <input ref="txtSalesforceLoginUrl" type="text" className="validate nucleus-input-form-small accountTextFull" defaultValue={this.state.salesforceLoginUrl} />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateSalesforceLoginUrl}>
                        <i className="material-icons nucleus-floating-btn-icon">save </i>
                      </span>
                    </div>
                    <div className="col s4 no-margin"></div>
                  </div>

                  <div className="row  no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Client ID</span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin">
                    <div className="col s10 no-margin">
                      <input ref="txtSalesforceClientID" type="text" className="validate nucleus-input-form-small accountTextFull" defaultValue={this.state.salesforceClientID} />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateSalesforceClientID}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>

                  <div className="row no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Client Secret</span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin">
                    <div className="col s10 no-margin">
                      <input
                        ref="txtSalesforceClientSecret"
                        type="text"
                        className="validate nucleus-input-form-small accountTextFull"
                        defaultValue={this.state.salesforceClientSecret}
                      />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span
                        className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left"
                        onClick={this.handleUpdateSalesforceClientSecret}
                      >
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                  </div>

                  <div className="row no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">User Name</span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin">
                    <div className="col s6 no-margin">
                      <input ref="txtSalesforceUserName" type="text" className="validate nucleus-input-form-small accountTextFull" defaultValue={this.state.salesforceUserName} />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateSalesforceUserName}>
                        <i className="material-icons nucleus-floating-btn-icon">save</i>
                      </span>
                    </div>
                    <div className="col s4 no-margin"></div>
                  </div>

                  <div className="row no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Password</span>
                    </div>
                  </div>
                  <div className="row vbottom-align no-margin">
                    <div className="col s6 no-margin">
                      <input
                        ref="txtSalesforcePassword"
                        type="password"
                        className="validate nucleus-input-form-small accountTextFull"
                        defaultValue={this.state.salesforcePassword}
                      />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateSalesforcePassword}>
                        <i className="material-icons nucleus-floating-btn-icon"> save </i>
                      </span>
                    </div>
                    <div className="col s4 no-margin"></div>
                  </div>

                  <div className="row no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Redirect Uri</span>
                    </div>
                  </div>
                  <div className="row no-margin vbottom-align">
                    <div className="col s6 no-margin" key={this.state.salesforceRedirectUri}>
                      <input
                        ref="txtSalesforceRedirectUri"
                        type="text"
                        className="validate nucleus-input-form-small accountTextFull"
                        defaultValue={this.state.salesforceRedirectUri}
                      />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span
                        className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left"
                        onClick={this.handleUpdateSalesforceRedirectUri}
                      >
                        <i className="material-icons nucleus-floating-btn-icon">save </i>
                      </span>
                    </div>
                    <div className="col s4 no-margin"></div>
                  </div>

                  <div className="row no-margin top-separated">
                    <div className="col s12 no-margin">
                      <span className="nucleus-labels-small">Security Token</span>
                    </div>
                  </div>
                  <div className="row no-margin vbottom-align">
                    <div className="col s6 no-margin">
                      <input
                        ref="txtSalesforceSecurityToken"
                        type="text"
                        className="validate nucleus-input-form-small accountTextFull"
                        defaultValue={this.state.salesforceSecurityToken}
                      />
                    </div>
                    <div className="col s2 no-margin a-little-right">
                      <span
                        className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left"
                        onClick={this.handleUpdateSalesforceSecurityToken}
                      >
                        <i className="material-icons nucleus-floating-btn-icon">save </i>
                      </span>
                    </div>
                    <div className="col s4 no-margin"></div>
                  </div>
                </div>
                <div className="col s2 m2 l3 xl4  no-margin a-little-right">
                  <div className="row">
                    <span className="nucleus-labels">Credentials State: {this.state.salesforceValidCredentials ? 'Connected' : 'Not Connected'}</span>
                    <br />
                    <br />
                    <span className="nucleus-font-small nucleus-link nucleus-unblock-api-btn" onClick={this.verifySalesforceConfiguration}>
                      &nbsp;&nbsp;Verify Connection&nbsp;&nbsp;
                    </span>
                  </div>
                  {this.state.salesforceValidCredentials && (
                    <div className="row">
                      <br />
                      <span className="nucleus-font-small nucleus-link nucleus-unblock-api-btn" onClick={this.checkSalesforceIntegration}>
                        &nbsp;&nbsp;Salesforce Configuration&nbsp;&nbsp;
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {this.state.accountConfigSelectedTab === AccountConfigTab.ClassSession && (
              <div className={'row '}>
                <ClassSession AccountID={this.state.accountID} />
              </div>
            )}

            {this.state.accountConfigSelectedTab === AccountConfigTab.Apps && (
              <div className={'row '}>
                <AppsTab AccountID={this.state.accountID} />
              </div>
            )}

            {this.state.accountConfigSelectedTab === AccountConfigTab.LinkButton && (
              <div className={'row'}>
                <LinkTab AccountID={this.state.accountID} />
              </div>
            )}

            {this.state.accountConfigSelectedTab === AccountConfigTab.SchedulingTemplate && (
              <div className={'row'}>
                <ScheduleTab AccountID={this.state.accountID} />
              </div>
            )}
            {this.state.accountConfigSelectedTab === AccountConfigTab.NewThemeSettings && (
              <div className="row">
                <AccountThemeSettings accountId={this.state.accountID}></AccountThemeSettings>
              </div>
            )}
          </div>

          <div className="nucleus-news-separator" style={{ marginBottom: 5 }}>
            &nbsp;
          </div>

          {this.state.loading && (
            <div className={'row center-align'}>
              <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />
            </div>
          )}

          <div className="row nucleusTabContainer">
            <span className={'staffTabs nucleus-link ' + statsTabStyle} onClick={this.handleStatsTab}>
              <span className="nucleusTabText">&nbsp;&nbsp;Stats&nbsp;&nbsp;</span>
            </span>

            <span className={'staffTabs nucleus-link ' + devicesTabStyle} onClick={this.handleDevicesTab}>
              <span className="nucleusTabText">&nbsp;&nbsp;Devices {'(' + this.state.accountDevices + ')'}&nbsp;&nbsp;</span>
            </span>

            <span className={'staffTabs nucleus-link ' + usersTabStyle} onClick={this.handleUsersTab}>
              <span className="nucleusTabText">&nbsp;&nbsp;Users{'(' + this.state.accountUsers + ')'}&nbsp;&nbsp;</span>
            </span>

            <span className={'staffTabs nucleus-link ' + patientsTabStyle} onClick={this.handlePatientsTab}>
              <span className="nucleusTabText">&nbsp;&nbsp;{this.state.accountClientRoleNamePlural + '(' + this.state.accountPatients + ')'}&nbsp;&nbsp;</span>
            </span>

            <span className={'staffTabs nucleus-link ' + clientsTabStyle} onClick={this.handleClientsTab}>
              <span className="nucleusTabText">&nbsp;&nbsp;{this.state.accountClientRoleNamePlural + ' Statistics (' + this.state.accountClients + ')'}&nbsp;&nbsp;</span>
            </span>

            {AuthStore.superUser() && (
              <span className={'staffTabs nucleus-link ' + patientsTimeZoneTabStyle} onClick={this.handlePatientsTimeZones}>
                <span className="nucleusTabText">&nbsp;&nbsp;{this.state.accountClientRoleNamePlural + ' Timezones'}&nbsp;&nbsp;</span>
              </span>
            )}

            {AuthStore.superUser() && (
              <span className={'staffTabs nucleus-link ' + bioDevicesTabStyle} onClick={this.handleBioDevicesTab}>
                <span className="nucleusTabText">&nbsp;&nbsp;Biometric Devices {' (' + this.state.accountBioDevices + ')'}&nbsp;&nbsp;</span>
              </span>
            )}

            <span className={'staffTabs nucleus-link ' + scheduleTabStyle + scheduleVisibility} onClick={this.handleScheduleTab}>
              <span className="nucleusTabText">&nbsp;&nbsp;Schedule&nbsp;&nbsp;</span>
            </span>

            <span className={'staffTabs nucleus-link ' + externalAppsTabStyle} onClick={this.handleExternalAppsSubTab}>
              <span className="nucleusTabText">&nbsp;&nbsp;External Apps&nbsp;&nbsp;</span>
            </span>
          </div>

          <div className={'row nucleus-fixed-container ' + statsContentVisibility}>
            <AccountStats accountId={this.state.accountID} />
          </div>

          {/*  - - - - - - - DEVICES - - - - - - - - - */}
          {/* <div className={"row work-log " + this.state.devicesContent }> */}
          <div className={devicesContentVisibility}>
            {/* <div className="assignments-table-pending nucleus-table-container" > */}
            <AccountDevices
              setCheckedDevices={(device) => {
                if (device.DeviceAssigned) {
                  console.log('Device Assigned');
                  return;
                }
                let selectedDevicesArray = this.state.selectedDevicesToMoveArray;
                let deviceID = device.ID;
                let indexInArray = selectedDevicesArray.findIndex((d) => d.ID == deviceID);
                let selectedDevices = Object.assign({}, this.state.selectedDevicesToMove);
                let index = selectedDevices[deviceID];
                // console.log("indexGGG",index);
                if (!index) {
                  selectedDevicesArray.push(device);
                  selectedDevices[deviceID] = device;
                } else {
                  selectedDevicesArray.splice(indexInArray, 1);
                  delete selectedDevices[deviceID];
                }

                selectedDevicesArray.sort((a, b) => {
                  let textA = a.PreAssignedName.toLowerCase();
                  let textB = b.PreAssignedName.toLowerCase();
                  if (textA === '' || textA === null) return 1;
                  if (textB === '' || textB === null) return -1;
                  if (textA === textB) return 0;
                  return textA < textB ? -1 : 1;
                });
                this.setState({
                  selectedDevicesToMove: selectedDevices,
                  selectedDevicesToMoveArray: selectedDevicesArray,
                });
              }}
              selectedDevicesToMove={this.state.selectedDevicesToMove}
              accountId={this.state.accountID}
            ></AccountDevices>

            <div className="fixed-action-btn btn-add-new move-float-button">
              <span
                className="btn-floating btn-large waves-effect waves-light blue tooltipped nucleus-floating-button"
                data-position="left"
                data-delay="50"
                data-tooltip="Move Devices"
                onClick={this.handleMoveDevices}
              >
                <i className="material-icons nucleus-floating-icon">reply</i>
              </span>
              &nbsp;
            </div>
          </div>

          {/*  - - - - - - - -  USERS - - - - - - - - */}
          <div className={'row nucleus-fixed-container ' + usersContentVisibility}>
            <div className="assignments-table-pending">
              <table className="bordered highlight nucleus-table">
                <thead>
                  <tr>
                    <th className="table-col-30">
                      <span className="nucleus-table-header-medium">Name</span>
                    </th>
                    <th className="table-col-25">
                      <span className="nucleus-table-header-medium">Email</span>
                    </th>
                    <th className="table-col-20">
                      <span className="nucleus-table-header-medium">Telephone</span>
                    </th>
                    <th className="table-col-10">
                      <span className="nucleus-table-header-medium">Status</span>
                    </th>
                    <th className="table-col-10 center-align">
                      <span className="nucleus-table-header-medium">Is Admin</span>
                    </th>
                    <th className="table-col-5">
                      {AuthStore.superUser() && (
                        <span className="nucleus-table-icon tooltipped nucleus-link " data-position="top" data-delay="50" data-tooltip="Generate Temporal Password">
                          <i className="Tiny material-icons">lock_open</i>
                        </span>
                      )}
                    </th>
                  </tr>
                </thead>

                <tbody>{accountUsersList}</tbody>
              </table>
            </div>

            <div className="fixed-action-btn btn-add-new">
              <Link to={'/newAccountUser/' + this.state.accountID}>
                <span
                  className="btn-floating btn-large waves-effect waves-light orange tooltipped nucleus-floating-button"
                  data-position="left"
                  data-delay="50"
                  data-tooltip="New User"
                >
                  <i className="material-icons nucleus-floating-icon">add</i>
                </span>
              </Link>
              &nbsp;
            </div>

            <AccountUserResetPasswordModal UserID={this.state.selectedUserId} UserName={this.state.selectedUserName} OnDismiss={this.onUserResetPasswordClear} />
          </div>

          {/*  - - - - - - - -  PATIENTS STATS - - - - - - - - */}
          <div className={'row nucleus-fixed-container ' + clientsContentVisibility}>
            <div className="assignments-table-pending">
              <table className="bordered highlight nucleus-table">
                <thead>
                  <tr>
                    <th className="table-col-20">
                      <span className="nucleus-table-header-medium">Patient Name</span>
                    </th>
                    <th className="table-col-15 center-align">
                      <span className="nucleus-table-header-medium">Devices</span>
                    </th>
                    <th className="table-col-18 center-align">
                      <span className="nucleus-table-header-medium">Media Box Assets</span>
                    </th>
                    <th className="table-col-19 center-align">
                      <span className="nucleus-table-header-medium">
                        Family Members
                        <br />
                        {'(Active / Total)'}
                      </span>
                    </th>
                    <th className="table-col-13 center-align">
                      <span className="nucleus-table-header-medium">Read Messages</span>
                    </th>
                    <th className="table-col-15 center-align">
                      <span className="nucleus-table-header-medium">Expired Messages</span>
                    </th>
                  </tr>
                </thead>
                <tbody>{clientsUsersList}</tbody>
              </table>
            </div>
          </div>

          {/*  - - - - - - - -  PATIENTS TIMEZONES  - - - - - - - - */}
          {patientsTimeZoneContentVisibility == ' ' && (
            <div className={'row nucleus-fixed-container nucleus-table-container-s ' + patientsTimeZoneContentVisibility}>
              <AccountPatientTimezones accountId={this.state.accountID} patientsLabel={this.state.accountClientRoleNamePlural} />
            </div>
          )}

          {/*  - - - - - - - -  PATIENTS  - - - - - - - - */}
          {patientsContentVisibility == ' ' && (
            <div className={'row nucleus-fixed-container nucleus-table-container-s ' + patientsContentVisibility}>
              <AccountPatients accountId={this.state.accountID} clientRoleName={this.state.accountClientRoleName} patientsLabel={this.state.accountClientRoleNamePlural} />
            </div>
          )}

          {/*  - - - - - - - -  BIOMETIC DEVICES  - - - - - - - - */}
          <div className={'row nucleus-fixed-container ' + bioDevicesContentVisibility}>
            <div className="assignments-table-pending">
              <table className="bordered highlight nucleus-table">
                <thead>
                  <tr>
                    <th className="table-col-15">
                      <span className="nucleus-table-header-medium">Mac Address</span>
                    </th>
                    <th className="table-col-30">
                      <span className="nucleus-table-header-medium">Device Name</span>
                    </th>
                    <th className="table-col-15">
                      <span className="nucleus-table-header-medium">Patient Name</span>
                    </th>
                    <th className="table-col-15 center-align">
                      <span className="nucleus-table-header-medium"># Times Used</span>
                    </th>
                    <th className="table-col-25">
                      <span className="nucleus-table-header-medium">Last Used</span>
                    </th>
                  </tr>
                </thead>
                <tbody>{accountBioDevicesList}</tbody>
              </table>
            </div>
          </div>

          {/*  - - - - - - - -  SCHEDULE  - - - - - - - - */}
          <div className={'row nucleus-fixed-container ' + scheduleContentVisibility + scheduleVisibility}>
            <div className="col s12 m6">
              <ScheduleView accountId={this.state.accountID}></ScheduleView>
            </div>
            <div className="col s12 m6"></div>
          </div>

          {/*  - - - - - - - -  ACCOUNT APPS  - - - - - - - - */}
          {
            <div className={`row nucleus-fixed-container ${externalAppsContentVisibility}`}>
              <AccountExternalApps AccountID={this.state.accountID}></AccountExternalApps>
            </div>
          }

          <div id="modalDeleteAccountDevice" className="modal modalDeleteAccountDevice">
            <div className="modal-content nucleus-modal-wrapper-scrollable2">
              <img className="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCancelDeleteDevice} />
              <h3 className="nucleus-page-subtitle"> Delete Device Options</h3>
              <br />
              <br />
              <div className="row">
                <div className="col s12 no-margin">
                  <span className="nucleus-labels">Are you sure you want to remove the device {'"' + this.state.selectedDeviceName + '"'} from the account?</span>
                </div>
                <br />
                <br />
                <div className="col s12 no-margin">
                  <span className="nucleus-labels">Please select one of the following options listed bellow:</span>
                </div>
              </div>
              {Boolean(this.state.selectedDeviceAssigned) && (
                <div className="row ">
                  <div className="col s6 no-margin">
                    <span className="nucleus-labels-small">Unassign Device</span>
                  </div>
                  <div className="col s6 no-margin">
                    <span>
                      <input
                        ref="checkUnassignDevice"
                        type="checkbox"
                        className="filled-in"
                        id="checkUnassignDevice"
                        checked={this.state.selectedDeviceUnassign}
                        onChange={(e) => {
                          console.log('Checked->', e.target.checked);
                          this.setState({
                            selectedDeviceUnassign: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor={'checkUnassignDevice'}></label>
                    </span>
                  </div>
                </div>
              )}
              {Boolean(this.state.selectedDeviceOnHexnode) && (
                <div className="row ">
                  <div className="col s6 no-margin">
                    <span className="nucleus-labels-small">Remove from Hexnode</span>
                  </div>
                  <div className="col s6 no-margin">
                    <span>
                      <input
                        ref="checkDisenrollHexnode"
                        type="checkbox"
                        className="filled-in"
                        id="checkDisenrollHexnode"
                        checked={this.state.selectedDeviceDisenrollHexnode}
                        onChange={(e) => {
                          console.log('Checked->', e.target.checked);
                          this.setState({
                            selectedDeviceDisenrollHexnode: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor={'checkDisenrollHexnode'}></label>
                    </span>
                  </div>
                </div>
              )}

              <div className="row ">
                <div className="col s6 no-margin">
                  <span className="nucleus-labels-small">Remove from NucleusCare</span>
                </div>
                <div className="col s6 no-margin">
                  <span>
                    <input
                      ref="checkRemoveDevice"
                      type="checkbox"
                      className="filled-in"
                      id="checkRemoveDevice"
                      checked={this.state.selectedDeviceRemoveFromSystem}
                      onChange={(e) => {
                        console.log('Checked->', e.target.checked);
                        this.setState({
                          selectedDeviceRemoveFromSystem: e.target.checked,
                        });
                        !e.target.checked && this.setState({ selectedDeviceRemoveFromMDM: false });
                      }}
                    />
                    <label htmlFor={'checkRemoveDevice'}></label>
                  </span>
                </div>
              </div>
              {Boolean(this.state.selectedDeviceOnNucleusMDM) && (
                <div className="row ">
                  <div className="col s6 no-margin">
                    <span className="nucleus-labels-small">Remove from Nucleus MDM</span>
                  </div>
                  <div className="col s6 no-margin">
                    <span>
                      <input
                        ref="checkRemoveDeviceMDM"
                        type="checkbox"
                        className="filled-in"
                        id="checkRemoveDeviceMDM"
                        checked={this.state.selectedDeviceRemoveFromMDM}
                        onChange={(e) => {
                          console.log('Checked->', e.target.checked);
                          this.setState({
                            selectedDeviceRemoveFromMDM: e.target.checked,
                          });
                        }}
                        disabled={!this.state.selectedDeviceRemoveFromSystem}
                      />
                      <label htmlFor={'checkRemoveDeviceMDM'}></label>
                    </span>
                  </div>
                </div>
              )}
              <div className="input-field col s12">
                <br />
                <div className="row center-align">
                  <div className="col s6 center-align no-margin">
                    <p></p>
                    <span className={'txt-white nucleus-font-medium ' + this.state.deleteDeviceBtnStyle} onClick={this.handleCancelDeleteDevice}>
                      Cancel
                    </span>
                    <div>&nbsp;</div>
                  </div>
                  <div className="col s6 center-align no-margin">
                    <p></p>
                    <span className={'txt-white nucleus-font-medium ' + this.state.deleteDeviceBtnStyle} onClick={this.handleDeleteDevice}>
                      Confirm
                    </span>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="modalDownloadDeviceLogs" className="modal modalDownloadDeviceLogs">
            <div className="modal-content nucleus-modal-wrapper-scrollable2">
              <img className="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCancelDownloadDeviceLogs} />
              <h3 className="nucleus-page-subtitle"> Download Device Logs</h3>
              <br />
              <br />
              <div className="row no-margin">
                <div className="col s12 no-margin">
                  <span className="nucleus-labels"> Download the log file for the following device {this.state.selectedDeviceMac} ? </span>
                </div>
              </div>
              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <span className={'txt-white nucleus-font-small ' + this.state.downloadDeviceLogsBtnStyle} onClick={this.handleDownloadDeviceLogs}>
                      Download
                    </span>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="modalNewAccountDevice" className="modal modalNewAccountDevice">
            <div className="modal-content modal-content-delete center-align">
              <h3 className="nucleus-page-subtitle">Add Devices to Account</h3>
              <br />
              <br />
              <br />
              <div className="row center-align">
                <div className="col s2"></div>
                <div className="col s8 left-align">
                  <span className="nucleus-labels">
                    <b>Mac Addresses</b>
                  </span>
                </div>
                <div className="col s2"></div>
              </div>
              <div className="row center-align">
                <div className="col s2"></div>
                <div className="col s8 left-align">
                  {/*<input id="txtNewAccountDeviceMac" ref="txtNewAccountDeviceMac" type="text" className="validate nucleus-search"/><br/>*/}
                  <textarea ref="txtNewAccountDeviceMac" className="nucleus-textarea nucleus-textarea-scroll" defaultValue={''} onChange={this.validateMacAddressText}></textarea>
                  <span className={'nucleus-form-error center-align ' + this.state.deviceMacAddressError}> {this.state.deviceMacAddressText}</span>
                </div>
                <div className="col s2"></div>
              </div>
              <div className="row ">
                <div className="col s12 center-align ">
                  <div className="col s6 right-align">
                    <span className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCloseNewDeviceModal}>
                      Cancel
                    </span>
                  </div>
                  <div className="col s6 left-align">
                    <span className={'txt-white nucleus-font-small nucleus-submit-btn-small' + this.state.deviceAddBtnStyle} onClick={this.handleSubmitNewDevice}>
                      Confirm
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="modalMoveDevices" className="modal modalScrollableSmall dismissible-modal" style={{ width: 450, zIndex: 100 }}>
            <div className="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
              <img
                className="responsive-img img-close-modal"
                src="img/close_modal.png"
                onClick={() => {
                  //@ts-ignore
                  window.$('#modalMoveDevices').modal('close');
                }}
              />
              <div className="row">
                <div className="col s12">
                  <h3 className="nucleus-page-subtitle">Move Devices to Account</h3>
                </div>
              </div>
              <div className="row marginBottom12">
                <div className="col s4">
                  <span className="nucleus-labels" style={{ fontSize: 16.7, fontWeight: 'bold' }}>
                    {'From account:'}
                  </span>
                </div>
                <div className="col s8">
                  <span className="nucleus-labels" style={{ fontSize: 16.7 }}>
                    {this.state.accountName}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels " style={{ fontSize: 16.7 }}>
                    Please select the account where the following devices will be moved to.
                  </span>
                </div>
              </div>
              <div className="row marginBottom12">
                <div className="col s8">
                  <select
                    className="browser-default accountSelect"
                    onChange={(e) => {
                      this.setState({
                        selectedAccountToMoveID: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      {' Select an Account '}
                    </option>
                    {this.state.accountsAbleToMoveDevices != null &&
                      this.state.accountsAbleToMoveDevices.length > 0 &&
                      this.state.accountsAbleToMoveDevices.map((account, index) => {
                        return (
                          <option key={index} value={account.ID}>
                            {account.AccountName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="row marginBottom12">
                <div className="col s12">
                  <span className="nucleus-labels " style={{ fontSize: 16.7, fontWeight: 'bold' }}>
                    {' '}
                    Devices to be moved{' '}
                  </span>
                  <table className="bordered highlight nucleus-table" style={{ backgroundColor: 'white', marginTop: 10, marginBottom: 10 }}>
                    <thead>
                      <tr>
                        <th className="table-col-10 left-align">
                          <span className="nucleus-table-header-small">Device ID</span>
                        </th>
                        <th className="table-col-10 left-align">
                          <span className="nucleus-table-header-small">Pre A. Name</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.selectedDevicesToMoveArray != null &&
                        this.state.selectedDevicesToMoveArray.map((device, key) => (
                          <tr key={key}>
                            <td className="">{device.HexnodeID ? device.HexnodeID : device.MdmDeviceID ? device.MdmDeviceID : device.Mac}</td>
                            <td className="">{device.PreAssignedName}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <span
                      className={'txt-white nucleus-font nucleus-submit-btn '}
                      onClick={() => {
                        //@ts-ignore
                        window.$('#modalMoveDevices').modal('close');
                      }}
                    >
                      Cancel
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className={'txt-white nucleus-font nucleus-submit-btn '} onClick={this.handleSubmitMoveDevices}>
                      Confirm
                    </span>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="modalNewAccountLegacyDevice" className="modal modalNewAccountDevice">
            <div className="modal-content modal-content-delete center-align">
              <h3 className="nucleus-page-subtitle">Add Devices to Account*</h3>
              <br />
              <br />

              <div className="row left-align">
                <div className="col s12 no-margin">
                  <span className="nucleus-labels">
                    Are you sure you want to add the next mac address devices and remove from the Legacy ?
                    <br />
                    <br />
                    {this.state.devicesToAddInLegacy}.
                  </span>
                </div>
              </div>

              <br />
              <div className="row ">
                <div className="col s12 center-align ">
                  <div className="col s6 right-align">
                    <span className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCloseLegacyDeviceModal}>
                      Cancel
                    </span>
                  </div>
                  <div className="col s6 left-align">
                    <span className={'txt-white nucleus-font-small nucleus-submit-btn-small' + this.state.deviceLegacyAddBtnStyle} onClick={this.handleSubmitLegacyDevice}>
                      Confirm
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Unlock API modal*/}
          <div id="modalUnblockApi" className="modal modalUnblockApi modalUnblockApi">
            <div className="modal-content modal-content-delete">
              <h3 className="nucleus-page-subtitle dark-blue">&nbsp;&nbsp;Unblock API </h3>
              <br />
              <br />
              <div className="row">
                <div className="col s12">
                  <span className={'nucleus-labels dark-blue'}>Are you sure you would like to unblock API?</span>
                </div>
              </div>

              <br />
              <br />
              <div className="row ">
                <div className="col s12 right-align ">
                  <span className="txt-white nucleus-font-medium nucleus-link" onClick={this.handleCloseUnblockApi}>
                    Cancel
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className={'txt-white nucleus-font-medium nucleus-submit-btn-small'} onClick={this.handleSubmitUnblockApi}>
                    Confirm
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/*Generate API Token Modal*/}
          <div id="modalGenerateApiToken" className="modal modalGenerateApiToken modalDeletePatientGenerateApiToken">
            <div className="modal-content modal-content-delete">
              <h3 className="nucleus-page-subtitle dark-blue">&nbsp;&nbsp;New API Token </h3>
              <br />
              <br />
              <div className="row">
                <div className="col s12">
                  <span className={'nucleus-labels dark-blue'}>Warning: You are about to generate a new token. Once done, the old token will no longer be valid.</span>
                </div>
              </div>

              <br />
              <br />
              <div className="row ">
                <div className="col s12 right-align ">
                  <span className="txt-white nucleus-font-medium nucleus-link" onClick={this.handleCloseGenerateApiTokenModal}>
                    Cancel
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className={'txt-white nucleus-font-medium nucleus-submit-btn-small'} onClick={this.handleSubmitGenerateApiToken}>
                    Confirm
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/*  Account Template */}
          <div id="modalSelectTemplate" className="modal modalSelectTemplate">
            <div className="modal-content nucleus-modal-wrapper-scrollable2">
              <img className="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCancelSelectTemplate} />

              <div className="row">
                <div className="col s12 center-align">
                  <h3 className="nucleus-page-subtitle"> Main Device Template Selection </h3>
                  <br />
                  <span className="nucleus-labels">Select the template you want to apply</span>
                </div>
              </div>

              <div>
                {this.state.templatesArray.map(
                  (template, templateId) =>
                    this.state.templateNamesToDisplay.has(template.Name) && (
                      <div className="row" key={templateId}>
                        <div className="col s1">
                          <span>
                            <input
                              type="checkbox"
                              className="filled-in"
                              id={`checkSelectTemplate${templateId}`}
                              checked={this.state.checkedTemplateId === templateId}
                              onChange={this.handleSelectTemplate.bind(this, templateId)}
                            />
                            <label htmlFor={`checkSelectTemplate${templateId}`}>&nbsp;</label>
                          </span>
                        </div>
                        <div className="col s6">
                          <img alt="template example" className="responsive-img" src={template.ExampleImageUrl} height="140" />
                        </div>
                        <div className="col s5">
                          <span className="nucleus-labels-bold">{template.Name}</span>
                          <br />
                          <span className="nucleus-labels f-small">{template.Description}</span>
                        </div>
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>

          <div id="modalDeleteAccount" className="modal modalDeleteAccount">
            <div className="modal-content modal-content-delete center-align">
              <h3 className="nucleus-page-subtitle">Delete Account</h3>
              <br />
              <br />
              <br />
              <div className="row">
                <div className="col s12 center-align">
                  <span className="nucleus-labels">Are you sure you want to delete this Account?</span>
                </div>
              </div>
              <div className="row">
                <div className="col s12 center-align">
                  <span className="nucleus-labels">You will no longer be able to view it on the Admin Portal.</span>
                </div>
              </div>

              <div className="row ">
                <div className="col s12">
                  <span className="nucleus-labels-small txt-red">{' ' + this.state.deleteAccountError}</span>
                </div>
              </div>

              <div className="row ">
                <div className="col s12 center-align ">
                  <div className="col s6 right-align">
                    <span className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCloseDeleteAccountModal}>
                      Cancel
                    </span>
                  </div>
                  <div className="col s6 left-align">
                    <span className={'txt-white nucleus-font-small ' + this.state.btnDeleteAccountMode} onClick={this.handleSubmitDeleteAccount}>
                      Confirm
                    </span>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>

          <div id="modalCheckSalesforceConnection" className="modal modalMedium">
            <div className="modal-content nucleus-modal-wrapper-scrollable2">
              <img className="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCloseSalesforceConfigurationModal} />
              <h3 className="nucleus-page-subtitle"> Check Salesforce Connection </h3>
              <br />
              <br />
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels"> {' ' + this.state.salesforceCheckStatus + ' '} </span>
                </div>
              </div>
              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      <label> &nbsp;</label>{' '}
                    </span>
                    <span className={'txt-white nucleus-font-small ' + this.state.closeSalesforceConfigurationBtnStyle} onClick={this.handleCloseSalesforceConfigurationModal}>
                      Ok
                    </span>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="modalSalesforceIntegration" className="modal modalMedium">
            <div className="modal-content nucleus-modal-wrapper-scrollable2">
              <img alt="close icon" className="responsive-img img-close-modal" src="img/close_modal.png" onClick={this.handleCloseSalesforceIntegrationModal} />
              <h3 className="nucleus-page-subtitle"> Salesforce Configuration </h3>
              <br />
              <br />

              <div className="row">
                <span className="nucleus-labels"> {"The Custom Object needed is : 'Nucleus Event' (NucleusCare__Nucleus_Event__c)"} </span>
                <br />
                <br />
                <span className="nucleus-labels"> {this.state.salesforceConfigurationStatus} </span>
              </div>
              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      <label> &nbsp;</label>{' '}
                    </span>
                    {this.state.salesforceConfigurationNeeded && (
                      <span className={'txt-white nucleus-font-small ' + this.state.closeSalesforceConfigurationBtnStyle} onClick={this.handleConfigureSalesforce}>
                        Start Configuration
                      </span>
                    )}

                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountDetails;
