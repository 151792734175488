import Config from "../Config";
import backendClient from "../utils/BackendClient";
import NucleusNetwork from "../utils/NucleusNetwork";

class UserApi {

	constructor(context) {
		this.context = context;
	}

	//<----------------------------------Get User Data-------------------------------->
	GetCareUserData (data,callback) {
		backendClient.get(`users/${data.UserID}`, callback);
	}

	//<----------------------------------Update User Data----------------------------->
	UpdateCareUserData (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/UpdateUser",
			data : data,
			callback : callback,
		});
	}

	//<----------------------------------Get All Users Data--------------------------->
	getAllUsersData (data,callback) {
		backendClient.get(`users/${data.UserID}/users`, callback);
	}

	//<----------------------------------Set User On Duty--------------------------->
	setUserOnDuty (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/SetOnDuty",
			data : data,
			callback : callback,
		});
	}

	//<----------------------------------Set User Is Admin--------------------------->
	setUserIsAdmin (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/SetIsAdmin",
			data : data,
			callback : callback,
		});
	}

	//<-------------------------Set User Can see all Patients----------------------->
	SetSeeAllPatients (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/CanSeeAllPatients",
			data : data,
			callback : callback,
		});
	}

	//<----------------------------------Create New User--------------------------->
	CreateNewUser (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/AddUser",
			data : data,
			callback : callback,
		});
	}


	//<---------------------------------Delete User--------------------------------->
	DeleteUser (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/DeleteUser",
			data : data,
			callback : callback,
		});
	}


	//<----------------------------Get Possible Patients--------------------------->
	GetPossiblePatients (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/GetPossiblePatients",
			data : data,
			callback : callback,
		});
	}

	//<----------------------------Assign Patient--------------------------->
	AssignPatient (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/AddPatientUser",
			data : data,
			callback : callback,
		});
	}

	//<----------------------------Assign Patient--------------------------->
	UnassignPatient (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/DeletePatientUser",
			data : data,
			callback : callback,
		});
	}

	//<----------------------------Get All Patients Data By User------------------------->
	getAllPatientDataForUser (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CarePatient.asmx/GetPatientsForUser",
			data : data,
			callback : callback,
		});
	}

	//<--------------------------------Request Resend Email----------------------------->
	RequestResendEmail (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/ReSendWelcomeEmail",
			data : data,
			callback : callback,
		});
	}





	//<--------------------------------------Account-------------------------------->
	//<---------------------------------Set Show Call Btn--------------------------->
	SetShowCallBtn (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/ShowCallButton",
			data : data,
			callback : callback,
		});
	}

	//<-------------------------------Set Show CheckIn Btn-------------------------->
	SetShowCheckInBtn (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/ShowCheckInButton",
			data : data,
			callback : callback,
		});
	}

	//<------------------------------Set Show Emergency Btn------------------------->
	SetShowEmergencyBtn (data,callback) {
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareAccount.asmx/ShowEmergencyButton",
			data : data,
			callback : callback,
		});
	}
}


export default UserApi;